import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  Button,
  Input,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui';
import { getAvailableFunds } from '@/features/account/utils/math';
import { useAccountStore } from '@/store/use-account-store';
import {
  COLLATERAL_DECIMALS,
  getAccountMarketStores,
  useMarketStores,
} from '@/store/use-markets-store';
import { useOrdersStore } from '@/store/use-orders-store';
import { LPOrderType } from '@/types/enums';
import {
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
  validatedDecimalStr,
} from '@/utils/value-format';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo, useState } from 'react';
import { Controller, FormProvider, set, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useShallow } from 'zustand/react/shallow';
import { useCreateLpOrder } from '../api/create-lp-order';
import { useFetchAccount } from '@/features/account/api/get-account';

const lpFormSchema = z.object({
  orderType: z.nativeEnum(LPOrderType),
  size: z.bigint().min(1n),
});

interface LPFormProps {
  orderType: LPOrderType;
}

type LPFormValues = z.infer<typeof lpFormSchema>;

export default function LPForm({ orderType }: LPFormProps) {
  const form = useForm<LPFormValues>({
    resolver: zodResolver(lpFormSchema),
    defaultValues: {
      orderType,
      size: 0n,
    },
  });

  const [sizeInput, setSizeInput] = useState<string>('');

  const { account } = useAccountStore((state) => ({
    account: state.account,
  }));

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));
  const accountMarkets = getAccountMarketStores(account, markets);

  const newOrders = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]),
    ),
  );

  const availableFunds = useMemo(
    () => getAvailableFunds(account, markets, newOrders),
    [account, accountMarkets, newOrders],
  );

  const { data: fetchedAccount } = useFetchAccount();
  const availableToWithdraw = fetchedAccount
    ? parseDecimalToBigInt(fetchedAccount.lp.balance, COLLATERAL_DECIMALS)
    : 0n;

  const maxAmount =
    orderType === LPOrderType.DEPOSIT
      ? availableFunds.bigint
      : availableToWithdraw; // TODO max amount rounding?

  const { mutateAsync: createLPOrder } = useCreateLpOrder();

  const { isSubmitting, isValid } = form.formState;

  const onSubmit = async (values: LPFormValues) => {
    if (orderType === LPOrderType.DEPOSIT) {
      await createLPOrder({
        orderType: values.orderType,
        size: bigIntToDecimalStr(values.size, COLLATERAL_DECIMALS),
      });
    } else {
      if (availableToWithdraw === 0n || !fetchedAccount?.lp.shares) {
        throw new Error('No available funds to withdraw');
      }
      const sizeInShares =
        (((values.size * 10n ** COLLATERAL_DECIMALS) / availableToWithdraw) *
          parseDecimalToBigInt(
            fetchedAccount?.lp.shares,
            COLLATERAL_DECIMALS,
          )) /
        10n ** COLLATERAL_DECIMALS;
      await createLPOrder({
        orderType: values.orderType,
        size: bigIntToDecimalStr(sizeInShares, COLLATERAL_DECIMALS),
      });
    }
    form.reset();
    setSizeInput('');
  };

  return (
    <div>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.error('Form validation errors:', errors);
          })}
        >
          <div className="flex flex-col gap-4 px-2.5 py-6">
            <div className="flex flex-col gap-3 px-3">
              <h4 className="text-vestgrey-200">Amount</h4>
              <div className="flex items-center gap-2">
                <Controller
                  control={form.control}
                  name="size"
                  render={({ field }) => (
                    <Input
                      id="size"
                      className="h-10 flex-1 rounded-none bg-vestgrey-800 font-mono text-base text-foreground"
                      type="text"
                      value={sizeInput}
                      onChange={(e) => {
                        try {
                          const valueStr = validatedDecimalStr(
                            e.target.value,
                            2,
                            10,
                          );
                          const value = parseDecimalToBigInt(
                            valueStr,
                            COLLATERAL_DECIMALS,
                          );
                          if (value <= maxAmount) {
                            field.onChange(value);
                            setSizeInput(valueStr);
                          }
                        } catch {}
                      }}
                    />
                  )}
                />
                <button
                  className="h-10 bg-black-alt px-4 py-2 font-mono text-sm uppercase"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    form.setValue('size', maxAmount, {
                      shouldValidate: true,
                    });
                    setSizeInput(
                      formatBigInt(maxAmount, COLLATERAL_DECIMALS, {
                        digits: 2,
                      }),
                    );
                  }}
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="flex h-10 items-center justify-between px-3">
              <span className="flex items-center gap-2">
                <p className="text-vestgrey-200">Available</p>
                {/* <TooltipProvider> // TODO: tooltip content must be styled better
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <Info size={14} className="text-vestgrey-100" />
                    </TooltipTrigger>
                    <TooltipContent>
                      {orderType === LPOrderType.DEPOSIT
                        ? 'To add liquidity, you must first deposit funds into your account.'
                        : 'Removed liquidity will be available for withdrawal from your account.'}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider> */}
              </span>
              <p className="text-font font-mono">
                {formatBigInt(maxAmount, COLLATERAL_DECIMALS, { digits: 2 })}
              </p>
            </div>
          </div>
          <Button
            type="submit"
            className="h-16 w-full rounded-none font-mono text-lg font-normal uppercase tracking-wider"
            disabled={isSubmitting || !isValid}
          >
            {orderType === LPOrderType.DEPOSIT
              ? 'Add Liquidity'
              : 'Remove Liquidity'}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
