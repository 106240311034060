import { create } from 'zustand';
import { TransferType, OrderStatus } from '@/types/enums';

export type NewTransfer = {
  id: string;
  size: bigint;
  orderType: TransferType;
  status: OrderStatus.NEW;
  chainId: number;
  postTime: number;
};

export type FilledTransfer = {
  id: string;
  size: bigint;
  orderType: TransferType;
  status: OrderStatus.FILLED;
  chainId: number;
  postTime: number;
};

export type RejectedTransfer = {
  id: string;
  orderType: TransferType;
  status: OrderStatus.REJECTED;
  code: number;
  chainId: number;
  postTime: number;
};

export type Transfer = NewTransfer | FilledTransfer | RejectedTransfer;

interface TransfersStore {
  newDeposits: Transfer[];
  addNewDeposit: (transfer: Transfer) => void;
  removeNewDeposit: (id: string) => void;

  transfers: Record<string, Transfer>;
  setTransfer: (id: string, transfer: Transfer) => void;
  clearTransfersStore: () => void;
}

export const useTransfersStore = create<TransfersStore>((set, get) => ({
  newDeposits: [],
  addNewDeposit: (transfer: Transfer) =>
    set((state) => ({ newDeposits: [...state.newDeposits, transfer] })),
  removeNewDeposit: (id: string) =>
    set((state) => ({
      newDeposits: state.newDeposits.filter((t) => t.id !== id),
    })),
  transfers: {},
  setTransfer: (id: string, transfer: Transfer) =>
    set((state) => ({ transfers: { ...state.transfers, [id]: transfer } })),
  clearTransfersStore: () => {
    set({
      newDeposits: [],
      transfers: {},
    });
  },
}));
