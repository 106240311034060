import React, { useState, useEffect, useMemo } from 'react';
import {
  FormControl,
  FormItem,
  FormLabel,
} from '@/features/trade/order/components/order-form-components';
import { Input } from '@/components/ui';
import { UnitSelect } from './unit-select';
import { abbrFromSym } from '@/utils/token-symbol';
import {
  adjustDecimals,
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
  validatedDecimalStr,
} from '@/utils/value-format';
import {
  COLLATERAL_DECIMALS,
  COLLATERAL_SYMBOL,
  useMarketStore,
} from '@/store/use-markets-store';
import { getSizeFromNumeraire } from '../utils/math';
import { set, useFormContext } from 'react-hook-form';
import { CustomSlider } from './custom-slider';
import { rangeCount } from '../utils/helpers';

export enum SliderType {
  LEVERAGE,
  PCT,
}

interface SizeInputProps {
  symbol: string;
  price: bigint;
  onBaseSizeChange: (baseSize: bigint) => void;
  onSizeUnitChange?: (unit: string) => void;
  onLeverageChange?: (leverage: number) => void;
  onSizeInputChange?: (size: string) => void;
  sliderType?: SliderType;
  maxBaseSize?: bigint; // Enables sizePct slider
  maxNumeraireSize?: bigint; // Enables max size button, TODO: reconcile the two?
  maxLeverage?: number;
  isSuccess?: boolean;
  defaultSize?: bigint;
}

export const SizeInput: React.FC<SizeInputProps> = ({
  symbol,
  price,
  onBaseSizeChange,
  onSizeInputChange,
  onSizeUnitChange,
  onLeverageChange,
  sliderType,
  maxBaseSize,
  isSuccess,
  maxNumeraireSize,
  maxLeverage,
  defaultSize,
}) => {
  const { formState } = useFormContext();

  const marketSpec = useMemo(() => {
    return useMarketStore(symbol).getState().marketSpec; // TODO: is ready hook
  }, [symbol]);

  const [sizeInput, setSizeInput] = useState<string>(
    defaultSize
      ? formatBigInt(
          defaultSize * price,
          marketSpec.sizeDecimals + marketSpec.priceDecimals,
          { digits: 2 },
        )
      : '',
  );
  const [sizeUnit, setSizeUnit] = useState<string>(COLLATERAL_SYMBOL);
  const [sizePct, setSizePct] = useState<number>(
    defaultSize && maxBaseSize
      ? Number((defaultSize * 10n ** 6n) / maxBaseSize / 10n ** 4n)
      : 0,
  );
  const [leverage, setLeverage] = useState<number>(1);
  const [lastChangedInput, setLastChangedInput] = useState<
    'sizeInput' | 'sizePct' | null
  >(null);

  const handleSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let validatedValue: string;
    if (sizeUnit === COLLATERAL_SYMBOL) {
      validatedValue = validatedDecimalStr(e.target.value, 2, 10);
    } else if (sizeUnit === abbrFromSym(symbol)) {
      validatedValue = validatedDecimalStr(
        e.target.value,
        Number(marketSpec.sizeDecimals),
        10,
      );
      // } else if (sizeUnit === '%') {
      //   validatedValue = validatedDecimalStr(e.target.value, 2, 3);
      //   setSizePct(Number(validatedValue));
    } else {
      throw new Error('Invalid size unit');
    }

    setSizeInput(validatedValue);
    setLastChangedInput('sizeInput');
    onSizeInputChange?.(validatedValue);
  };

  const handleUnitChange = (unit: string) => {
    setSizeUnit(unit);
    onSizeUnitChange?.(unit);
    setLastChangedInput('sizeInput');
  };

  const handleSizePctChange = (val: number) => {
    if (!maxBaseSize) {
      throw new Error('maxBaseSize is required for pct slider');
    }
    if (val > 100) return;

    setSizePct(val);

    const valBigInt = BigInt((val * 10 ** 4).toFixed(0));
    const baseSize = (valBigInt * maxBaseSize) / 10n ** 6n;
    onBaseSizeChange(baseSize);

    switch (sizeUnit) {
      case COLLATERAL_SYMBOL:
        const numeraireSize = adjustDecimals(
          baseSize * price,
          COLLATERAL_DECIMALS -
            marketSpec.priceDecimals -
            marketSpec.sizeDecimals,
        );
        setSizeInput(
          formatNumber(bigIntToDecimalStr(numeraireSize, COLLATERAL_DECIMALS), {
            maxDigits: 2,
            commas: false,
          }),
        );
        break;

      case abbrFromSym(symbol):
        setSizeInput(bigIntToDecimalStr(baseSize, marketSpec.sizeDecimals));
        break;

      default:
        throw new Error('Invalid size unit');
    }

    setLastChangedInput('sizePct');
  };

  const handleLeverageChange = (val: number) => {
    setLeverage(val);
    onLeverageChange?.(val);
  };

  // Set baseSize from sizeInput, sizeUnit, leverage change
  useEffect(() => {
    if (lastChangedInput !== 'sizeInput') return;

    let baseSize: bigint;
    if (sizeUnit === COLLATERAL_SYMBOL) {
      if (price === 0n) {
        baseSize = 0n;
      } else {
        baseSize = getSizeFromNumeraire(
          parseDecimalToBigInt(sizeInput, COLLATERAL_DECIMALS),
          price,
          marketSpec,
          false,
        );
      }
    } else {
      baseSize = parseDecimalToBigInt(sizeInput, marketSpec.sizeDecimals);
    }
    const leverageBigInt = parseDecimalToBigInt(String(leverage), 2n);
    baseSize = (baseSize * leverageBigInt) / 10n ** 2n;
    onBaseSizeChange(baseSize);

    if (maxBaseSize) {
      const sizePctBigInt = (baseSize * 10n ** 6n) / maxBaseSize;
      setSizePct(
        Number(formatBigInt(sizePctBigInt, 4, { digits: 1, commas: false })),
      );
    }
  }, [sizeInput, sizeUnit, leverage, price]);

  useEffect(() => {
    if (formState.isSubmitting || !isSuccess) return;
    setSizeInput('');
    setSizePct(0);
    setLeverage(1);
  }, [formState.isSubmitting, isSuccess]);

  return (
    <span>
      <FormItem className="h-10">
        <p className="text-vestgrey-200">Size</p>
        <div className="flex" onClick={(e) => e.stopPropagation()}>
          <span className="flex gap-2">
            {maxNumeraireSize !== undefined && (
              <button
                className="h-10 bg-black-alt px-4 py-2 font-mono text-sm uppercase text-white disabled:text-vestgrey-600"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  if (sizeUnit === COLLATERAL_SYMBOL) {
                    setSizeInput(
                      formatBigInt(maxNumeraireSize, COLLATERAL_DECIMALS, {
                        digits: 2,
                        commas: false,
                      }),
                    );
                  } else if (sizeUnit === abbrFromSym(symbol)) {
                    const maxBaseSize = getSizeFromNumeraire(
                      maxNumeraireSize,
                      price,
                      marketSpec,
                    );
                    setSizeInput(
                      formatBigInt(maxBaseSize, marketSpec.sizeDecimals, {
                        maxDigits: Number(marketSpec.sizeDecimals),
                      }),
                    );
                  }
                  setLastChangedInput('sizeInput');
                }}
                disabled={maxNumeraireSize === 0n}
              >
                MAX
              </button>
            )}
            <FormControl>
              <Input
                className="max-w-[15ch] rounded-none bg-vestgrey-800 font-mono text-base text-foreground"
                onChange={handleSizeChange}
                value={sizeInput}
              />
            </FormControl>
          </span>
          <UnitSelect
            units={['USDC', abbrFromSym(symbol)]}
            onSelect={handleUnitChange}
          />
        </div>
      </FormItem>
      {sliderType === SliderType.PCT && maxBaseSize !== undefined && (
        <FormItem className="flex-col items-stretch gap-2">
          <p className="text-vestgrey-200">Close</p>
          <CustomSlider
            onChange={handleSizePctChange}
            value={sizePct}
            minValue={0}
            maxValue={100}
            unitSymbol="%"
            buttonValues={rangeCount(25, 100, 4, 0)}
            step={1}
          />
        </FormItem>
      )}
      {sliderType === SliderType.LEVERAGE && maxLeverage && (
        <FormItem className="flex-col items-stretch gap-2">
          <p className="text-vestgrey-200">Leverage</p>
          <CustomSlider
            onChange={handleLeverageChange}
            value={leverage}
            minValue={1}
            maxValue={maxLeverage}
            unitSymbol={'X'}
            buttonValues={
              maxLeverage == 20
                ? [1, 5, 10, 15, 20]
                : maxLeverage == 10
                  ? [1, 2, 5, 8, 10]
                  : [1, 2, 3, 4, 5]
            }
            step={0.1}
          />
        </FormItem>
      )}
    </span>
  );
};
