import { privateApi } from '@/lib/api-clients/rest-client';
import { ErrorMsgs } from '@/types/enums';
import { toast } from 'sonner';

export default async function postJoin(refCode: string) {
  try {
    await privateApi.post('/referrals/join', {
      refCode,
    });
    toast.success(`Joined with referral code ${refCode}!`);
    localStorage.removeItem('ref-code');
  } catch (err) {
    const error = err as keyof typeof ErrorMsgs;
    if (error in ErrorMsgs) {
      toast.error(ErrorMsgs[error]);
    } else {
      toast.error('Failed to join with referral code');
    }
  }
}
