import { useState, useRef, useEffect, useMemo } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { MarketsTableContainer } from '@/features/markets/components/markets-table';
import { cn } from '@/utils/cn';
import { useNavigate, useParams } from 'react-router-dom';
import { imgFromAbbr, abbrFromSym } from '@/utils/token-symbol';
import { FavoriteButton } from '@/features/markets/components/favorite-button';
import { getMaxLeverage } from '../order/utils/math';
import { useMarketStore } from '@/store/use-markets-store';
import { formatNumber } from '@/utils/value-format';
import { useStore } from 'zustand';

export const MarketsDropdown = () => {
  const { symbol } = useParams() as { symbol: string };
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { marketSpec } = useStore(useMarketStore(symbol), (state) => ({
    marketSpec: state.marketSpec,
  }));

  const maxLeverage: string | undefined = useMemo(() => {
    return getMaxLeverage(marketSpec)?.decimal;
  }, [marketSpec]);

  const handleRowClick = (symbol: string) => {
    navigate(`/trade/${symbol}`);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <div className="flex h-full gap-4 border-r border-border pl-8">
        <div className="self-center">
          <FavoriteButton symbol={symbol} size={18} />
        </div>
        <div className="grid grow">
          <button
            className="flex items-center justify-between gap-3 pr-8"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="flex items-center gap-3">
              <img
                src={imgFromAbbr(abbrFromSym(params?.symbol))}
                alt="Token Image"
                className="h-6 w-6"
              />
              <span className="text-font text-lg font-bold">{`${abbrFromSym(params?.symbol)} / USDC`}</span>
              {maxLeverage && (
                <div className="rounded bg-[#271714] px-2 py-0.5">
                  <span className="font-mono text-primary">
                    {formatNumber(maxLeverage)}X
                  </span>
                </div>
              )}
            </div>
            <div>
              <ChevronDownIcon
                strokeWidth={1.5}
                className={cn(
                  'text-vestgrey-100 transition-transform duration-300',
                  {
                    'rotate-180': isOpen,
                  },
                )}
              />
            </div>
          </button>
        </div>
        {/*  */}
      </div>
      <div
        className={cn(
          'absolute z-10 w-full max-w-[800px] overflow-hidden bg-background shadow-lg shadow-primary/5 transition-all duration-200',
          {
            'pointer-events-none opacity-0': !isOpen,
            'opacity-100': isOpen,
          },
        )}
      >
        <MarketsTableContainer isOpen={isOpen} onRowClick={handleRowClick} />
      </div>
    </div>
  );
};
