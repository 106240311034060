export default function SystemClockBanner() {
  return (
    <div className="flex items-center bg-brown px-8 py-4">
      <p className="text-lg tracking-wider text-primary">
        <strong>Your system clock is out of sync.</strong> Please update your
        system clock to the correct time before creating orders. If the clock
        remains unsynced, orders may expire, but we will automatically retry
        using accurate time fetched from reliable third-party sources.
      </p>
    </div>
  );
}
