import * as React from 'react';
import { flexRender } from '@tanstack/react-table';
import { cn } from '@/utils/cn';

interface MarketRowProps {
  row: any;
  onRowClick: (symbol: string) => void;
}

export const MarketRow: React.FC<MarketRowProps> = ({ row, onRowClick }) => {
  const symbol = row.original.symbol;
  return (
    <tr
      key={row.id}
      className="group cursor-pointer"
      onClick={() => onRowClick(symbol)}
    >
      {row.getVisibleCells().map((cell: any) => (
        <td
          key={cell.id}
          className={cn(
            'px-6 py-2 font-mono text-vestgrey-50 group-hover:bg-primary/5',
            // @ts-ignore
            cell.column.columnDef.meta?.width,
          )}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};
