import { Bug } from 'lucide-react';
import { Button, Input } from './ui';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { FormEvent, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Textarea } from './ui/textarea';
import { Label } from './ui/label';
import vestIcon from '@/assets/branding/vest-icon.svg';

export default function BugReport() {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(true);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    let attachment: {
      filename: string;
      data: Uint8Array;
      contentType: string;
    } | null = null;
    if (file) {
      try {
        const buffer = await file.arrayBuffer();
        const data = new Uint8Array(buffer);
        attachment = { filename: file.name, data, contentType: file.type };
      } catch (err) {
        console.warn("Couldn't upload image: ", err);
      }
    }
    Sentry.captureFeedback(
      {
        message,
        ...(email ? { email } : {}),
        ...(name ? { name } : {}),
      },
      { attachments: attachment ? [attachment] : [] },
    );
    setIsSent(true);
    setIsLoading(false);
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setIsSent(false);
      setIsLoading(false);
      setEmail('');
      setName('');
      setFile(undefined);
      setMessage('');
    }
    setIsOpen(open);
  };

  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          size="icon"
          variant="secondary"
          className="fixed bottom-4 left-4 h-14 w-14"
        >
          <Bug size={16} />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={14}
        className="flex flex-col gap-3"
      >
        <div className="mb-2 flex items-center gap-3">
          <img src={vestIcon} alt="Vest Logo" />
          <h4 className="text-xl font-semibold tracking-tight text-foreground">
            Report a Bug
          </h4>
        </div>
        {!isSent ? (
          <>
            <form onSubmit={onSubmit}>
              <div className="flex flex-col gap-4">
                <div className="space-y-2">
                  <Label htmlFor="feedback-name">Name</Label>
                  <Input
                    type="text"
                    placeholder="Your Name"
                    id="feedback-name"
                    className="h-12 border border-border text-base text-foreground"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="feedback-email">Email</Label>
                  <Input
                    value={email}
                    type="email"
                    placeholder="your.email@example.org"
                    id="feedback-email"
                    className="h-12 border border-border text-base text-foreground"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="feedback-description">
                    Description (required)
                  </Label>
                  <Textarea
                    required
                    className="min-h-32"
                    placeholder="What happened? What did you expect?"
                    id="feedback-description"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="feedback-email">Screenshot</Label>
                  <Input
                    type="file"
                    id="feedback-attachment"
                    accept="image/png, image/gif, image/jpeg"
                    className="h-12 border border-border text-base text-foreground file:text-foreground"
                    onChange={(e) =>
                      e.target.files &&
                      e.target.files.length > 0 &&
                      setFile(e.target.files[0])
                    }
                  />
                </div>
                <Button
                  disabled={isLoading}
                  size="lg"
                  className="mt-4 h-12 w-full rounded-none font-mono text-base uppercase tracking-wider"
                >
                  {isLoading ? 'Processing image' : 'Send Bug Report'}
                </Button>
              </div>
            </form>
            <Button
              variant="outline"
              className="h-12 w-full rounded-none font-mono text-base uppercase tracking-wider"
              size="lg"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <div className="flex flex-col items-center gap-4 py-6">
            <div
              className="grid h-12 w-12 place-content-center"
              style={{ color: '#58F5BD', backgroundColor: '#58F5BD0D' }}
            >
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.50018 6.11714L11.0001 0L12 0.941098L4.50018 8L0 3.76439L0.999882 2.82329L4.50018 6.11714Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <p className="text-center text-foreground">
              Thank you for sharing your feedback. We will make sure to resolve
              the bug as fast as possible!
            </p>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
