// import useWelcomeStore from "stores/welcome"

import { useQuery } from '@tanstack/react-query';

const SANCTIONED_COUNTRIES = [
  // ['AG', 'Antigua and Barbuda'],
  // ['DZ', 'Algeria'],
  // ['BD', 'Bangladesh'],
  // ['BO', 'Bolivia'],
  ['BY', 'Belarus'],
  ['BI', 'Burundi'],
  // ['MM', 'Burma (Myanmar)'],
  // ['CI', "Cote D'Ivoire (Ivory Coast)"],
  ['CU', 'Cuba'],
  ['CD', 'Democratic Republic of the Congo'],
  // ['EC', 'Ecuador'],
  // ['GB', 'United Kingdom'],
  ['IR', 'Iran'],
  ['IQ', 'Iraq'],
  ['LB', 'Lebanon'],
  // ['LR', 'Liberia'],
  // ['LY', 'Libya'],
  // ['ML', 'Mali'],
  // ['MA', 'Morocco'],
  // ['NP', 'Nepal'],
  ['NI', 'Nicaragua'],
  ['KP', 'North Korea'],
  ['RU', 'Russia'],
  ['SO', 'Somalia'],
  ['SD', 'Sudan'],
  ['SY', 'Syria'],
  ['-', 'Crimea (Ukraine)'],
  ['-', 'Donetsk (Ukraine)'],
  ['-', 'Luhansk (Ukraine)'],
  ['VE', 'Venezuela'],
  ['YE', 'Yemen'],
  ['ZW', 'Zimbabwe'],
  ['US', 'United States'],
];

const SANCTIONED_COUNTRY_CODES = SANCTIONED_COUNTRIES.map(
  (country) => country[0],
);

export const SANCTIONED_COUNTRY_NAMES = SANCTIONED_COUNTRIES.map(
  (country) => country[1],
);

// Returns true if user is from a sanction country
async function geofenceCheck() {
  const response = await fetch(
    `https://api.geoapify.com/v1/ipinfo?apiKey=1d7ac37f9dd94072a7c128d8953ff250`,
  );
  const parsedResponse = await response.json();
  const ipCountryCode = parsedResponse?.country.iso_code;

  // Sets Geofence State to True in Welcome Store if Country is geofenced
  return SANCTIONED_COUNTRY_CODES.includes(ipCountryCode);
}

const useGeofenceCheck = () =>
  useQuery({
    queryKey: ['is_sanctioned'],
    queryFn: () => geofenceCheck(),
  });

export default useGeofenceCheck;
