import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { publicApi } from '@/lib/api-clients/rest-client';
import {
  createMarketStore,
  MarketSpec,
  useMarketStore,
} from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { QueryConfig } from '@/lib/api-clients/react-query';

export type SymbolInfo = {
  symbol: string;
  displayName: string;
  base: string;
  quote: string;
  sizeDecimals: number;
  priceDecimals: number;
  initMarginRatio: string;
  maintMarginRatio: string;
  takerFee: string;
};

export type ExchangeInfo = {
  symbols: SymbolInfo[];
  exchange: {
    lp: string;
    insurance: string;
  };
};

export const FEE_DECIMALS = 5n;

export const getExchangeInfo = async (
  symbols?: string,
): Promise<ExchangeInfo> => {
  return publicApi
    .get<ExchangeInfo>('/exchangeInfo', {
      params: {
        symbols,
      },
    })
    .then((res: ExchangeInfo) => {
      const { symbols } = res;
      symbols.map((symbolInfo: SymbolInfo) => {
        createMarketStore(symbolInfo.symbol);
        const { setMarketSpec } = useMarketStore(symbolInfo.symbol).getState();
        const marketSpec: MarketSpec = {
          displayName: symbolInfo.displayName,
          base: symbolInfo.base,
          quote: symbolInfo.quote,
          sizeDecimals: BigInt(symbolInfo.sizeDecimals),
          priceDecimals: BigInt(symbolInfo.priceDecimals),
          initMarginRatio: parseDecimalToBigInt(symbolInfo.initMarginRatio, 4n),
          maintMarginRatio: parseDecimalToBigInt(
            symbolInfo.maintMarginRatio,
            4n,
          ),
          takerFee: parseDecimalToBigInt(symbolInfo.takerFee, FEE_DECIMALS),
        };
        setMarketSpec(marketSpec);
      });
      return res;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getExchangeInfoQueryOptions = ({
  symbols,
}: {
  symbols?: string;
} = {}): UseQueryOptions => {
  return {
    queryKey: symbols ? ['exchangeInfo', { symbols }] : ['exchangeInfo'],
    queryFn: () => getExchangeInfo(symbols),
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  };
};

type UseExchangeInfoOptions = {
  symbols?: string;
  queryConfig?: QueryConfig<typeof getExchangeInfo>;
};

export const useExchangeInfo = ({
  queryConfig,
  symbols,
}: UseExchangeInfoOptions = {}) => {
  return useQuery({
    ...getExchangeInfoQueryOptions({ symbols }),
    ...queryConfig,
  });
};
