import * as React from 'react';
import { StarIcon, StarFilledIcon } from '@radix-ui/react-icons';
import { useFavorites } from '@/hooks';
interface IFavoriteButtonProps {
  symbol: string;
  size?: number;
}

export const FavoriteButton: React.FC<IFavoriteButtonProps> = ({
  symbol,
  size = 14,
}) => {
  const { isFavorite, toggleFavorite } = useFavorites();

  const handleFavorite = (e: React.BaseSyntheticEvent) => {
    toggleFavorite(symbol);
    e.stopPropagation();
  };

  return (
    <div className="cursor-pointer" onClick={handleFavorite}>
      {isFavorite(symbol) ? (
        <StarFilledIcon className="text-primary" width={size} height={size} />
      ) : (
        <StarIcon className="text-vestgrey-200" width={size} height={size} />
      )}
    </div>
  );
};

// import * as React from 'react';
// import { StarIcon, StarFilledIcon } from '@radix-ui/react-icons';
// import { useFavoritesStore } from '@/store/use-favorites-store';

// interface IFavoriteButtonProps {
//   symbol: string;
//   size?: number;
// }

// export const FavoriteButton: React.FC<IFavoriteButtonProps> = ({
//   symbol,
//   size = 14,
// }) => {
//   const { isFavorite, toggleFavorite } = useFavoritesStore();

//   const handleFavorite = (e: React.BaseSyntheticEvent) => {
//     toggleFavorite(symbol);
//     e.stopPropagation();
//   };

//   return (
//     <div className="cursor-pointer" onClick={handleFavorite}>
//       {isFavorite(symbol) ? (
//         <StarFilledIcon className="text-primary" width={size} height={size} />
//       ) : (
//         <StarIcon className="text-vestgrey-200" width={size} height={size} />
//       )}
//     </div>
//   );
// };
