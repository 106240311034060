import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import env from '@/config';
import { useAccountStore } from '@/store/use-account-store';
import { signMessage } from 'viem/accounts';
import { ErrorCode } from '@/types/enums';

export type ApiClient = Omit<
  AxiosInstance,
  'get' | 'post' | 'put' | 'delete' | 'patch'
> & {
  get<T = any>(url: string, config?: any): Promise<T>;
  post<T = any>(url: string, data?: any, config?: any): Promise<T>;
  put<T = any>(url: string, data?: any, config?: any): Promise<T>;
  delete<T = any>(url: string, config?: any): Promise<T>;
  patch<T = any>(url: string, data?: any, config?: any): Promise<T>;
};

const REST_URL = import.meta.env.VITE_REST_URL;

export const publicApi: ApiClient = Axios.create({
  baseURL: REST_URL,
  headers: {
    common: {
      xrestservermm: 'restserver0', // default to 0
    },
  },
});

export const privateApi: ApiClient = Axios.create({
  baseURL: REST_URL,
});

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = 'application/json';
  }

  if (import.meta.env.VITE_ENV === 'development') {
    // WORKAROUND FOR DEV
    config.withCredentials = false;
  }
  return config;
}
publicApi.interceptors.request.use(authRequestInterceptor);
privateApi.interceptors.request.use(authRequestInterceptor);

async function signatureInterceptor(config: InternalAxiosRequestConfig) {
  const method = config.method?.toUpperCase();
  if (method === 'GET') {
    // const { authParams } = useAccountStore.getState();
    // if (!authParams) {
    //   throw new Error('No auth params');
    // }
    // const path = config.url || '';
    const time = Date.now();
    try {
      // const signature = await signMessage({
      //   message: `${path},time=${time}`,
      //   privateKey: authParams.signingKey,
      // });
      config.params = {
        ...config.params,
        // signature,
        time,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  return config;
}
privateApi.interceptors.request.use(signatureInterceptor);

function responseInterceptor(response: AxiosResponse) {
  return response.data;
}

function errorInterceptor(error: AxiosError) {
  console.error(error);
  return Promise.reject(error);
}

type ErrorResponse = {
  detail: {
    code: ErrorCode;
    msg: string;
  };
};

function privateApiErrorInterceptor(error: AxiosError) {
  console.error(error);
  const data = error.response?.data as ErrorResponse;
  if (data?.detail?.code) {
    return Promise.reject(data.detail.code as ErrorCode);
  } else {
    if (error.response?.status === 401 || error.response?.status === 403) {
      // Not authenticated
      return Promise.reject(ErrorCode.UNAUTHORIZED);
    }
    return Promise.reject(ErrorCode.UNKNOWN);
  }
}
publicApi.interceptors.response.use(responseInterceptor, errorInterceptor);
privateApi.interceptors.response.use(
  responseInterceptor,
  privateApiErrorInterceptor,
);

export function updatePrivateApiHeaders(headers: Record<string, string>) {
  Object.entries(headers).forEach(([key, value]) => {
    privateApi.defaults.headers.common[key] = value;
  });
}

export function clearPrivateApiHeaders(headers: string[]) {
  headers.forEach((header) => {
    delete privateApi.defaults.headers.common[header];
  });
}
