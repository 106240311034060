import { Button } from '@/components/ui';
import { Link } from '@/components/ui/link';
import animation from '@/assets/branding/404.gif';

export default function NotFoundPage() {
  return (
    <div className="relative flex h-full items-center justify-center">
      <div className="relative z-10 flex flex-col items-center pb-16">
        <h1 className="text-[128px] font-bold leading-tight">404</h1>
        <p className="text-lg">Something went wrong...</p>
        <Button
          size="lg"
          className="mt-8 h-14 rounded-none font-mono text-lg uppercase tracking-wider"
          asChild
        >
          <Link to="/" replace>
            Back to homepage
          </Link>
        </Button>
      </div>
      <img
        className="absolute bottom-0 top-0 h-full opacity-50"
        src={animation}
        alt="404 error"
      />
    </div>
  );
}
