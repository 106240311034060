// export function linspace(
//   start: number,
//   stop: number,
//   num: number,
//   endpoint: boolean = true,
// ): number[] {
//   const div = endpoint ? num - 1 : num;
//   const step = (stop - start) / div;
//   return Array.from({ length: num }, (_, i) => start + step * i);
// }

export function range(start: number, stop: number, step: number): number[] {
  const result = [];
  for (let i = start; i <= stop; i += step) {
    result.push(Number(i.toFixed(2))); // Rounds to 2 decimal places
  }
  return result;
}

export function rangeCount(
  start: number,
  stop: number,
  count: number,
  maxDecimals: number,
): number[] {
  if (count <= 1) return [start];
  const step = (stop - start) / (count - 1);
  return Array.from({ length: count }, (_, i) => {
    const value = start + step * i;
    return Number(value.toFixed(maxDecimals)); // Rounds to 2 decimal places
  });
}
