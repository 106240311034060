import { useAccountStore } from '@/store/use-account-store';
import React, { createContext, useCallback, useEffect, useState } from 'react';

type FavoriteMarketsContextType = {
  favorites: Set<string>;
  toggleFavorite: (symbol: string) => void;
  isFavorite: (symbol: string) => boolean;
};

export const FavoriteMarketsContext = createContext<FavoriteMarketsContextType>(
  null!,
);

export default function FavoriteMarketsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { address } = useAccountStore((state) => ({
    address: state.authParams?.address,
  }));
  const [favorites, setFavorites] = useState<Set<string>>(new Set([]));

  useEffect(() => {
    const stored = localStorage.getItem(
      address ? `favoriteMarkets-${address}` : 'favoriteMarkets',
    );
    setFavorites(new Set(stored ? JSON.parse(stored) : []));
  }, [address]);

  useEffect(() => {
    localStorage.setItem(
      address ? `favoriteMarkets-${address}` : 'favoriteMarkets',
      JSON.stringify([...favorites]),
    );
  }, [favorites, address]);

  const isFavorite = useCallback(
    (symbol: string) => favorites.has(symbol),
    [favorites],
  );

  const toggleFavorite = useCallback((symbol: string) => {
    setFavorites((prev) => {
      const newFavorites = new Set(prev);
      if (newFavorites.has(symbol)) {
        newFavorites.delete(symbol);
      } else {
        newFavorites.add(symbol);
      }
      return newFavorites;
    });
  }, []);

  return (
    <FavoriteMarketsContext.Provider
      value={{ favorites, toggleFavorite, isFavorite }}
    >
      {children}
    </FavoriteMarketsContext.Provider>
  );
}
