import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui';
import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { ErrorMsgs } from '@/types/enums';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

export default function UseReferralCodeDialog() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const hasAuth = useAccountStore((state) => state.hasAuth);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const refCode = searchParams.get('ref');

  useEffect(() => {
    if (!refCode) return;
    setIsOpen(true);
  }, [refCode]);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();
    if (!refCode) return;
    setIsLoading(true);
    try {
      if (hasAuth) {
        await privateApi.post('/referrals/join', {
          refCode,
        });
        localStorage.removeItem('ref-code');
        toast.success(`Joined with referral code ${refCode}!`);
      } else {
        localStorage.setItem('ref-code', refCode);
        toast.success(
          'The referral code will be used immediately after you connect',
        );
      }
      navigate({ search: '' });
      setIsOpen(false);
    } catch (err) {
      const error = err as keyof typeof ErrorMsgs;
      if (error in ErrorMsgs) {
        toast.error(ErrorMsgs[error]);
      } else {
        toast.error('Failed to join with referral code');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="p-0">
        <DialogHeader className="px-6 pt-6">
          <DialogTitle className="text-xl">Use Referral Code</DialogTitle>
        </DialogHeader>
        <div className="space-y-6 px-6 py-4">
          <DialogDescription className="text-base text-vestgrey-100">
            Using this code will give you a 10% boost in rewards. You cannot
            change this code once used.
          </DialogDescription>
          <div className="rounded border border-border py-6">
            <p className="text-center text-4xl font-bold uppercase text-foreground">
              {refCode}
            </p>
          </div>
        </div>
        <DialogFooter className="sm:flex-col sm:space-x-0">
          <form onSubmit={onSubmit}>
            <Button
              disabled={isLoading}
              className="h-14 w-full rounded-none font-mono text-lg font-normal uppercase tracking-wider"
            >
              USE REFERRAL CODE
            </Button>
          </form>
          <Button
            onClick={() => setIsOpen(false)}
            className="h-14 rounded-none bg-black font-mono text-lg font-normal uppercase tracking-wider text-foreground hover:bg-black"
          >
            Ignore
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
