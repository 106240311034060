export default function PerformanceChart() {
  return (
    <div className="col-span-3 flex flex-col border-b border-border p-8">
      <h3 className="text-font text-lg font-semibold sm:text-xl">
        Performance
      </h3>
      <div className="flex flex-1 items-center justify-center">
        <span className="text-lg text-vestgrey-600">Coming soon...</span>
      </div>
    </div>
  );
}
