/* eslint-disable */
import { parseAbi } from 'viem';

export default parseAbi([
  'function publicMint(uint256 amount) external',
  'function approve(address spender, uint256 amount) external',
  'function transfer(address to, uint256 amount) external',
  'function balanceOf(address _owner) external returns(uint256)',
  'function transferFrom(address from, address to, uint256 amount)',
  'function allowance(address from, address to) external returns (uint256)',
]);

// const PAYMASTER_ABI = parseAbi([
//   'function whitelist(address userAddress) view returns(bool)',
//   'function setToWhitelist(address[] usersAddresses) external',
// ]);
