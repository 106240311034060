import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/lib/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  TooltipPrimitive.TooltipTriggerProps & React.RefAttributes<HTMLButtonElement>
>(({ children, className, asChild = true, ...props }, ref) => (
  <TooltipPrimitive.Trigger {...props} type="button" asChild={asChild}>
    {children}
  </TooltipPrimitive.Trigger>
));

type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  position?: TooltipPosition;
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(
  (
    { className, position = 'top', sideOffset = 5, children, ...props },
    ref,
  ) => (
    <TooltipPrimitive.Content
      ref={ref}
      side={position}
      sideOffset={sideOffset}
      avoidCollisions={true}
      collisionPadding={8}
      className={cn(
        'relative z-50 inline-block max-w-xs px-3 py-1.5',
        'rounded-md bg-border text-primary-foreground',
        'text-center text-sm',
        'animate-in fade-in-0 zoom-in-95',
        'data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95',
        'data-[side=bottom]:slide-in-from-top-2',
        'data-[side=left]:slide-in-from-right-2',
        'data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    >
      {children}
      {/* <TooltipArrow position={position} /> */}
    </TooltipPrimitive.Content>
  ),
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const TooltipArrow = ({ position }: { position: TooltipPosition }) => {
  const arrowPositionStyles = {
    top: 'bottom-full rotate-180',
    bottom: 'top-full',
    left: 'right-full rotate-90',
    right: 'left-full -rotate-90',
  };

  return (
    <svg
      className={cn('absolute z-50', arrowPositionStyles[position])}
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L11 0L5.5 5L0 0Z" fill="#292929" />
    </svg>
  );
};

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
