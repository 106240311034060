import { Table } from '@/components/ui/';
import { createColumnHelper } from '@tanstack/react-table';
import { PositionRow } from './position-row';
import { Position, useAccountStore } from '@/store/use-account-store';
import { useMemo, useState } from 'react';
import {
  getPositionFunding,
  getPositionLiqPrice,
  getPositionPnl,
  getPositionRoePct,
  getPositionValue,
} from '@/features/account/utils/math';
import { useMarketStores } from '@/store/use-markets-store';
import { bigIntToDecimalStr, formatNumber } from '@/utils/value-format';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useFetchAccount } from '../api/get-account';
import {
  abbrFromSym,
  imgFromAbbr,
  symbolToBaseQuote,
} from '@/utils/token-symbol';
import { cn } from '@/utils/cn';
import { CloseDialog } from '@/features/trade/order/components/close-dialog';
import { TPSLEdit } from './tpsl-edit';
import { Order, useOrdersStore } from '@/store/use-orders-store';
import { OrderStatus, OrderType } from '@/types/enums';
import { useOrders } from '../api/get-orders';
import { useShallow } from 'zustand/react/shallow';
import { Link, useNavigate } from 'react-router-dom';
import { useTickerLatest } from '@/features/markets/api/get-ticker-latest';
import { ArrowLeftRight } from 'lucide-react';
import Share from '@/features/trade/components/share';
import useGeofenceCheck from '@/utils/geofence-check';

export type PositionColumn = {
  symbol: string;
  leverage: string;
  size: string;
  isLong: boolean;
  entryPrice: string;
  unrealizedPnl: string;
  fundingPaid: string;
  markPrice: string;
  liqPrice: string;
  roePct: string;
  value: string;
  positionTps: Order[];
  positionSls: Order[];
  minTpPrice?: string;
  maxSlPrice?: string;
  rawPosition: Position;
};

const columnHelper = createColumnHelper<PositionColumn>();

export const PositionsTableContainer = () => {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});

  const { isPending: isAccountPending, isError: isAccountError } =
    useFetchAccount({});

  const { isPending: isOrdersPending, isError: isOrdersError } = useOrders({
    status: OrderStatus.NEW,
  });

  if (isExchangeInfoError) {
    return <></>;
  }

  return <PositionsTable />;
};

const PositionsTable = () => {
  const { data: isSanctioned } = useGeofenceCheck();
  const navigate = useNavigate();
  const [valueActive, setValueActive] = useState(false);
  const account = useAccountStore((state) => state.account);
  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const { tps, sls } = useOrdersStore(
    useShallow((state) => {
      const newOrders = Array.from(state.newOrderIds, (id) => state.orders[id]);
      return {
        tps: newOrders.filter(
          (order) => order.orderType === OrderType.TAKE_PROFIT,
        ),
        sls: newOrders.filter(
          (order) => order.orderType === OrderType.STOP_LOSS,
        ),
      };
    }),
  );

  const positions = useMemo(() => {
    return Object.entries(account.positions).map(([symbol, position]) => {
      const positionTps = tps.filter((tp) => tp.symbol === symbol);
      const positionSls = sls.filter((sl) => sl.symbol === symbol);
      const minTpPrice: bigint | undefined =
        positionTps.length > 0
          ? BigInt(Math.min(...positionTps.map((tp) => Number(tp.limitPrice))))
          : undefined;
      const maxSlPrice: bigint | undefined =
        positionSls.length > 0
          ? BigInt(Math.max(...positionSls.map((sl) => Number(sl.limitPrice))))
          : undefined;
      return {
        symbol,
        leverage: bigIntToDecimalStr(10n ** 6n / position.initMarginRatio, 2n),
        size: `${bigIntToDecimalStr(
          position.size,
          markets[symbol].marketSpec.sizeDecimals,
        )} ${abbrFromSym(symbol)}`,
        isLong: position.isLong,
        entryPrice: bigIntToDecimalStr(
          position.cost / position.size,
          markets[symbol].marketSpec.priceDecimals,
        ),
        unrealizedPnl: getPositionPnl(symbol, account, markets).decimal,
        fundingPaid: getPositionFunding(symbol, account, markets).decimal,
        markPrice: bigIntToDecimalStr(
          markets[symbol].marketData.markPrice,
          markets[symbol].marketSpec.priceDecimals,
        ),
        liqPrice: getPositionLiqPrice(symbol, account, markets).decimal,
        roePct: getPositionRoePct(symbol, account, markets).decimal,
        value: formatNumber(
          getPositionValue(symbol, account, markets).decimal,
          { digits: 2, round: 'ceil' },
        ),
        positionTps,
        positionSls,
        minTpPrice: minTpPrice
          ? bigIntToDecimalStr(
              minTpPrice,
              markets[symbol].marketSpec.priceDecimals,
            )
          : undefined,
        maxSlPrice: maxSlPrice
          ? bigIntToDecimalStr(
              maxSlPrice,
              markets[symbol].marketSpec.priceDecimals,
            )
          : undefined,
        rawPosition: position,
      };
    });
  }, [account, markets, tps, sls]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('symbol', {
        cell: (info) => (
          <Link
            to={`/trade/${info.getValue()}`}
            className="flex cursor-pointer items-center gap-4"
          >
            <img
              src={imgFromAbbr(abbrFromSym(info.getValue()))}
              title={info.getValue()}
              className="h-5 w-5"
            />
            <span className="font-sans">
              {symbolToBaseQuote(info.getValue())}
            </span>
            <div className="rounded bg-[#271714] px-2 py-0.5">
              <span className="font-mono text-sm text-primary">
                {formatNumber(info.row.original.leverage, {
                  style: 'leverage',
                  maxDigits: 2,
                })}
              </span>
            </div>
          </Link>
        ),
        header: () => 'MARKET',
        meta: { width: 'w-[15%]' },
      }),
      columnHelper.accessor('isLong', {
        cell: (info) => (
          <p className={cn(info.getValue() ? 'text-green' : 'text-red')}>
            {info.getValue() ? 'Long' : 'Short'}
          </p>
        ),
        header: () => 'SIDE',
        meta: { width: 'w-[6%]' },
      }),
      columnHelper.accessor(valueActive ? 'value' : 'size', {
        cell: (info) => info.getValue(),
        header: () => (
          <div className="flex items-center gap-2">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setValueActive((prev) => !prev);
              }}
            >
              <ArrowLeftRight size={14} />
            </button>
            <span className="uppercase">{valueActive ? 'Value' : 'Size'}</span>
          </div>
        ),
        meta: { width: 'w-[10%]' },
      }),
      // columnHelper.accessor('value', {
      //   cell: (info) => formatNumber(info.getValue().decimal, { digits: 2 }),
      //   header: () => 'VALUE',
      //   meta: { width: 'w-[10%]' },
      // }),
      columnHelper.accessor((row) => row.entryPrice, {
        id: 'entryPrice',
        cell: (info) => formatNumber(info.getValue()),
        header: () => <span>ENTRY PRICE</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('markPrice', {
        cell: (info) => formatNumber(info.getValue()),
        header: () => <span>MARK PRICE</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('liqPrice', {
        cell: (info) => formatNumber(info.getValue()),
        header: () => <span>LIQ PRICE</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.display({
        id: 'tp-sl',
        cell: ({ row }) => (
          <div className="flex items-center gap-6">
            <span>
              {formatNumber(row.original.minTpPrice)} /{' '}
              {formatNumber(row.original.maxSlPrice)}
            </span>
            <TPSLEdit
              symbol={row.original.symbol}
              // @ts-ignore
              currentPosition={row.original.rawPosition}
              currentTps={row.original.positionTps}
              currentSls={row.original.positionSls}
              disabled={isSanctioned}
            />
          </div>
        ),
        header: () => 'TP/SL',
        meta: { width: 'w-[12.5%]' },
      }),
      columnHelper.accessor('unrealizedPnl', {
        cell: (info) => (
          <p className="text-ellipsis whitespace-nowrap">
            <span
              className={cn(
                Number(info.getValue()) < 0 ? 'text-red' : 'text-green',
              )}
            >
              {`${formatNumber(info.getValue(), { showChange: true, digits: 2 })} / ${formatNumber(
                info.row.original.roePct,
                {
                  digits: 2,
                  style: 'percent',
                  abs: true,
                },
              )}`}
            </span>
            <Share position={info.row.original} />
          </p>
        ),
        header: () => 'PNL',
        meta: { width: 'w-[15%]' },
      }),
      // columnHelper.display({
      //   id: 'share',
      //   cell: ({ row }) => <Share position={row.original} />,
      //   header: '',
      //   meta: { width: 'w-[7.5%]' },
      // }),
      columnHelper.accessor('fundingPaid', {
        cell: (info) => (
          <p
            className={cn(
              Number(info.getValue()) < 0 ? 'text-red' : 'text-green',
            )}
          >
            {formatNumber(info.getValue(), { showChange: true, digits: 2 })}
          </p>
        ),
        header: () => 'FUNDING',
        meta: { width: 'w-[7.5%]' },
      }),
      columnHelper.display({
        id: 'close',
        cell: ({ row }) => (
          <div className="flex justify-end">
            <CloseDialog
              symbol={row.original.symbol}
              position={row.original.rawPosition}
              disabled={isSanctioned}
            />
          </div>
        ),
        header: () => '',
        meta: { width: 'w-[7.5%]' },
      }),
    ],
    [valueActive, isSanctioned],
  );

  return (
    // @ts-ignore
    <Table data={positions} columns={columns} RowComponent={PositionRow} />
  );
};
