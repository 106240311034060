import { Table } from '@/components/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useLpOrders } from '../api/get-lp-orders';
import { OrderStatus, LPOrderType } from '@/types/enums';
import {
  formatDate,
  formatNumber,
  formatTimeDelta,
} from '@/utils/value-format';

type LpScheduleWithdraw = {
  postTime: string;
  size: string;
  timeLeft: string;
};

const columnHelper = createColumnHelper<LpScheduleWithdraw>();

const EIGHT_HOURS = 60 * 60 * 8 * 1000;

export default function LPTable() {
  const { data: lpOrders } = useLpOrders({
    orderType: LPOrderType.SCHEDULE_WITHDRAW,
    status: OrderStatus.NEW,
  });

  const data = useMemo(() => {
    if (!lpOrders) return [];
    return lpOrders
      .sort((a, b) => a.postTime - b.postTime)
      .map((order) => {
        return {
          size: formatNumber(order.size, { maxDigits: 2 }),
          postTime: formatDate(new Date(order.postTime)),
          timeLeft: formatTimeDelta(
            new Date(new Date().getTime() - order.postTime + EIGHT_HOURS),
          ),
        };
      });
  }, [lpOrders]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('postTime', {
        cell: (info) => info.getValue(),
        header: () => 'TIME',
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('size', {
        cell: (info) => info.getValue(),
        header: () => 'REQUESTED WTTHDRAW AMOUNT',
        meta: { width: 'w-[12.5%]' },
      }),
      columnHelper.accessor('timeLeft', {
        cell: (info) => info.getValue(),
        header: () => 'TIME UNTIL WITHDRAWN',
        meta: { width: 'w-[12.5%]' },
      }),
    ],
    [],
  );
  //   @ts-ignore
  return <Table<Withdrawal> data={data} columns={columns} />;
}
