import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useEffect, useMemo, useState, type FC } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  COLLATERAL_SYMBOL,
  useMarketStore,
  useMarketStores,
} from '@/store/use-markets-store';
import { useStore } from 'zustand';
import {
  FormControl,
  FormItem,
  FormLabel,
} from '@/features/trade/order/components/order-form-components';
import {
  Button,
  Collapsible,
  CollapsibleContent,
  Input,
} from '@/components/ui';
import { OrderCheckbox } from '@/features/trade/order/components';
import {
  CreateOrderInput,
  createOrderInputSchema,
  CreateOrderResponse,
  useCreateOrder,
} from '@/features/trade/order/api/create-order';
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from '@/features/trade/order/components/order-tabs';
import { useParams } from 'react-router-dom';
import {
  BigDecimal,
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
  parseInputToDecimalStr,
} from '@/utils/value-format';
import {
  getEstFillPrice,
  getEstLiqPrice,
  getFee,
  getLimitPriceFromMaxSlippage,
  getMaxFillSize,
  getMaxLeverage,
  getOrderValue,
  getSlippage,
} from '../utils/math';
import { getAvailableFunds } from '@/features/account/utils/math';
import { useAccountStore } from '@/store/use-account-store';
import {
  FEE_DECIMALS,
  useExchangeInfo,
} from '@/features/markets/api/get-exchange-info';
import { useFetchAccount } from '@/features/account/api/get-account';
import { useOrdersStore } from '@/store/use-orders-store';
import { TpslInput } from './tpsl-input';
import { OrderStatus, OrderType } from '@/types/enums';
import { ScrollArea } from '@/components/ui/scroll-area';
import { AccountStatsContainer } from '@/features/account/components';
import { useShallow } from 'zustand/react/shallow';
import { debugResolver } from '@/utils/dev';
import { SizeInput, SliderType } from './size-input';
import { MaxSlippageInput } from './max-slippage-input';
import { PriceInput } from './price-input';
import { cn } from '@/lib/utils';
import useGeofenceCheck from '@/utils/geofence-check';
import { abbrFromSym } from '@/utils/token-symbol';
import { useWebSocketStore } from '@/store/use-websocket-store';

interface IOrderFormProps {}

const FormValuesSchema = z.object({
  order: createOrderInputSchema,
  tp: z.object({
    size: z.string().optional(),
    limitPrice: z.string(),
  }),
  sl: z.object({
    size: z.string().optional(),
    limitPrice: z.string(),
  }),
});

type FormValues = z.infer<typeof FormValuesSchema>;

export const OrderFormContainer: FC<IOrderFormProps> = () => {
  const { symbol } = useParams() as { symbol: string };

  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo();

  const { publicWs, isUpdated } = useWebSocketStore((state) => ({
    publicWs: state.publicWs,
    isUpdated: state.isUpdated,
  }));

  useEffect(() => {
    publicWs?.subscribeToChannels([`${symbol}@depth`]);
    return () => {
      publicWs?.unsubscribeFromChannels([`${symbol}@depth`]);
    };
  }, [symbol, publicWs, isUpdated]);

  if (isExchangeInfoPending) {
    return <div className="flex-1"></div>; // TODO: loading skeleton
  }

  if (isExchangeInfoError) {
    return <div>Error</div>; // TODO: error state
  }

  return <OrderForm symbol={symbol} />;
};

const OrderForm: FC<{
  symbol: string;
}> = ({ symbol }) => {
  const { data: isSanctioned } = useGeofenceCheck();
  const defaultValues = useMemo(() => {
    return {
      order: {
        symbol: symbol,
        isBuy: true,
        size: '',
        orderType: OrderType.MARKET,
        limitPrice: '',
        reduceOnly: false,
        initMarginRatio: '1.000',
      },
      tp: {
        size: '',
        limitPrice: '',
      },
      sl: {
        size: '',
        limitPrice: '',
      },
    };
  }, [symbol]);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValuesSchema),
    defaultValues,
  });
  const book = useStore(useMarketStore(symbol), (state) => state.book);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  useEffect(() => {
    form.setValue('order.symbol', symbol);
  }, [form, symbol]);

  const { account, hasAuth } = useAccountStore((state) => ({
    account: state.account,
    hasAuth: state.hasAuth,
  }));

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const { marketData, marketSpec } = markets[symbol];

  const newOrders = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]),
    ),
  );

  const { isPending: isFetchAccountPending } = useFetchAccount(); // might need to clear cache on disconnect

  const [tpslChecked, setTpslChecked] = useState(false);
  const [baseSize, setBaseSize] = useState<bigint>(0n);
  const [hasSizeInput, setHasSizeInput] = useState<boolean>(false);

  const onBaseSizeChange = (val: bigint) => {
    setBaseSize(val);
  };

  const onSizeInputChange = (val: string) => {
    setHasSizeInput(val.length > 0);
  };

  useEffect(() => {
    const sizeDecimalStr = bigIntToDecimalStr(
      baseSize,
      marketSpec.sizeDecimals,
    );
    form.setValue(`order.size`, sizeDecimalStr);
  }, [baseSize]);

  const [leverage, setLeverage] = useState(1);
  const onLeverageChange = (val: number) => {
    setLeverage(val);
  };

  const [sizeUnit, setSizeUnit] = useState(COLLATERAL_SYMBOL);

  useEffect(() => {
    const leverageBigInt = parseDecimalToBigInt(String(leverage), 2n);
    const initMarginRatio = 10n ** 6n / leverageBigInt; // rounds down
    form.setValue(
      `order.initMarginRatio`,
      bigIntToDecimalStr(initMarginRatio, 4n),
    );
  }, [leverage]);

  const isBuy = useWatch({
    control: form.control,
    name: 'order.isBuy',
  });

  const orderType = useWatch({
    control: form.control,
    name: 'order.orderType',
  });

  const initMarginRatio = useWatch({
    control: form.control,
    name: 'order.initMarginRatio',
  });

  const reduceOnly = useWatch({
    control: form.control,
    name: 'order.reduceOnly',
  });

  const orderSize = useWatch({
    control: form.control,
    name: 'order.size',
  });

  const limitPrice = useWatch({
    control: form.control,
    name: 'order.limitPrice',
  });

  useEffect(() => {
    if (tpslChecked) {
      form.setValue('tp.size', orderSize);
      form.setValue('sl.size', orderSize);
    }
  }, [orderSize, tpslChecked]);

  // depends on market
  const maxLeverage = getMaxLeverage(marketSpec)?.decimal;

  // depends on account
  const { availableFunds, estLiqPrice } = useMemo(() => {
    if (isFetchAccountPending)
      return { availableFunds: undefined, estLiqPrice: undefined };

    const availableFunds = getAvailableFunds(account, markets, newOrders);
    const estLiqPrice = getEstLiqPrice(
      symbol,
      isBuy,
      baseSize,
      account,
      markets,
      initMarginRatio ? parseDecimalToBigInt(initMarginRatio, 4n) : undefined,
      orderType === 'LIMIT'
        ? parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals)
        : undefined,
    );
    return { availableFunds, estLiqPrice };
  }, [baseSize, account, markets, newOrders, limitPrice]);

  const { maxFillSize, openPrice, slippage } = useMemo(() => {
    if (orderType !== OrderType.MARKET)
      return { openPrice: undefined, slippage: undefined };

    const maxFillSize = getMaxFillSize(isBuy, book, marketSpec);
    const estFillPrice = getEstFillPrice(isBuy, baseSize, book, marketSpec);
    const openPrice =
      estFillPrice === undefined || estFillPrice.bigint === 0n
        ? ({
            bigint: marketData.markPrice,
            decimal: bigIntToDecimalStr(
              marketData.markPrice,
              marketSpec.priceDecimals,
            ),
          } as BigDecimal)
        : estFillPrice;

    const slippage =
      estFillPrice === undefined
        ? undefined
        : getSlippage(isBuy, openPrice.bigint, book);

    return { maxFillSize, openPrice, slippage };
  }, [orderType, isBuy, baseSize, book, marketSpec]);

  const { orderValue, fee } = useMemo(() => {
    const price =
      orderType === OrderType.MARKET
        ? marketData.markPrice
        : parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals);

    const orderValue = getOrderValue(baseSize, price, marketSpec);
    const fee = getFee(baseSize, price, marketSpec);

    return { orderValue, fee };
  }, [orderType, baseSize, marketData.markPrice, limitPrice, marketSpec]);

  const tpPrice = useWatch({
    control: form.control,
    name: 'tp.limitPrice',
  });

  const slPrice = useWatch({
    control: form.control,
    name: 'sl.limitPrice',
  });

  const { buttonDisabled, buttonText } = useMemo(() => {
    if (isSanctioned) {
      return { buttonDisabled: true, buttonText: 'PLACE ORDER' };
    }

    if (!hasAuth) {
      return { buttonDisabled: true, buttonText: 'PLACE ORDER' };
    }

    if (baseSize === 0n) {
      return {
        buttonDisabled: true,
        buttonText: hasSizeInput ? 'INSUFFICIENT ORDER SIZE' : 'PLACE ORDER',
      };
    }

    const enoughMargin =
      availableFunds &&
      availableFunds.bigint > 0n &&
      orderValue !== undefined &&
      orderValue.bigint *
        (initMarginRatio
          ? parseDecimalToBigInt(initMarginRatio, 4n)
          : marketSpec.initMarginRatio) <=
        availableFunds.bigint * 10n ** 4n;

    if (!reduceOnly && !enoughMargin) {
      return { buttonDisabled: true, buttonText: 'NOT ENOUGH FUNDS' };
    }

    const parsedTpPrice = parseDecimalToBigInt(
      tpPrice,
      marketSpec.priceDecimals,
    );
    if (
      tpPrice !== '' &&
      ((isBuy && parsedTpPrice <= marketData.markPrice) ||
        (!isBuy && parsedTpPrice >= marketData.markPrice))
    ) {
      return { buttonDisabled: true, buttonText: 'INVALID TP PRICE' };
    }

    const parsedSlPrice = parseDecimalToBigInt(
      slPrice,
      marketSpec.priceDecimals,
    );
    if (
      slPrice !== '' &&
      ((isBuy && parsedSlPrice >= marketData.markPrice) ||
        (!isBuy && parsedSlPrice <= marketData.markPrice))
    ) {
      return { buttonDisabled: true, buttonText: 'INVALID SL PRICE' };
    }

    return { buttonDisabled: false, buttonText: 'PLACE ORDER' };
  }, [
    baseSize,
    hasSizeInput,
    hasAuth,
    orderValue,
    availableFunds,
    initMarginRatio,
    reduceOnly,
    tpPrice,
    slPrice,
    marketSpec.priceDecimals,
    marketData.markPrice,
    marketSpec.initMarginRatio,
  ]);

  const { mutateAsync: createOrder } = useCreateOrder();

  async function onSubmit(values: FormValues) {
    setIsSubmitSuccessful(false);
    const queue: (() => Promise<CreateOrderResponse>)[] = [];
    const order = values.order;

    // TODO update orders or something chain promises
    const validatedOrder = {
      ...order,
      limitPrice: parseInputToDecimalStr(limitPrice, marketSpec.priceDecimals),
    };
    queue.push(() =>
      createOrder({
        order: validatedOrder,
        showToast: true,
        waitForConfirm: true,
      }),
    );

    if (tpslChecked && values.tp.limitPrice !== '') {
      const tpOrder: CreateOrderInput = {
        symbol,
        orderType: OrderType.TAKE_PROFIT,
        isBuy: !order.isBuy,
        size: order.size, // FIXME: should really use values.tp.size, but this is undefined for some reason
        limitPrice: parseInputToDecimalStr(
          values.tp.limitPrice,
          marketSpec.priceDecimals,
        ),
        reduceOnly: true,
      };
      queue.push(() => createOrder({ order: tpOrder, showToast: false }));
    }

    if (tpslChecked && values.sl.limitPrice !== '') {
      const slOrder: CreateOrderInput = {
        symbol,
        orderType: OrderType.STOP_LOSS,
        isBuy: !order.isBuy,
        size: order.size, // FIXME: should really use values.sl.size, but this is undefined for some reason
        limitPrice: parseInputToDecimalStr(
          values.sl.limitPrice,
          marketSpec.priceDecimals,
        ),
        reduceOnly: true,
      };
      queue.push(() => createOrder({ order: slOrder, showToast: false }));
    }

    // Chain all requests to ensure nonce monotonicity and return array of promise results
    const results = await queue.reduce(
      (
        acc: Promise<CreateOrderResponse[]>,
        curr: () => Promise<CreateOrderResponse>,
      ) => acc.then((results) => curr().then((result) => [...results, result])),
      Promise.resolve([]),
    );

    if (!results.some((item) => item.status === OrderStatus.REJECTED)) {
      setIsSubmitSuccessful(true);
      form.reset({
        ...defaultValues,
        order: {
          ...defaultValues.order,
          symbol,
          isBuy: order.isBuy,
          orderType: order.orderType,
          initMarginRatio: '1.000',
        },
      });
      setHasSizeInput(false);
      setTpslChecked(false);
    }
  }

  return (
    <div className="flex h-full flex-col">
      <Tabs
        defaultValue="true"
        className="w-full"
        onValueChange={(value) => {
          const isBuyValue = value === 'true';
          form.setValue(`order.isBuy`, isBuyValue);
        }}
      >
        <TabsList className="border-b border-border">
          <TabsTrigger
            value="true"
            className="data-[state=active]:!bg-green/5 data-[state=active]:text-green"
          >
            LONG
          </TabsTrigger>
          <TabsTrigger
            value="false"
            className="border-l border-border data-[state=active]:bg-red/5 data-[state=active]:text-red"
          >
            SHORT
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <Tabs
        defaultValue={OrderType.MARKET}
        className="w-full"
        value={form.watch(`order.orderType`)}
        onValueChange={(value) => {
          form.setValue(`order.orderType`, value as OrderType);
        }}
      >
        <TabsList>
          <TabsTrigger
            value={OrderType.MARKET}
            className="overflow-hidden border-b border-border data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
          >
            MARKET
          </TabsTrigger>
          <TabsTrigger
            value={OrderType.LIMIT}
            className="overflow-hidden border-b border-border data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
          >
            LIMIT
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {/* Available Funds (non-input) */}
          <ScrollArea
            // className="h-[calc(100vh-227px)]"
            className={cn(
              isSanctioned ? 'h-[calc(100vh-237px)]' : 'h-[calc(100vh-192px)]',
            )}
          >
            <div className="flex flex-col gap-2 px-2.5 py-2">
              <div className="flex h-10 items-center justify-between px-3">
                <p className="text-vestgrey-200">Available Funds</p>
                <p className="font-mono text-gray-50">
                  {formatNumber(availableFunds?.decimal, { digits: 2 })}
                </p>
              </div>
              {orderType === OrderType.MARKET && (
                <div className="flex h-10 items-center justify-between px-3">
                  <p className="text-vestgrey-200">Price</p>
                  <p className="font-mono text-gray-50">
                    {formatNumber(openPrice?.decimal, {
                      digits: Number(marketSpec.priceDecimals),
                    })}
                  </p>
                </div>
              )}
              {orderType === OrderType.LIMIT && (
                <PriceInput
                  isSuccess={isSubmitSuccessful}
                  symbol={symbol}
                  onPriceChange={(limitPrice) =>
                    form.setValue('order.limitPrice', limitPrice)
                  }
                />
              )}

              {/* Size Input */}
              <SizeInput
                symbol={symbol}
                isSuccess={isSubmitSuccessful}
                price={
                  orderType === OrderType.MARKET
                    ? marketData.markPrice
                    : parseDecimalToBigInt(limitPrice, marketSpec.priceDecimals)
                }
                onSizeInputChange={onSizeInputChange}
                onBaseSizeChange={onBaseSizeChange}
                sliderType={SliderType.LEVERAGE}
                maxLeverage={maxLeverage ? Number(maxLeverage) : 0}
                onLeverageChange={onLeverageChange}
                maxNumeraireSize={availableFunds?.bigint}
                onSizeUnitChange={(unit) => setSizeUnit(unit)}
              />
              {/* Reduce | TP/SL */}
              {/* TODO: Handle Reset TP/SL params appropriately on uncheck */}
              <Collapsible open={tpslChecked}>
                <div className="flex h-10 items-center">
                  <FormItem className="justify-start gap-4">
                    <OrderCheckbox
                      id="reduce-only"
                      checked={reduceOnly}
                      onCheckedChange={(checked) =>
                        form.setValue(`order.reduceOnly`, checked === true)
                      }
                    />
                    <FormLabel htmlFor="reduce-only">Reduce Only</FormLabel>
                  </FormItem>
                  {orderType === OrderType.MARKET && (
                    <FormItem className="justify-start gap-4">
                      <OrderCheckbox
                        id="tpsl-checked"
                        onCheckedChange={(checked) =>
                          setTpslChecked(checked === true)
                        }
                        // disabled={account.positions[symbol]?.size > 0n}
                      />
                      <FormLabel htmlFor="tpsl-checked">TP/SL</FormLabel>
                    </FormItem>
                  )}
                </div>

                {/* TP/SL */}
                <div className="flex h-[160px] w-full flex-col justify-center">
                  <CollapsibleContent className="collapsible-content space-y-3 overflow-visible px-3">
                    <div className="space-y-3 border-b border-border pb-3">
                      <TpslInput
                        {...form.register('tp')}
                        orderType={OrderType.TAKE_PROFIT}
                        symbol={symbol}
                        parentOrderName="order"
                        showTitle={true}
                      />
                    </div>
                    {/* Stop Loss Price */}
                    <div className="space-y-3 border-b border-border pb-3">
                      <TpslInput
                        {...form.register('sl')}
                        orderType={OrderType.STOP_LOSS}
                        symbol={symbol}
                        parentOrderName="order"
                        showTitle={true}
                      />
                    </div>
                  </CollapsibleContent>
                </div>
              </Collapsible>
              {orderType === OrderType.MARKET ? (
                <FormItem className="flex h-10 items-center justify-between">
                  <p className="flex items-center gap-2">
                    Slippage
                    {slippage !== undefined &&
                      maxFillSize &&
                      baseSize > maxFillSize.bigint && (
                        <TooltipProvider>
                          <Tooltip delayDuration={0}>
                            <TooltipTrigger>
                              <Info size={14} />
                            </TooltipTrigger>
                            <TooltipContent>
                              Due to the large order size, the actual slippage
                              incurred may be much higher than this estimate.
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      )}
                  </p>
                  <div className="flex items-center gap-3">
                    <div className="flex items-end gap-2">
                      <span className="text-sm leading-tight text-vestgrey-600">
                        Est:
                      </span>
                      <span
                        className={cn(
                          'font-mono leading-tight text-white',
                          slippage &&
                            Number(Number(slippage.decimal).toFixed(2)) <= 0 // TEMP: ugly
                            ? 'text-green'
                            : null,
                        )}
                      >
                        {formatNumber(slippage?.decimal, {
                          digits: 2,
                          style: 'slippage',
                        })}
                      </span>
                      <span className="px-1 leading-none">/</span>
                      <span className="text-sm leading-tight text-vestgrey-600">
                        Max
                      </span>
                    </div>
                    <div className="flex items-center bg-vestgrey-800">
                      <MaxSlippageInput
                        symbol={symbol}
                        isBuy={isBuy}
                        isSubmitSuccessful={isSubmitSuccessful}
                        onLimitPriceChange={(limitPrice) => {
                          form.setValue(
                            `order.limitPrice`,
                            bigIntToDecimalStr(
                              limitPrice,
                              marketSpec.priceDecimals,
                            ),
                          );
                        }}
                      />
                    </div>
                  </div>
                </FormItem>
              ) : (
                <div className="h-10" />
              )}
              {/* Non-input Order Params */}
              <FormItem className="h-10">
                <p>Liquidation Price</p>
                <p className="font-mono text-vestgrey-50">
                  {formatNumber(estLiqPrice?.decimal, { digits: 2 })}
                </p>
              </FormItem>
              <FormItem>
                <p>Order Size</p>
                <p className="font-mono text-vestgrey-50">
                  {orderSize
                    ? `${orderSize} ${abbrFromSym(symbol)} (${formatNumber(orderValue?.decimal, { digits: 2 })})`
                    : '-'}
                </p>
              </FormItem>
              <FormItem className="h-10">
                <p>Fee</p>
                <p className="font-mono text-vestgrey-50">
                  {formatBigInt(marketSpec.takerFee, FEE_DECIMALS - 2n, {
                    style: 'percent',
                  })}{' '}
                  ({formatNumber(fee?.decimal, { digits: 2 })})
                </p>
              </FormItem>
            </div>
            <FormItem className="px-0 py-6">
              <Button
                type="submit"
                className="h-16 w-full rounded-none font-mono text-lg font-normal uppercase tracking-wider"
                size="lg"
                disabled={buttonDisabled}
              >
                {buttonText}
              </Button>
            </FormItem>
            <div className="px-2.5 pb-6">
              <AccountStatsContainer />
            </div>
          </ScrollArea>
        </form>
      </FormProvider>
    </div>
  );
};
