import {
  COLLATERAL_DECIMALS,
  CUM_FUNDING_DECIMALS,
  MarketData,
  MarketSpec,
} from '@/store/use-markets-store';
import { Account, Positions } from '@/store/use-account-store';
import {
  adjustDecimals,
  BigDecimal,
  bigIntToDecimalStr,
} from '@/utils/value-format';
import { Order } from '@/store/use-orders-store';

export interface MarketInfo {
  marketData: MarketData;
  marketSpec: MarketSpec;
}

export const getAccountHealthPct = (
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal | undefined => {
  // Returns 2 decimal places (%)
  if (account.collateral === 0n) return undefined;
  const accountValue = getAccountValue(account, markets).bigint;
  if (accountValue <= 0n)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, 2n) };
  const maintMarginReq = getAccountMaintMarginReq(account, markets).bigint;
  // const healthPct =
  //   ((accountValue - maintMarginReq) * 10n ** 4n) / accountValue;
  if (maintMarginReq === 0n)
    return { bigint: 10n ** 4n, decimal: bigIntToDecimalStr(10n ** 4n, 2n) };
  const healthPct = (accountValue * 10n ** 4n) / maintMarginReq;
  const boundHealthPct = healthPct > 10n ** 4n ? 10n ** 4n : healthPct;
  return {
    bigint: boundHealthPct,
    decimal: bigIntToDecimalStr(boundHealthPct, 2n),
  };
};

export const getAccountLeverage = (
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const accountValue = getAccountValue(account, markets).bigint;

  if (accountValue == 0n)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, 2n) };

  const notionalValue = getAccountNotionalValue(account, markets).bigint;
  // if (notionalValue <= accountValue) {
  //   return { bigint: 0n, decimal: bigIntToDecimalStr(0n, 2n) };
  // }
  const leverage = (notionalValue * 10n ** 4n) / accountValue / 10n ** 2n;
  return {
    bigint: leverage,
    decimal: bigIntToDecimalStr(leverage, 2n),
  };
};

export const getAccountNotionalValue = (
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const notionalValue = Object.entries(account.positions).reduce(
    (acc, [symbol, pos]) => {
      const marketSpec = markets[symbol].marketSpec;
      return (
        acc +
        adjustDecimals(
          pos.size * markets[symbol].marketData.markPrice,
          COLLATERAL_DECIMALS -
            marketSpec.sizeDecimals -
            marketSpec.priceDecimals,
        )
      );
    },
    BigInt(0),
  );
  return {
    bigint: notionalValue,
    decimal: bigIntToDecimalStr(notionalValue, COLLATERAL_DECIMALS),
  };
};

export const getAccountMaintMarginReq = (
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const maintMarginReq = Object.entries(account.positions).reduce(
    (acc, [symbol, pos]) => {
      const marketSpec = markets[symbol].marketSpec;
      return (
        acc +
        adjustDecimals(
          pos.size *
            markets[symbol].marketData.markPrice *
            marketSpec.maintMarginRatio,
          COLLATERAL_DECIMALS -
            marketSpec.sizeDecimals -
            marketSpec.priceDecimals -
            4n,
        )
      );
    },
    BigInt(0),
  );
  return {
    bigint: maintMarginReq,
    decimal: bigIntToDecimalStr(maintMarginReq, COLLATERAL_DECIMALS),
  };
};

export const getAccountValue = (
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const value = account.collateral + getAccountPnl(account, markets).bigint;
  return {
    bigint: value,
    decimal: bigIntToDecimalStr(value, COLLATERAL_DECIMALS),
  };
};

export const getAccountPnl = (
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const pnl = Object.keys(account.positions).reduce((acc, symbol) => {
    return (
      acc +
      getPositionPnl(symbol, account, markets).bigint +
      getPositionFunding(symbol, account, markets).bigint
    );
  }, BigInt(0));
  return {
    bigint: pnl,
    decimal: bigIntToDecimalStr(pnl, COLLATERAL_DECIMALS),
  };
};

export const getPositionPnl = (
  symbol: string,
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const pos = account.positions[symbol];
  if (!pos)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, COLLATERAL_DECIMALS) };

  const { marketData, marketSpec } = markets[symbol];
  const pnl =
    (pos.isLong ? 1n : -1n) *
    adjustDecimals(
      pos.size * marketData.markPrice - pos.cost,
      COLLATERAL_DECIMALS - marketSpec.sizeDecimals - marketSpec.priceDecimals,
    );

  return {
    bigint: pnl,
    decimal: bigIntToDecimalStr(pnl, COLLATERAL_DECIMALS),
  };
};

export const getPositionFunding = (
  symbol: string,
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const pos = account.positions[symbol];
  if (!pos || markets[symbol].marketData.cumFunding == 0n)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, COLLATERAL_DECIMALS) };
  const funding = adjustDecimals(
    pos.size * (markets[symbol].marketData.cumFunding - pos.entryFunding),
    COLLATERAL_DECIMALS -
      markets[symbol].marketSpec.sizeDecimals -
      CUM_FUNDING_DECIMALS,
  );
  const signedFunding = (pos.isLong ? -1n : 1n) * funding; // if long and funding is positive, then trader is paying
  return {
    bigint: signedFunding,
    decimal: bigIntToDecimalStr(signedFunding, COLLATERAL_DECIMALS),
  };
};

// cross margined
export const getPositionLiqPrice = (
  symbol: string,
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  // Returns priceDecimals
  const pos = account.positions[symbol];
  if (!pos || pos.size == 0n)
    return {
      bigint: 0n,
      decimal: bigIntToDecimalStr(0n, markets[symbol].marketSpec.priceDecimals),
    };

  const direction = pos.isLong ? 1n : -1n;

  const accValWithoutPos =
    getAccountValue(account, markets).bigint -
    getPositionPnl(symbol, account, markets).bigint;

  const maintMarginReqWithoutPos =
    getAccountMaintMarginReq(account, markets).bigint -
    adjustDecimals(
      markets[symbol].marketData.markPrice *
        pos.size *
        markets[symbol].marketSpec.maintMarginRatio,
      COLLATERAL_DECIMALS -
        markets[symbol].marketSpec.priceDecimals -
        markets[symbol].marketSpec.sizeDecimals -
        4n,
    );

  const numerator =
    adjustDecimals(
      maintMarginReqWithoutPos - accValWithoutPos,
      markets[symbol].marketSpec.sizeDecimals +
        markets[symbol].marketSpec.priceDecimals +
        4n -
        COLLATERAL_DECIMALS,
    ) + adjustDecimals(direction * pos.cost, 4n); // sizeDecimals + priceDecimals + 4

  const denominator =
    direction *
    pos.size *
    (10n ** 4n - markets[symbol].marketSpec.maintMarginRatio); // sizeDecimals + 4

  let liqPrice = numerator / denominator; // priceDecimals
  if (pos.isLong) {
    liqPrice =
      liqPrice > markets[symbol].marketData.markPrice
        ? markets[symbol].marketData.markPrice
        : liqPrice; // min
  } else {
    liqPrice =
      liqPrice < markets[symbol].marketData.markPrice
        ? markets[symbol].marketData.markPrice
        : liqPrice; // max
  }

  liqPrice = liqPrice < 0n ? 0n : liqPrice;
  return {
    bigint: liqPrice,
    decimal: bigIntToDecimalStr(
      liqPrice,
      markets[symbol].marketSpec.priceDecimals,
    ),
  };
};

export const getRealizedPnl = (
  symbol: string,
  isBuy: boolean,
  filledSize: bigint,
  filledPrice: bigint,
  settledFunding: bigint,
  fees: bigint,
  account: Account,
  marketSpec: MarketSpec,
): BigDecimal => {
  const pos = account.positions[symbol];
  if (!pos || pos.size === 0n || pos.isLong === isBuy)
    return {
      bigint: settledFunding - fees,
      decimal: bigIntToDecimalStr(settledFunding - fees, COLLATERAL_DECIMALS),
    };

  const cost =
    filledSize === pos.size ? pos.cost : (pos.cost * filledSize) / pos.size;
  const realizedPnl =
    (pos.isLong ? 1n : -1n) *
    adjustDecimals(
      filledSize * filledPrice - cost,
      COLLATERAL_DECIMALS - marketSpec.sizeDecimals - marketSpec.priceDecimals,
    );
  return {
    bigint: realizedPnl + settledFunding - fees,
    decimal: bigIntToDecimalStr(
      realizedPnl + settledFunding - fees,
      COLLATERAL_DECIMALS,
    ),
  };
};

export const getSettledFunding = (
  symbol: string,
  cumFunding: bigint,
  account: Account,
  marketSpec: MarketSpec,
): BigDecimal => {
  const pos = account.positions[symbol];
  if (!pos || pos.size === 0n)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, COLLATERAL_DECIMALS) };

  const settledFunding =
    (pos.isLong ? -1n : 1n) *
    adjustDecimals(
      pos.size * (cumFunding - pos.entryFunding),
      COLLATERAL_DECIMALS - marketSpec.sizeDecimals - CUM_FUNDING_DECIMALS,
      'ceil',
    );

  return {
    bigint: settledFunding,
    decimal: bigIntToDecimalStr(settledFunding, COLLATERAL_DECIMALS),
  };
};

export const getUpdatedAccountOnFill = (
  symbol: string,
  isBuy: boolean,
  filledSize: bigint,
  filledPrice: bigint,
  realizedPnl: bigint,
  orderInitMarginRatio: bigint,
  account: Account,
  marketData: MarketData,
): Account => {
  // Updates collateral and positions
  const pos = account.positions[symbol];
  if (!pos) {
    // no position
    return {
      collateral: account.collateral + realizedPnl,
      positions: {
        ...account.positions,
        [symbol]: {
          isLong: isBuy,
          size: filledSize,
          cost: filledPrice * filledSize,
          entryFunding: marketData.cumFunding,
          initMarginRatio: orderInitMarginRatio,
        },
      } as Positions,
    };
  } else {
    if (pos.isLong === isBuy) {
      // increase position
      return {
        collateral: account.collateral + realizedPnl,
        positions: {
          ...account.positions,
          [symbol]: {
            ...pos,
            size: pos.size + filledSize,
            cost: pos.cost + filledPrice * filledSize,
            entryFunding: marketData.cumFunding,
            initMarginRatio:
              (pos.initMarginRatio * pos.size +
                orderInitMarginRatio * filledSize) /
              (pos.size + filledSize),
          },
        } as Positions,
      };
    } else {
      if (pos.size == filledSize) {
        // close position
        const { [symbol]: closedPos, ...remainingPositions } =
          account.positions;
        return {
          collateral: account.collateral + realizedPnl,
          positions: remainingPositions,
        };
      } else if (pos.size >= filledSize) {
        // decrease position
        return {
          collateral: account.collateral + realizedPnl,
          positions: {
            ...account.positions,
            [symbol]: {
              ...pos,
              size: pos.size - filledSize,
              cost: pos.cost - (pos.cost * filledSize) / pos.size,
              entryFunding: marketData.cumFunding,
            },
          } as Positions,
        };
      } else {
        // cross position
        return {
          collateral: account.collateral + realizedPnl,
          positions: {
            ...account.positions,
            [symbol]: {
              isLong: !pos.isLong,
              size: filledSize - pos.size,
              cost: filledPrice * (filledSize - pos.size),
              entryFunding: marketData.cumFunding,
              initMarginRatio: orderInitMarginRatio,
            },
          } as Positions,
        };
      }
    }
  }
};

export const getAvailableFunds = (
  account: Account,
  markets: Record<string, MarketInfo>,
  openOrders: Order[],
): BigDecimal => {
  const totalPosInitMargin = Object.keys(account.positions).reduce(
    (acc, symbol) => {
      return acc + getPositionInitMargin(symbol, account, markets).bigint;
    },
    BigInt(0),
  );

  const totalOrderInitMargin = openOrders
    .filter((order) => order.orderType === 'LIMIT' && !order.reduceOnly)
    .reduce((acc, order) => {
      const { marketSpec } = markets[order.symbol];
      const { sizeDecimals, priceDecimals } = marketSpec;
      return (
        acc +
        adjustDecimals(
          order.size *
            order.limitPrice *
            (order.initMarginRatio ?? marketSpec.initMarginRatio),
          COLLATERAL_DECIMALS - sizeDecimals - priceDecimals - 4n,
        )
      );
    }, BigInt(0));

  let availableFunds =
    account.collateral - totalPosInitMargin - totalOrderInitMargin;
  if (availableFunds < 0n) {
    availableFunds = 0n;
  }
  return {
    bigint: availableFunds,
    decimal: bigIntToDecimalStr(availableFunds, COLLATERAL_DECIMALS),
  };
};

export const getPositionInitMargin = (
  symbol: string,
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const pos = account.positions[symbol];
  if (!pos)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, COLLATERAL_DECIMALS) };
  const marketSpec = markets[symbol].marketSpec;
  const initMargin = adjustDecimals(
    pos.size * markets[symbol].marketData.markPrice * pos.initMarginRatio,
    COLLATERAL_DECIMALS -
      marketSpec.sizeDecimals -
      marketSpec.priceDecimals -
      4n,
  );
  return {
    bigint: initMargin,
    decimal: bigIntToDecimalStr(initMargin, COLLATERAL_DECIMALS),
  };
};

export const getPositionRoePct = (
  symbol: string,
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  // Returns % with 2 decimal places
  const pos = account.positions[symbol];
  if (!pos) return { bigint: 0n, decimal: bigIntToDecimalStr(0n, 2n) };
  const posPnl = getPositionPnl(symbol, account, markets).bigint;
  const posInitMargin = getPositionInitMargin(symbol, account, markets).bigint;
  if (posInitMargin === 0n)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, 2n) };
  const posRoe = (posPnl * 10n ** 4n) / posInitMargin;
  return {
    bigint: posRoe,
    decimal: bigIntToDecimalStr(posRoe, 2n),
  };
};

export const getPositionValue = (
  symbol: string,
  account: Account,
  markets: Record<string, MarketInfo>,
): BigDecimal => {
  const pos = account.positions[symbol];
  if (!pos)
    return { bigint: 0n, decimal: bigIntToDecimalStr(0n, COLLATERAL_DECIMALS) };
  const { marketSpec, marketData } = markets[symbol];
  const value = adjustDecimals(
    pos.size * marketData.markPrice,
    COLLATERAL_DECIMALS - marketSpec.priceDecimals - marketSpec.sizeDecimals,
  );
  return {
    bigint: value,
    decimal: bigIntToDecimalStr(value, COLLATERAL_DECIMALS),
  };
};
