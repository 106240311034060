import axios from 'axios';

export const fetchers = [
  async () => {
    // TimeAPI.io
    const response = await axios.get(
      'https://timeapi.io/api/Time/current/zone?timeZone=UTC',
    );
    return new Date(response.data.dateTime);
  },
  async () => {
    // WorldTimeAPI
    const response = await axios.get(
      'http://worldtimeapi.org/api/timezone/Etc/UTC',
    );
    return new Date(response.data.datetime);
  },
  async () => {
    // Fallback: System time
    return new Date();
  },
];

export const fetchTime = async () => {
  for (const fetcher of fetchers) {
    try {
      const fetchedTime = await fetcher();

      return (
        fetchedTime.getTime() - fetchedTime.getTimezoneOffset() * 60 * 1_000
      );
    } catch (err) {
      console.error('Time fetch failed:', err);
    }
  }
  return 0;
};
