import animation from '@/assets/branding/404.gif';
import { cn } from '@/lib/utils';

export const Maintenance = () => {
  return (
    <div
      className={cn(
        'relative flex flex-col items-center justify-center',
        'h-[calc(100vh-60px)]',
      )}
    >
      <div className="relative z-10 flex flex-col items-center pb-16">
        <h1 className="text-[128px] font-bold leading-tight">Upgrading...</h1>
        <p className="text-lg">Check back soon.</p>
      </div>
      <img
        className="absolute bottom-0 top-0 h-full opacity-50"
        src={animation}
        alt="404 error"
      />
    </div>
  );
};
