import commonConfig from './common';
import devConfig from './development';
import prodConfig from './production';

const getConfig = () => {
  const isLocal = import.meta.env.DEV;
  const isDevelopment = import.meta.env.VITE_ENV === 'development';

  if (isLocal) {
    return { ...commonConfig, ...devConfig, isDevelopment };
  }

  // vercel deploy
  const branch = import.meta.env.VITE_VERCEL_GIT_COMMIT_REF;
  if (branch) {
    switch (branch) {
      // explicitly setting configurations for specific branches for future flexibility,
      // such as renaming branches or adding new ones
      case 'main':
      case 'staging':
        return { ...commonConfig, ...prodConfig, isDevelopment };
      case 'dev':
        return { ...commonConfig, ...devConfig, isDevelopment };
      default:
        return { ...commonConfig, ...prodConfig, isDevelopment };
    }
  }

  // other platform deploy
  const isProd = import.meta.env.PROD;
  if (isProd) {
    return { ...commonConfig, ...prodConfig, isDevelopment };
  }

  // default
  return { ...commonConfig, ...devConfig, isDevelopment };
};

const config = getConfig();

export default config;
