import * as Slider from '@radix-ui/react-slider';
import { BaseSyntheticEvent, useState } from 'react';
import { validatedDecimalStr } from '@/utils/value-format';
import { useEffect } from 'react';

interface CustomSliderProps {
  onChange: (val: number) => void;
  value: number;
  minValue: number;
  maxValue: number;
  unitSymbol: string;
  step?: number;
  buttonValues?: number[];
}

export const CustomSlider: React.FC<CustomSliderProps> = ({
  onChange,
  value,
  minValue,
  maxValue,
  buttonValues,
  step = 0.01,
  unitSymbol = 'x',
}) => {
  const handleSliderChange = (newValue: number[]) => {
    onChange(newValue[0]);
    setSliderInput(String(newValue[0]));
  };

  const [sliderInput, setSliderInput] = useState(String(value));
  const handleSliderInputChange = (e: BaseSyntheticEvent) => {
    if (e.target.value === '') {
      setSliderInput('');
      return;
    }

    const value = validatedDecimalStr(e.target.value, -Math.log10(step), 3);

    if (Number(value) < minValue || Number(value) > maxValue) {
      return;
    }

    onChange(Number(value));
  };

  useEffect(() => {
    setSliderInput(String(value));
  }, [value]);

  const handleButtonClick = (buttonValue: number) => {
    handleSliderChange([buttonValue]);
  };

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="grid grid-cols-[1fr_max-content] items-center gap-3">
        <Slider.Root
          className="relative flex h-10 w-full cursor-pointer touch-none select-none items-center"
          value={[value]}
          onValueChange={handleSliderChange}
          min={minValue}
          max={maxValue}
          step={step}
        >
          <Slider.Track className="relative mr-1 h-[3px] grow rounded-full bg-border">
            <Slider.Range className="absolute h-full rounded-full bg-primary" />
          </Slider.Track>
          <Slider.Thumb
            className="relative flex h-5 w-5 cursor-pointer items-center justify-center focus:outline-none"
            aria-label="Leverage"
          >
            <svg
              width="18"
              height="20"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.53674e-07 -6.11959e-07L2.54292e-07 16L14 8L9.53674e-07 -6.11959e-07Z"
                fill="#FF5A44"
              />
            </svg>
            <div className="absolute inset-0 h-full w-full bg-primary/60 blur-md"></div>
          </Slider.Thumb>
        </Slider.Root>
        <div className="flex h-10 items-center justify-center bg-vestgrey-800">
          <input
            type="text"
            value={sliderInput}
            onChange={handleSliderInputChange}
            className="h-full max-w-[5ch] bg-transparent pl-2 text-center font-mono text-base text-white focus:outline-none"
          />
          <p className="px-2 font-mono text-base text-white">{unitSymbol}</p>
        </div>
      </div>
      {buttonValues && buttonValues.length > 0 && (
        <div
          className={`grid h-10 w-full border border-border grid-cols-${buttonValues.length}`}
        >
          {buttonValues.map((buttonValue) => (
            <button
              key={buttonValue}
              type="button"
              className={`w-full border-r border-border bg-background font-mono text-foreground last:border-r-0`}
              onClick={() => handleButtonClick(buttonValue)}
            >
              {buttonValue}
              {unitSymbol}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
