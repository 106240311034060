import { queryOptions, useQuery } from '@tanstack/react-query';
import { privateApi } from '@/lib/api-clients/rest-client';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { ErrorCode, OrderStatus, LPOrderType } from '@/types/enums';
import { useAccountStore } from '@/store/use-account-store';

interface GetLpOrdersParams {
  id?: string;
  orderType?: LPOrderType;
  status?: OrderStatus;
  startTime?: number;
  endTime?: number;
  limit?: number;
}

export type LpOrder = {
  id: string;
  size: string;
  orderType: LPOrderType;
  status: OrderStatus;
  code?: ErrorCode;
  fees: string;
  postTime: number;
};

export const getLpOrders = async (
  params: GetLpOrdersParams,
): Promise<LpOrder[]> => {
  return privateApi.get('/lp', {
    params,
  });
};

export const getLpOrdersQueryOptions = (params: GetLpOrdersParams) => {
  return queryOptions({
    queryKey: ['lpOrders', params],
    queryFn: () => getLpOrders(params),
  });
};

type UseLpOrdersOptions = GetLpOrdersParams & {
  queryConfig?: QueryConfig<typeof getLpOrdersQueryOptions>;
};

export const useLpOrders = ({ queryConfig, ...params }: UseLpOrdersOptions) => {
  const { hasAuth } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
  }));
  return useQuery({
    ...getLpOrdersQueryOptions(params),
    ...queryConfig,
    enabled: hasAuth,
    refetchInterval: hasAuth ? false : 1_000,
  });
};
