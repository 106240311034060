import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import * as React from 'react';
import { WagmiProvider } from 'wagmi';

import { WebSocketProvider } from '@/context/ws-provider';
import { queryConfig } from '@/lib/api-clients/react-query';
import { wagmiConfig } from '@/lib/wagmi/config';
import { ApiProvider } from '@/context/api-provider';
import FavoriteMarketsProvider from '@/app/favorite-markets-provider';
// import { MainErrorFallback } from '@/components/errors/main';
// import { Notifications } from '@/components/ui/notifications';
// import { Spinner } from '@/components/ui/spinner';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: queryConfig,
      }),
  );

  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center"></div>
      }
    >
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <ApiProvider>
            <WebSocketProvider>
              {import.meta.env.TESTNET && <ReactQueryDevtools />}
              {/* <Notifications /> */}
              <RainbowKitProvider>
                <FavoriteMarketsProvider>{children}</FavoriteMarketsProvider>
              </RainbowKitProvider>
            </WebSocketProvider>
          </ApiProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </React.Suspense>
  );
};
