import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/lib/api-clients/react-query';
import {
  CUM_FUNDING_DECIMALS,
  FUNDING_RATE_DECIMALS,
  useMarketStore,
} from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { publicApi } from '@/lib/api-clients/rest-client';

export type Ticker = {
  symbol: string;
  markPrice: string;
  indexPrice: string;
  longOi: string;
  shortOi: string;
  oneHrFundingRate: string;
  cumFunding: string;
  status: string;
};

export type TickerResponse = {
  tickers: Ticker[];
};

export const getTickerLatest = (
  symbols?: string[],
): Promise<TickerResponse> => {
  return publicApi
    .get('/ticker/latest', {
      params: {
        symbols: symbols?.join(','),
      },
    })
    .then((res: TickerResponse) => {
      res.tickers.forEach((ticker: Ticker) => {
        const { setMarketData, marketData, marketSpec } = useMarketStore(
          ticker.symbol,
        ).getState();
        setMarketData({
          ...marketData,
          markPrice: parseDecimalToBigInt(
            ticker.markPrice,
            marketSpec.priceDecimals,
          ),
          indexPrice: parseDecimalToBigInt(
            ticker.indexPrice,
            marketSpec.priceDecimals,
          ),
          oneHrFundingRate: parseDecimalToBigInt(
            ticker.oneHrFundingRate,
            FUNDING_RATE_DECIMALS,
          ),
          cumFunding: parseDecimalToBigInt(
            ticker.cumFunding,
            CUM_FUNDING_DECIMALS,
          ),
        });
      });
      return res;
    });
};

export const getTickerLatestQueryOptions = ({
  symbols,
}: {
  symbols?: string[];
} = {}) => {
  return {
    queryKey: symbols ? ['tickerLatest', { symbols }] : ['tickerLatest'],
    queryFn: () => getTickerLatest(symbols),
  };
};

type UseTickerLatestOptions = {
  symbols?: string[];
  queryConfig?: QueryConfig<typeof getTickerLatestQueryOptions>;
};

export const useTickerLatest = ({
  queryConfig,
  symbols,
}: UseTickerLatestOptions = {}) => {
  return useQuery({
    ...getTickerLatestQueryOptions({ symbols }),
    ...queryConfig,
  });
};
