import {
  MarketsDropdown,
  MarketInfo,
  PositionsTableContainer,
} from '@/features/trade/components';
import TradingView from '@/features/trade/components/tv-chart';
import { OrderFormContainer } from '@/features/trade/order/components';
import {
  AccountStatsContainer,
  OrdersHistoryTableContainer,
} from '@/features/account/components';
import { useParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui';
import { useTickerLatest } from '@/features/markets/api/get-ticker-latest';
import { OpenOrdersTableContainer } from '@/features/account/components/open-orders-table';
import { TransfersTableContainer } from '@/features/account/components/transfers-table';
import { Bell, Bolt } from 'lucide-react';
import ConnectionStatus from '@/features/trade/order/components/connection-status';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@/lib/utils';
import useGeofenceCheck from '@/utils/geofence-check';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { OrderHistoryFilterType, TableTabType } from '@/types/enums';
import { useMarketStore } from '@/store/use-markets-store';
import { bigIntToDecimalStr } from '@/utils/value-format';
import { useStore } from 'zustand';
import UseReferralCodeDialog from '@/features/referrals/components/use-referral-code-dialog';

export default function TradePage() {
  const [tableTab, setTableTab] = useState<TableTabType>(
    TableTabType.POSITIONS,
  );
  const [orderHistoryFilter, setOrderHistoryFilter] =
    useState<OrderHistoryFilterType>(OrderHistoryFilterType.ALL);
  const { symbol } = useParams() as { symbol: string };
  const { data: isSanctioned } = useGeofenceCheck();
  const { marketData, marketSpec } = useStore(
    useMarketStore(symbol),
    (state) => state,
  );

  // const { isSuccess: isAccountSuccess } = useFetchAccount({});
  useTickerLatest(); // TEMP for MarketsDropdown

  useEffect(() => {
    document.title = `${bigIntToDecimalStr(
      marketData.markPrice,
      marketSpec.priceDecimals,
    )} | ${symbol} | Vest`;
  }, [marketData.markPrice, marketSpec.priceDecimals]);

  return (
    <div>
      <UseReferralCodeDialog />
      <div className="grid h-[56px] grid-cols-5 border-b border-border bg-background">
        <div className="col-span-4 grid grid-cols-[320px_1fr]">
          <MarketsDropdown />
          <MarketInfo />

          {/* <button className="border-x border-border px-8 font-mono text-sm uppercase tracking-wide text-primary">
            Market Details
          </button> */}
        </div>
        {/* <div className="grid grid-cols-4">
          <div className="col-span-2"></div>
          <button className="grid place-content-center border-l border-border">
            <div className="relative">
              <Bell className="text-[#BBBBBB]" size={18} />
              {true && (
                <div className="absolute -right-0.5 -top-0.5 flex h-3 w-3 items-center justify-center rounded-full bg-background">
                  <div className="h-1.5 w-1.5 rounded-full bg-primary"></div>
                </div>
              )}
            </div>
          </button>
          <button className="grid place-content-center border-l border-border">
            <Bolt className="text-[#BBBBBB]" size={18} />
          </button>
        </div> */}
      </div>
      <div
        className={cn(
          'flex',
          isSanctioned ? 'h-[calc(100vh-163px)]' : 'h-[calc(100vh-118px)]',
        )}
      >
        {/* Left Section */}
        <div className="grid w-4/5 grid-rows-[1fr_min-content]">
          <TradingView symbol={symbol || 'BTC-PERP'} />
          {/* Tables Section; TODO: div height */}
          <Tabs
            value={tableTab}
            onValueChange={(value) => setTableTab(value as TableTabType)}
            className="w-full"
          >
            {/* to make right side of tabs foreground div */}
            <div className="flex">
              <TabsList>
                <TabsTrigger value="positions">POSITIONS</TabsTrigger>
                <TabsTrigger value="open-orders">OPEN ORDERS</TabsTrigger>
                <TabsTrigger value="order-history">ORDER HISTORY</TabsTrigger>
                <TabsTrigger value="transfers">TRANSFERS</TabsTrigger>
              </TabsList>
              {/* to make right side of tabs foreground div */}
              <div className="flex w-full items-center justify-end gap-4 border-y border-border bg-background font-mono">
                {tableTab === TableTabType.ORDER_HISTORY && (
                  <Select
                    onValueChange={(value) =>
                      setOrderHistoryFilter(value as OrderHistoryFilterType)
                    }
                  >
                    <SelectTrigger
                      id="filter"
                      className="h-full max-w-32 rounded-none border-y-0 border-r-0"
                    >
                      <SelectValue placeholder="Filter" />
                    </SelectTrigger>
                    <SelectContent className="font-mono">
                      <SelectItem value={OrderHistoryFilterType.ALL}>
                        All
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.FILLED}>
                        Filled
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.REJECTED}>
                        Rejected
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.CANCELLED}>
                        Cancelled
                      </SelectItem>
                    </SelectContent>
                  </Select>
                )}
              </div>
            </div>
            {/* TODO: table height fixed for now to make it 600 like div */}
            <TabsContent value="positions">
              <div className="h-[228px]">
                <PositionsTableContainer />
              </div>
            </TabsContent>
            <TabsContent value="open-orders">
              <div className="h-[228px]">
                <OpenOrdersTableContainer />
              </div>
            </TabsContent>
            <TabsContent value="order-history">
              <div className="h-[228px]">
                <OrdersHistoryTableContainer filter={orderHistoryFilter} />
              </div>
            </TabsContent>
            <TabsContent value="transfers">
              <div className="h-[228px]">
                <TransfersTableContainer />
              </div>
            </TabsContent>
          </Tabs>
        </div>

        {/* Right Section */}
        <div className="flex w-1/5 flex-col border-l border-border bg-background">
          <OrderFormContainer />
          {/* <ConnectionStatus /> */}
        </div>
      </div>
    </div>
  );
}
