import {
  Button,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui';
import { cn } from '@/lib/utils';
import { formatNumber } from '@/utils/value-format';
import { Points } from '../api/get-points';
import { useFetchAccount } from '@/features/account/api/get-account';
import {
  getAccountValue,
  getAccountMaintMarginReq,
  getAccountHealthPct,
  getAccountLeverage,
  getAccountPnl,
} from '@/features/account/utils/math';
import { useAccountStore } from '@/store/use-account-store';
import {
  useMarketStores,
  getAccountMarketStores,
} from '@/store/use-markets-store';
import { useEffect, useMemo, useState } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Info } from 'lucide-react';
import { readContract, writeContract, switchChain } from '@wagmi/core';
import useReferrals from '@/features/referrals/api/get-referrals';
import { wagmiConfig } from '@/lib/wagmi/config';
import { parseAbi } from 'viem';
import { toast } from 'sonner';
import { useAccount, useSwitchChain } from 'wagmi';
import { zksyncSepoliaTestnet, zksync } from 'viem/zksync';

const abi = parseAbi([
  'function token() external returns(address)',
  'function isClaimed(uint256 index) external view returns (bool)',
  'function claim(uint256 index, address account, uint256 amount, bytes32[] calldata merkleProof) external',
]);

export default function PortfolioStats({ points }: { points?: Points }) {
  const [isClaimed, setIsClaimed] = useState(false);
  // const { switchChain } = useSwitchChain();
  const [isClaimedLoading, setIsClaimedLoading] = useState(true);
  const {
    data: referrals,
    isLoading: areReferralsLoading,
    isSuccess: haveReferralsSucceeded,
  } = useReferrals();
  const { account, hasAuth } = useAccountStore((state) => ({
    account: state.account,
    hasAuth: state.hasAuth,
  }));
  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));
  const { chainId } = useAccount();
  const accountMarkets = getAccountMarketStores(account, markets);

  const { isPending: isAccountPending, isError: isAccountError } =
    useFetchAccount();

  const accountStats = useMemo(() => {
    if (!hasAuth || isAccountPending) {
      return {
        accountValue: undefined,
        maintMarginReq: undefined,
        healthPct: undefined,
        leverage: undefined,
        unrealizedPnl: undefined,
      };
    }
    return {
      accountValue: getAccountValue(account, markets).decimal,
      maintMarginReq: getAccountMaintMarginReq(account, markets).decimal,
      healthPct: getAccountHealthPct(account, markets)?.decimal,
      leverage: getAccountLeverage(account, markets).decimal,
      unrealizedPnl: getAccountPnl(account, markets).decimal,
    };
  }, [account, accountMarkets, hasAuth, isAccountPending, isAccountError]);

  async function onClaim() {
    if (
      areReferralsLoading ||
      !haveReferralsSucceeded ||
      !referrals.proof ||
      !chainId
    )
      return;
    const loadingId = toast.loading('Claiming your rewards...');
    const { index, account, amount, proof } = referrals.proof;
    if (import.meta.env.VITE_ENV === 'development') {
      if (chainId !== zksyncSepoliaTestnet.id) {
        await switchChain(wagmiConfig, { chainId: zksyncSepoliaTestnet.id });
      }
    } else if (chainId !== zksync.id) {
      await switchChain(wagmiConfig, { chainId: zksync.id });
    }
    try {
      const txHash = await writeContract(wagmiConfig, {
        abi,
        address: import.meta.env.VITE_REWARDS_ZKSYNC,
        functionName: 'claim',
        chainId:
          import.meta.env.VITE_ENV === 'development'
            ? zksyncSepoliaTestnet.id
            : zksync.id,
        // @ts-ignore
        args: [index, account, amount, proof],
      });
      setIsClaimed(true);
      toast.dismiss(loadingId);

      const blockExplorerUrl =
        import.meta.env.VITE_ENV === 'development'
          ? zksyncSepoliaTestnet.blockExplorers.native.url
          : zksync.blockExplorers.native.url;
      toast.success(
        <div>
          Rewards claimed! Transaction:{' '}
          <a
            href={`${blockExplorerUrl}tx/${txHash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            {txHash.slice(0, 6)}...{txHash.slice(-4)}
          </a>
        </div>,
      );
    } catch (err) {
      toast.dismiss(loadingId);
      toast.error(`An error occurred while claiming your rewards.`);
      console.error("Couldn't claim: ", err);
    }
  }

  useEffect(() => {
    if (!haveReferralsSucceeded || !referrals.proof) return;
    setIsClaimedLoading(true);
    (async () => {
      const { index } = referrals.proof;
      try {
        const claimed = await readContract(wagmiConfig, {
          abi,
          address: import.meta.env.VITE_REWARDS_ZKSYNC,
          functionName: 'isClaimed',
          chainId:
            import.meta.env.VITE_ENV === 'development'
              ? zksyncSepoliaTestnet.id
              : zksync.id,
          // @ts-ignore
          args: [index],
        });
        setIsClaimed(claimed);
      } catch (err) {
        console.error("Couldn't read isClaimed: ", err);
        setIsClaimed(true);
      } finally {
        setIsClaimedLoading(false);
      }
    })();
  }, [haveReferralsSucceeded]);

  return (
    <Tabs defaultValue="assets">
      <TabsList className="grid w-full grid-cols-1 border-r border-border">
        <TabsTrigger value="assets" className="h-16 border-t-0">
          OVERVIEW
        </TabsTrigger>
      </TabsList>
      <TabsContent value="assets">
        <div className="grid grid-cols-2 border-r border-border">
          <div className="col-span-2 border-b border-border p-8">
            <div className="flex flex-col gap-2">
              <div className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                <h4 className="flex items-center gap-2">
                  Rewards{' '}
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <Info size={12} />
                      </TooltipTrigger>
                      <TooltipContent>
                        REWARDS ARE DISTRIBUTED EVERY MONDAY 12 PM EST.
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </h4>
              </div>
              <div className="flex items-center justify-between gap-4">
                <p
                  className={cn(
                    'text-3xl font-bold',
                    isClaimedLoading || isClaimed || !referrals?.proof?.amount
                      ? 'text-foreground'
                      : 'text-foreground/75',
                  )}
                >
                  {isClaimedLoading || isClaimed || !referrals?.proof?.amount
                    ? '-'
                    : Number(Number(referrals.proof.amount) / 10 ** 18).toFixed(
                        0,
                      ) + ' ZK'}
                </p>
                {!areReferralsLoading &&
                  haveReferralsSucceeded &&
                  referrals.proof && (
                    <Button
                      onClick={onClaim}
                      disabled={
                        isClaimedLoading ||
                        isClaimed ||
                        !referrals?.proof?.amount
                      }
                      className="rounded-none font-mono uppercase tracking-wider"
                    >
                      Claim
                    </Button>
                  )}
              </div>
            </div>
          </div>
          <div className="border-b border-r border-border px-8 py-6">
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Account Value
              </div>
              <div
                className={cn(
                  'text-font flex flex-1 items-center justify-start text-xl font-bold',
                )}
              >
                {formatNumber(accountStats?.accountValue, { digits: 2 })}
              </div>
            </div>
          </div>
          <div className="border-b border-border px-8 py-6">
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start gap-2 font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Maintenance Margin
              </div>
              <div className="text-font flex flex-1 items-center justify-start text-xl font-bold">
                {formatNumber(accountStats?.maintMarginReq, { digits: 2 })}
              </div>
            </div>
          </div>
          <div className="border-r border-border px-8 py-6">
            <div className="flex flex-col gap-2">
              <h4 className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Leverage
              </h4>
              <div className="text-font flex flex-1 items-center justify-start text-xl font-bold">
                {formatNumber(accountStats?.leverage, { style: 'leverage' })}
              </div>
            </div>
          </div>
          <div className="px-8 py-6">
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Unrealized PNL
              </div>
              <div
                className={cn(
                  'flex flex-1 items-center justify-start text-xl font-bold',
                  accountStats?.unrealizedPnl === undefined
                    ? 'text-vestgrey-20'
                    : Number(accountStats.unrealizedPnl) < 0
                      ? 'text-red'
                      : 'text-green',
                )}
              >
                {formatNumber(accountStats?.unrealizedPnl, {
                  digits: 2,
                  showChange: true,
                })}
              </div>
            </div>
          </div>
        </div>
      </TabsContent>
    </Tabs>
  );
}
