import { writeContract } from '@wagmi/core';
import { wagmiConfig } from '@/lib/wagmi/config';
import USDC_ABI from '@/lib/abis/mock-usdc-abi';
import { FilledTransfer, useTransfersStore } from '@/store/use-transfers-store';
import { ErrorCode, ErrorMsgs, OrderStatus } from '@/types/enums';
import { getTransfers } from '@/features/account/api/get-transfers';
import { toast } from 'sonner';
import { formatBigInt, formatNumber } from '@/utils/value-format';
import { ContractFunctionExecutionError } from 'viem';
import { z } from 'zod';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { getBridgeAddress, getUSDCAddress } from '@/utils/addresses';

export const createDepositInputSchema = z.object({
  size: z.bigint(),
  chainId: z.number(),
});

export type CreateDepositInput = z.infer<typeof createDepositInputSchema>;

export async function createDeposit({ size, chainId }: CreateDepositInput) {
  const time = Date.now();

  const promise = writeContract(wagmiConfig, {
    abi: USDC_ABI,
    address: getUSDCAddress(chainId),
    functionName: 'transfer',
    args: [getBridgeAddress(chainId), size],
  });

  promise.then((data) => {
    const toastPromise = new Promise<FilledTransfer>((resolve, reject) => {
      let pollIntervalId: NodeJS.Timeout;
      let subscriberTimeoutId: NodeJS.Timeout;
      let unsubscribe: () => void;

      const cleanup = () => {
        clearTimeout(subscriberTimeoutId);
        clearInterval(pollIntervalId);
        unsubscribe();
      };

      unsubscribe = useTransfersStore.subscribe((state) => {
        if (state.newDeposits.length > 0) {
          for (const deposit of state.newDeposits) {
            if (
              deposit.postTime >= time &&
              ('size' in deposit ? deposit.size === size : true)
            ) {
              state.removeNewDeposit(deposit.id);
              if (deposit.status === OrderStatus.FILLED) {
                cleanup();
                resolve(deposit);
              } else if (deposit.status === OrderStatus.REJECTED) {
                cleanup();
                reject(deposit.code as ErrorCode);
              }
            }
          }
        }
      });

      subscriberTimeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 30_000); // 30 seconds timeout

      pollIntervalId = setInterval(() => {
        getTransfers({ startTime: time });
      }, 2_000);
    });

    toast.promise(toastPromise, {
      loading: 'Depositing...',
      success: (data: FilledTransfer) => {
        return `Deposited ${formatBigInt(data.size, COLLATERAL_DECIMALS, { digits: 2 })} USDC`;
      },
      error: (error) => {
        return `Deposit failed: ${ErrorMsgs[error as ErrorCode]}`;
      },
    });
  });

  // Catch initial txn errors
  promise.catch((error) => {
    if (error instanceof ContractFunctionExecutionError) {
      toast.error(error.cause.shortMessage);
    } else {
      console.warn('Unhandled error');
      console.error(error);
    }
  });

  return promise;
}
