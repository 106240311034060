import {
  getPositionLiqPrice,
  getUpdatedAccountOnFill,
  MarketInfo,
} from '@/features/account/utils/math';
import { Account, Positions } from '@/store/use-account-store';
import {
  COLLATERAL_DECIMALS,
  MarketData,
  MarketSpec,
  OrderBook,
} from '@/store/use-markets-store';
import {
  adjustDecimals,
  BigDecimal,
  bigIntToDecimalStr,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { CreateOrderInput } from '../api/create-order';

export const getMaxFillSize = (
  isBuy: boolean,
  book: OrderBook,
  marketSpec: MarketSpec,
): BigDecimal => {
  const levels = isBuy ? book.asks : book.bids;
  const maxFillSize = levels.reduce((acc, [price, qty]) => acc + qty, 0n);
  return {
    bigint: maxFillSize,
    decimal: bigIntToDecimalStr(maxFillSize, marketSpec.sizeDecimals),
  };
};

export const getEstFillPrice = (
  isBuy: boolean,
  size: bigint,
  book: OrderBook,
  marketSpec: MarketSpec,
): BigDecimal | undefined => {
  if (size === 0n) return;
  const levels = isBuy ? book.asks : book.bids;
  const { totalQty, totalCost } = levels.reduce(
    (acc, [price, qty]) => {
      const consumedQty = qty > size - acc.totalQty ? size - acc.totalQty : qty;
      return {
        totalQty: acc.totalQty + consumedQty, // sizeDecimals
        totalCost: acc.totalCost + price * consumedQty, // priceDecimals + sizeDecimals
      };
    },
    { totalQty: BigInt(0), totalCost: BigInt(0) },
  );
  if (totalQty < size) {
    console.warn(
      'Not enough liquidity to fill order. Total book liquidity:',
      levels.reduce((acc, [price, qty]) => acc + qty, 0n),
    );
  }
  if (totalQty === 0n) {
    // console.error('totalQty is 0n');
    return undefined;
  }
  return {
    bigint: totalCost / totalQty,
    decimal: bigIntToDecimalStr(totalCost / totalQty, marketSpec.priceDecimals),
  };
};

export const getFee = (
  size: bigint,
  price: bigint,
  marketSpec: MarketSpec,
): BigDecimal | undefined => {
  if (size === 0n) return;
  const fee = adjustDecimals(
    price * marketSpec.takerFee * size,
    COLLATERAL_DECIMALS -
      marketSpec.priceDecimals -
      marketSpec.sizeDecimals -
      4n,
  );
  return {
    bigint: fee,
    decimal: bigIntToDecimalStr(fee, COLLATERAL_DECIMALS),
  };
};

export const getSlippage = (
  isBuy: boolean,
  openPrice: bigint,
  book: OrderBook,
): BigDecimal => {
  const markPrice = (book.asks[0][0] + book.bids[0][0]) / 2n;
  const slippage =
    ((isBuy ? 1n : -1n) * (openPrice - markPrice) * 10n ** 6n) / markPrice;
  return {
    bigint: slippage,
    decimal: bigIntToDecimalStr(slippage, 4n),
  };
};

export const getOrderValue = (
  size: bigint,
  price: bigint,
  marketSpec: MarketSpec,
): BigDecimal | undefined => {
  if (size === 0n) return;
  const orderValue = size * price;
  return {
    bigint: orderValue,
    decimal: bigIntToDecimalStr(
      orderValue,
      marketSpec.sizeDecimals + marketSpec.priceDecimals,
    ),
  };
};

export const getOrderValueFromPrice = (
  size: bigint,
  price: bigint,
  marketSpec: MarketSpec,
): BigDecimal | undefined => {
  if (size === 0n) return;
  const orderValue = size * price;
  return {
    bigint: orderValue,
    decimal: bigIntToDecimalStr(
      orderValue,
      marketSpec.sizeDecimals + marketSpec.priceDecimals,
    ),
  };
};

export const getLimitPriceFromMaxSlippage = (
  isBuy: boolean,
  maxSlippagePct: bigint, // pcts should always be 4 decimal precision
  marketData: MarketData,
  marketSpec: MarketSpec,
): BigDecimal => {
  const limitPrice =
    ((10n ** 4n + (isBuy ? maxSlippagePct : -maxSlippagePct)) *
      marketData.markPrice) /
    10n ** 4n;
  return {
    bigint: limitPrice,
    decimal: bigIntToDecimalStr(limitPrice, marketSpec.priceDecimals),
  };
};

export const getMaxLeverage = (
  marketSpec: MarketSpec,
): BigDecimal | undefined => {
  if (marketSpec.initMarginRatio === 0n) return undefined;
  const maxLeverage = 10n ** 6n / marketSpec.initMarginRatio; // want 2 decimal precision
  return {
    bigint: maxLeverage,
    decimal: bigIntToDecimalStr(maxLeverage, 2n),
  };
};

// returns sizeDecimals
export const getSizeFromNumeraire = (
  numeraireSize: bigint, // expects in collateral decimals
  price: bigint,
  marketSpec: MarketSpec,
  roundUp: boolean = true,
): bigint => {
  if (numeraireSize === 0n) return 0n;
  const { sizeDecimals, priceDecimals } = marketSpec;
  const decimalsPadding = COLLATERAL_DECIMALS + 1n;
  const bigSize = adjustDecimals(
    (numeraireSize * 10n ** decimalsPadding) / price,
    sizeDecimals - (COLLATERAL_DECIMALS - priceDecimals),
  ); // sizeDecimals + decimalsPadding
  const size = adjustDecimals(bigSize, -decimalsPadding);
  if (roundUp) {
    return bigSize > 0n && size == 0n ? 1n : size; // round up
  } else {
    return size;
  }
};

// TODO: limit prices
export const getEstLiqPrice = (
  symbol: string,
  isBuy: boolean,
  size: bigint,
  account: Account,
  markets: Record<string, MarketInfo>,
  orderInitMarginRatio?: bigint,
  price?: bigint,
): BigDecimal | undefined => {
  if (size === 0n) return;
  const updatedAccount = getUpdatedAccountOnFill(
    symbol,
    isBuy,
    size,
    price ?? markets[symbol].marketData.markPrice,
    0n,
    orderInitMarginRatio ?? markets[symbol].marketSpec.initMarginRatio,
    account,
    markets[symbol].marketData,
  );
  return getPositionLiqPrice(symbol, updatedAccount, markets);
};

// Assumes always reducing position
export const getPriceFromPnl = (
  symbol: string,
  pnl: bigint, // in collateral decimals
  size: bigint,
  account: Account,
  marketSpec: MarketSpec,
): BigDecimal => {
  const pos = account.positions[symbol];
  const cost = size === pos.size ? pos.cost : (pos.cost * size) / pos.size;
  const signedCost = (pos.isLong ? 1n : -1n) * cost;
  const signedSize = (pos.isLong ? 1n : -1n) * size;
  const price =
    (adjustDecimals(
      pnl,
      marketSpec.sizeDecimals + marketSpec.priceDecimals - COLLATERAL_DECIMALS,
    ) +
      signedCost) /
    signedSize;
  return {
    bigint: price,
    decimal: bigIntToDecimalStr(price, marketSpec.priceDecimals),
  };
};
