import { Table } from '@/components/ui';
import { formatDate, formatNumber } from '@/utils/value-format';
import { ColumnDef } from '@tanstack/react-table';
import useReferrals, { Trader } from '../api/get-referrals';

const columns: ColumnDef<Trader>[] = [
  {
    accessorKey: 'trader',
    cell: ({ row }) => (
      <p className="max-w-full overflow-hidden text-ellipsis">
        {row.original.trader}
      </p>
    ),
    header: 'TRADER',
    meta: { width: 'w-[25%]' },
  },
  {
    accessorKey: 'date_joined',
    cell: ({ row }) =>
      row.original.date_joined
        ? formatDate(new Date(row.original.date_joined), { showTime: false })
        : '-',
    header: 'DATE JOINED',
  },
  {
    accessorKey: 'volume_traded',
    cell: ({ row }) => formatNumber(row.original.volume_traded),
    header: 'VOLUME TRADED',
  },
  {
    accessorKey: 'fees_paid',
    cell: ({ row }) => formatNumber(row.original.fees_paid),
    header: 'FEES PAID',
  },
  {
    accessorKey: 'rewards_earned',
    cell: ({ row }) => formatNumber(row.original.rewards_earned),
    header: 'REWARDS EARNED',
  },
];

export default function ReferralTradersTable() {
  const { data: referrals } = useReferrals();
  return <Table data={referrals?.traders || []} columns={columns} />;
}
