import * as React from 'react';
import { cn } from '@/utils/cn';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: string) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type = 'text', onChange, ...props }, ref) => {
    const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
      let value = event.currentTarget.value;
      if (onChange) {
        onChange(value);
      }
    };

    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-1/2 bg-vestgrey-800 px-3 py-2 font-mono text-foreground placeholder:text-muted-foreground focus-visible:outline-none',
          className,
        )}
        ref={ref}
        onInput={handleInput}
        autoComplete="off"
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };
