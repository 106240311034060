import {
  Button,
  Input,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui';
import { LPOrderType } from '@/types/enums';
import LPForm from './lp-form';

export default function LPFormTabs() {
  return (
    <div className="flex h-full flex-col">
      <Tabs defaultValue={LPOrderType.DEPOSIT} className="w-full">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger
            value={LPOrderType.DEPOSIT}
            className="overflow-hidden border-b border-t-0 border-border uppercase data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
          >
            Add
          </TabsTrigger>
          <TabsTrigger
            value={LPOrderType.SCHEDULE_WITHDRAW}
            className="overflow-hidden border-b border-t-0 border-border uppercase data-[state=active]:border-primary data-[state=active]:text-primary data-[state=active]:[text-shadow:_0_0_30px_rgb(255,90,68)]"
          >
            Remove
          </TabsTrigger>
        </TabsList>
        <TabsContent value={LPOrderType.DEPOSIT}>
          <LPForm orderType={LPOrderType.DEPOSIT} />
        </TabsContent>
        <TabsContent value={LPOrderType.SCHEDULE_WITHDRAW}>
          <LPForm orderType={LPOrderType.SCHEDULE_WITHDRAW} />
        </TabsContent>
      </Tabs>
    </div>
  );
}
