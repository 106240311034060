import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction, useRef } from 'react';

type Props = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const CODE_LENGTH = 5;

export default function ReferralCode({
  value: code,
  setValue: setCode,
}: Props) {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  return (
    <div
      style={{ gridTemplateColumns: `repeat(${CODE_LENGTH}, 1fr)` }}
      className={cn(
        'grid max-w-xs flex-1 border',
        code.length === CODE_LENGTH ? 'border-primary' : 'border-foreground/75',
      )}
    >
      {Array.from(Array(CODE_LENGTH)).map((_, i) => (
        <label
          className={cn(
            'grid h-10 cursor-pointer place-content-center border-r last:border-r-0',
            code.length === CODE_LENGTH
              ? 'border-primary'
              : 'border-foreground/75',
          )}
          htmlFor={`referral-char-${i}`}
          key={`referral-char-label-${i}`}
        >
          <input
            size={1}
            maxLength={1}
            ref={(ref) => (inputRefs.current[i] = ref)}
            autoFocus={i === 0}
            className={cn(
              'min-w-0 bg-transparent font-mono text-lg focus:outline-none',
              code.length === CODE_LENGTH ? 'text-primary' : 'text-foreground',
            )}
            value={i < code.length ? code[i].toUpperCase() : ''}
            readOnly={i !== code.length - 1 && i !== code.length}
            onKeyDown={(e) => {
              if (e.key === 'Backspace' && code.length === i) {
                inputRefs.current[i - 1]?.focus();
              }
              if (e.key !== 'Backspace' && code.length === i + 1) {
                console.log(code.length, i);
                inputRefs.current[i + 1]?.focus();
              }
            }}
            onChange={(e) => {
              inputRefs.current[e.target.value ? i + 1 : i - 1]?.focus();
              setCode((prev) => {
                if (!prev[i]) return prev + e.target.value;
                const arr = prev.split('');
                arr[i] = e.target.value;
                return arr.join('');
              });
            }}
            id={`referral-char-${i}`}
            key={`referral-char-${i}`}
          />
        </label>
      ))}
    </div>
  );
}
