import { FormItem } from '@/features/trade/order/components/order-form-components';
import { useAccountStore } from '@/store/use-account-store';
import { useFetchAccount } from '../api/get-account';
import { formatNumber } from '@/utils/value-format';
import {
  getAccountMarketStores,
  useMarketStores,
} from '@/store/use-markets-store';
import {
  getAccountHealthPct,
  getAccountLeverage,
  getAccountMaintMarginReq,
  getAccountPnl,
  getAccountValue,
} from '../utils/math';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useMemo } from 'react';
import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import HealthBar from './health-bar';
import { cn } from '@/utils/cn';

interface IAccountStatsProps {}

export const AccountStatsContainer = () => {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo();

  if (isExchangeInfoPending) {
    return <div className="h-[133.5px]"></div>;
  }

  if (isExchangeInfoError) {
    return <div>Error</div>;
  }

  return <AccountStats />;
};

const AccountStats: React.FC<IAccountStatsProps> = () => {
  const { account, hasAuth } = useAccountStore((state) => ({
    account: state.account,
    hasAuth: state.hasAuth,
  }));
  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));
  const accountMarkets = getAccountMarketStores(account, markets);

  const { isPending: isAccountPending, isError: isAccountError } =
    useFetchAccount();

  const accountStats = useMemo(() => {
    if (!hasAuth || isAccountPending) {
      return {
        accountValue: undefined,
        maintMarginReq: undefined,
        healthPct: undefined,
        leverage: undefined,
        unrealizedPnl: undefined,
      };
    }
    return {
      accountValue: getAccountValue(account, markets).decimal,
      maintMarginReq: getAccountMaintMarginReq(account, markets).decimal,
      healthPct: getAccountHealthPct(account, markets)?.decimal,
      leverage: getAccountLeverage(account, markets).decimal,
      unrealizedPnl: getAccountPnl(account, markets).decimal,
    };
  }, [account, accountMarkets, hasAuth, isAccountPending, isAccountError]);

  return (
    <div className="flex flex-col">
      <FormItem className="flex-col gap-3">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            <h5 className="text-vestgrey-100">Account Health</h5>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger>
                  <Info size={14} />
                </TooltipTrigger>
                <TooltipContent>
                  Account Health = Account Value / Maintenance Margin
                  Requirement. It indicates how close you are to liquidation. At
                  100%, your collateral fully covers your positions, but near
                  0%, liquidations can occur.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <span
            className="font-mono"
            style={{
              color:
                accountStats.healthPct === undefined
                  ? '#AEADAD'
                  : Number(accountStats.healthPct) >= 60
                    ? '#56f5be'
                    : Number(accountStats.healthPct) > 20
                      ? '#FFE145'
                      : '#f32b46',
            }}
          >
            {formatNumber(accountStats.healthPct, {
              digits: 0,
              style: 'percent',
            })}
          </span>
        </div>
        <HealthBar
          value={
            Number(accountStats.healthPct)
              ? Number(accountStats.healthPct)
              : undefined
          }
        />
      </FormItem>
      <FormItem>
        <p>Account Value</p>
        <p className="font-mono text-vestgrey-50">
          {formatNumber(accountStats?.accountValue, { digits: 2 })}
        </p>
      </FormItem>
      <FormItem>
        <p>Maintenance Margin</p>
        <p className="font-mono text-vestgrey-50">
          {formatNumber(accountStats?.maintMarginReq, { digits: 2 })}
        </p>
      </FormItem>
      <FormItem>
        <p>Leverage</p>
        <p className="font-mono text-vestgrey-50">
          {formatNumber(accountStats?.leverage, { style: 'leverage' })}
        </p>
      </FormItem>
      <FormItem>
        <p>Unrealized PNL</p>
        <p
          className={cn(
            'font-mono',
            accountStats?.unrealizedPnl === undefined
              ? 'text-vestgrey-20'
              : Number(accountStats.unrealizedPnl) < 0
                ? 'text-red'
                : 'text-green',
          )}
        >
          {formatNumber(accountStats?.unrealizedPnl, {
            digits: 2,
            showChange: true,
          })}
        </p>
      </FormItem>
    </div>
  );
};
