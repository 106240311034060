import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
export interface Position {
  isLong: boolean;
  size: bigint;
  cost: bigint;
  entryFunding: bigint;
  initMarginRatio: bigint;
}

export interface Positions {
  [symbol: string]: Position;
}

export interface Account {
  collateral: bigint;
  positions: Positions;
}

export interface AuthParams {
  address: string; // NOTE: currently checksummed, match wagmi; this is unused except during store rehydration
  signingKey: `0x${string}`;
  apiKey: string;
  accountGroup: number;
  listenKey: string;
  listenKeyExpiry: number;
  shouldPersist: boolean;
}

export interface AccountStore {
  _hasHydrated: boolean; // internal zustand hydration
  setHasHydrated: (hasHydrated: boolean) => void;

  clearAccountStore: () => void;

  authHydrated: boolean; // api-provider auth hydration based on connected wallet
  setAuthHydrated: (authHydrated: boolean) => void;
  hasAuth: boolean;
  setHasAuth: (hasAuth: boolean) => void;
  authParams: AuthParams | undefined;
  setAuthParams: (authParams: AuthParams) => void;

  nonce: number;
  nextNonce: () => number; // returns new nonce

  account: Account;
  setAccount: (account: Account) => void;

  accountLastUpdated: number;
  setAccountLastUpdated: (accountLastUpdated: number) => void;
}

export const useAccountStore = create<AccountStore>()(
  persist(
    (set, get) => ({
      _hasHydrated: false,
      setHasHydrated: (state) => {
        set({
          _hasHydrated: state,
        });
      },

      clearAccountStore: () =>
        set({
          hasAuth: false,
          authParams: undefined,
          account: {
            collateral: BigInt(0),
            positions: {},
          },
        }),

      authHydrated: false,
      setAuthHydrated: (authHydrated) => set({ authHydrated }),
      hasAuth: false,
      setHasAuth: (hasAuth) => {
        if (hasAuth) {
          set({ hasAuth });
        } else {
          set({ hasAuth, authParams: undefined });
        }
      },
      authParams: undefined,
      setAuthParams: (authParams) => set({ authParams }),

      nonce: Date.now(),
      nextNonce: () => {
        let nextNonce: number;
        set((state) => {
          nextNonce = Math.max(Date.now(), state.nonce + 1);
          return { nonce: nextNonce };
        });
        return nextNonce!;
      },

      account: {
        collateral: BigInt(0),
        positions: {},
      },
      setAccount: (account) => set({ account }),

      accountLastUpdated: 0,
      setAccountLastUpdated: (accountLastUpdated) =>
        set({ accountLastUpdated }),
    }),
    {
      name: 'auth',
      storage: createJSONStorage(() => localStorage), // TODO: IndexedDB: https://github.com/pmndrs/zustand/discussions/1721
      partialize: (state) => {
        if (state.authParams?.shouldPersist === true) {
          return {
            authParams: state.authParams,
          };
        }
        return {};
      },
      onRehydrateStorage: (state) => {
        return (state, error) => {
          if (error) {
            console.error('Error rehydrating account store', error);
            return;
          }
          if (!state) {
            console.error('State undefined after rehydration');
            return;
          }
          state.setHasHydrated(true);
        };
      },
    },
  ),
);
