const getHexColor = (value: number | undefined) =>
  value === undefined
    ? '#AEADAD'
    : value >= 60
      ? '#56f5be'
      : value > 20
        ? '#FFE145'
        : '#f32b46';

export default function HealthBar({ value }: { value: number | undefined }) {
  const getBarStyles = (barIndex: number) => {
    const barStart = barIndex * 20;
    const barEnd = (barIndex + 1) * 20;

    if (value === undefined) return { full: 0, partial: 1 }; // Not filled, partial opacity

    if (value >= barEnd) {
      // Fully filled
      return { full: 1, partial: 0 };
    } else if (value > barStart && value < barEnd) {
      // Partially filled
      const filledPercentage = (value - barStart) / 20;
      return { full: filledPercentage, partial: 1 - filledPercentage };
    } else {
      return { full: 0, partial: 1 }; // Not filled, partial opacity
    }
  };

  return (
    <div className="flex h-1.5 w-full gap-1">
      {Array.from({ length: 5 }).map((_, index) => {
        const { full, partial } = getBarStyles(index);

        return (
          <div key={index} className="flex flex-1">
            {/* Full opacity part */}
            <div
              style={{
                width: `${full * 100}%`,
                backgroundColor: getHexColor(value),
                opacity: 1, // Fully opaque green
              }}
            />
            {/* Partial opacity part */}
            <div
              style={{
                width: `${partial * 100}%`,
                backgroundColor: getHexColor(value),
                opacity: 0.5, // Semi-transparent green
              }}
            />
          </div>
        );
      })}
    </div>
  );
}
