import LPFormTabs from '@/features/lp/components/lp-form-tabs';
import LPStats from '@/features/lp/components/lp-stats';
import LPTable from '@/features/lp/components/lp-table';
import PerformanceChart from '@/features/lp/components/performance-chart';
import {
  ExchangeInfo,
  useExchangeInfo,
} from '@/features/markets/api/get-exchange-info';
import { cn } from '@/lib/utils';
import useGeofenceCheck from '@/utils/geofence-check';
import { formatNumber } from '@/utils/value-format';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

export default function LPPage() {
  const { data: isSanctioned } = useGeofenceCheck();

  const { data } = useExchangeInfo();
  const exchangeInfo = data as ExchangeInfo;

  return (
    <div>
      <div className="grid h-40 grid-cols-5 items-center border-b border-border bg-background">
        <h1 className="pl-8 text-2xl font-semibold sm:text-3xl lg:text-4xl">
          Liquidity Providing
        </h1>
        <div className="col-span-3 flex flex-col gap-2 px-8">
          <p className="font-medium text-vestgrey-100">
            Provide backstop liquidity to Vest Exchange
          </p>
          <p className="max-w-7xl text-vestgrey-100">
            The lock up period is 1 day. Yield comes from funding rates and
            premiums paid by traders.{' '}
            <a
              href="https://docs.vest.exchange/overview/liquidity-providers"
              className="font-medium underline"
              target="_blank"
            >
              More info
            </a>
          </p>
        </div>
        <div className="grid grid-cols-2 pl-8">
          <div className="flex flex-col gap-2">
            <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
              Estimated APY
            </div>
            <div
              className={cn(
                'text-font flex flex-1 items-center justify-start text-xl font-bold text-green',
              )}
            >
              20%
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
              TVL
            </div>
            <div
              className={cn(
                'text-font flex flex-1 items-center justify-start text-xl font-bold',
              )}
            >
              {formatNumber(exchangeInfo.exchange.lp, {
                digits: 2,
                compact: true,
                round: 'ceil',
              })}
            </div>
          </div>
        </div>
      </div>
      <div
        className={cn(
          'flex',
          isSanctioned ? 'h-[calc(100vh-225px)]' : 'h-[calc(100vh-180px)]',
        )}
      >
        <div className="grid w-4/5 grid-cols-4 grid-rows-[max-content_1fr] border-b border-border">
          <LPStats />
          <PerformanceChart />
          <div className="col-span-full">
            <LPTable />
          </div>
        </div>
        <div className="flex w-1/5 flex-col border-l border-border bg-background">
          <LPFormTabs />
        </div>
      </div>
    </div>
  );
}
