import React from 'react';
import { Button } from '.';

interface OverlayProps {
  message: string;
  buttonText?: string;
  onButtonClick: () => void;
}

export const Overlay: React.FC<OverlayProps> = ({
  message,
  buttonText = 'Refresh Page',
  onButtonClick,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-80 font-mono text-white">
      <h1 className="text-2xl">{message}</h1>
      <Button className="mt-6" size="xl" onClick={onButtonClick}>
        {buttonText}
      </Button>
    </div>
  );
};
