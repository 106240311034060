export enum ErrorCode {
  // Internal to FE
  UNKNOWN = 0,
  INTERNAL_ID_MISMATCH = 900,
  INTERNAL_CREATE_ORDER_TIMEOUT = 901,

  // General
  UNAUTHORIZED = 1002,
  TOO_MANY_REQUESTS = 1003,
  INVALID_SIGNATURE = 1022,
  INVALID_NONCE = 1023,
  ACCOUNT_NOT_FOUND = 1099,

  // Invalid request
  BAD_DECIMALS = 1111,
  INVALID_ORDER_TYPE = 1116,
  BAD_SYMBOL = 1121,
  INVALID_LISTEN_KEY = 1125,
  INVALID_PARAMETER = 1130,
  BAD_RECV_WINDOW = 1131,
  ORDER_EXPIRED = 1132,

  // Order rejection
  PRICE_CHECK_FAILED = 3001,
  MARGIN_CHECK_FAILED = 3002,
  PRICE_STALE = 3003,
  ORDER_STALE = 3004,
  INCREASE_WITH_SL_TP = 3006,
  INCREASE_DURING_INSOLVENCY = 3007,
  DELETED_BY_LIQ = 3008,
  OI_CAP_EXCEEDED = 3010,
  INVALID_LIMIT_PRICE = 3011,
  DUPLICATE = 3015,
  ORDER_ACCOUNT_NOT_FOUND = 3016,
  ORDER_NOT_FOUND = 3017,
  REDUCE_ONLY_INCREASES = 3018,
  REDUCE_ONLY_EXCEEDS_SIZE = 3019,
  ORDER_CHANGES_LEVERAGE = 3020,

  // LP order rejection
  LP_INSUFFICIENT_BALANCE = 4001,
  LP_MARGIN_CHECK_FAILED = 4002,
  LP_PRICE_STALE = 4003,
  LP_ORDER_STALE = 4004,
  LP_WITHDRAW_WITHOUT_SHARES = 4007,
  LP_DUPLICATE = 4010,
  LP_ACCOUNT_NOT_FOUND = 4011,
  LP_WITHDRAW_EXCEEDS_BALANCE = 4012,

  // Transfer rejection
  TRANSFER_MARGIN_CHECK_FAILED = 5002,
  TRANSFER_WITHDRAW_CAP_EXCEEDED = 5009,
  TRANSFER_DUPLICATE = 5010,
  TRANSFER_ACCOUNT_NOT_FOUND = 5011,

  // Referrals
  NOT_UNIQUE_REF_CODE = 1140,
  NOT_FOUND_REF_CODE = 1141,
  ALREADY_USE_REF_CODE = 1142,

  // Health
  MAX_RETRIES_EXCEEDED = 9999,
}

export const ErrorMsgs: { [key in ErrorCode]: string } = {
  // Internal to FE
  [ErrorCode.UNKNOWN]:
    'Internal error: Unknown error. Please contact support team.', // INTERNAL_ID_MISMATCH
  [ErrorCode.INTERNAL_ID_MISMATCH]:
    'Internal error: Order ID mismatch. Please contact support team.', // INTERNAL_ID_MISMATCH
  [ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT]:
    'Request timed out. Please try again later.', // INTERNAL_CREATE_ORDER_TIMEOUT

  // General
  [ErrorCode.UNAUTHORIZED]: 'You are not authorized to perform this action', // UNAUTHORIZED
  [ErrorCode.TOO_MANY_REQUESTS]: 'Too many requests. Please try again later', // TOO_MANY_REQUESTS
  [ErrorCode.INVALID_SIGNATURE]: 'Invalid signature.', // INVALID_SIGNATURE
  [ErrorCode.INVALID_NONCE]: 'Invalid nonce.', // INVALID_NONCE
  [ErrorCode.ACCOUNT_NOT_FOUND]: 'Account not found.', // ACCOUNT_NOT_FOUND

  // Invalid request
  [ErrorCode.BAD_DECIMALS]: 'Invalid decimal places. Please check your input', // BAD_DECIMALS
  [ErrorCode.INVALID_ORDER_TYPE]:
    'Invalid order type. Please select a valid order type', // INVALID_ORDER_TYPE
  [ErrorCode.BAD_SYMBOL]:
    'Invalid trading pair. Please select a valid trading pair', // BAD_SYMBOL
  [ErrorCode.INVALID_LISTEN_KEY]: 'Invalid listen key.',
  [ErrorCode.INVALID_PARAMETER]: 'Invalid parameter. Please check your input', // INVALID_PARAMETER
  [ErrorCode.BAD_RECV_WINDOW]:
    'Invalid receive window. Please adjust your settings', // BAD_RECV_WINDOW
  [ErrorCode.ORDER_EXPIRED]:
    'Order has expired. This might be due to a slow connection.', // ORDER_EXPIRED

  // Order rejection
  [ErrorCode.PRICE_CHECK_FAILED]:
    'Executed price exceeded limit price. If this was a Market Order, the incurred slippage exceeded maximum slippage limits.', // PRICE_CHECK_FAILED
  [ErrorCode.MARGIN_CHECK_FAILED]: 'Insufficient funds.', // MARGIN_CHECK_FAILED
  [ErrorCode.PRICE_STALE]: 'Price is outdated.', // PRICE_STALE
  [ErrorCode.ORDER_STALE]: 'Order is outdated.', // ORDER_STALE
  [ErrorCode.INCREASE_WITH_SL_TP]:
    'Cannot increase position with stop loss or take profit', // INCREASE_WITH_SL_TP
  [ErrorCode.INCREASE_DURING_INSOLVENCY]:
    'Cannot increase position during insolvency', // INCREASE_DURING_INSOLVENCY
  [ErrorCode.DELETED_BY_LIQ]: 'Order deleted due to liquidation', // DELETED_BY_LIQ
  [ErrorCode.OI_CAP_EXCEEDED]:
    'Open interest cap exceeded. Please try a smaller order', // OI_CAP_EXCEEDED
  [ErrorCode.INVALID_LIMIT_PRICE]:
    'Invalid limit price. Please adjust your order', // INVALID_LIMIT_PRICE
  [ErrorCode.DUPLICATE]: 'Duplicate order. Please check your recent orders', // DUPLICATE
  [ErrorCode.ORDER_NOT_FOUND]: 'Order not found. Please check the order ID', // ORDER_NOT_FOUND
  [ErrorCode.REDUCE_ONLY_INCREASES]:
    'Reduce-only order would increase position. Please adjust your order', // REDUCE_ONLY_INCREASES
  [ErrorCode.REDUCE_ONLY_EXCEEDS_SIZE]:
    'Reduce-only order exceeds position size. Please adjust your order', // REDUCE_ONLY_EXCEEDS_SIZE
  [ErrorCode.ORDER_CHANGES_LEVERAGE]:
    'Order would change leverage. Please adjust your order or leverage settings', // ORDER_CHANGES_LEVERAGE
  [ErrorCode.ORDER_ACCOUNT_NOT_FOUND]:
    'Account not found. Please check your account details', // ORDER_ACCOUNT_NOT_FOUND

  // LP order rejection
  [ErrorCode.LP_INSUFFICIENT_BALANCE]:
    'Insufficient balance. Please add funds to your account', // INSUFFICIENT_BALANCE
  [ErrorCode.LP_WITHDRAW_WITHOUT_SHARES]:
    'Cannot withdraw LP without shares. Please check your LP balance', // LP_WITHDRAW_WITHOUT_SHARES
  [ErrorCode.LP_WITHDRAW_EXCEEDS_BALANCE]:
    'LP withdrawal exceeds balance. Please reduce the withdrawal amount', // LP_WITHDRAW_EXCEEDS_BALANCE
  // LP order rejection
  [ErrorCode.LP_MARGIN_CHECK_FAILED]:
    'Insufficient balance. Please add funds to your account', // LP_MARGIN_CHECK_FAILED
  [ErrorCode.LP_PRICE_STALE]: 'Price is outdated. Please submit a new order', // LP_PRICE_STALE
  [ErrorCode.LP_ORDER_STALE]: 'Order is outdated. Please submit a new order', // LP_ORDER_STALE
  [ErrorCode.LP_DUPLICATE]: 'Duplicate order. Please check your recent orders', // LP_DUPLICATE
  [ErrorCode.LP_ACCOUNT_NOT_FOUND]:
    'Account not found. Please check your account details', // LP_ACCOUNT_NOT_FOUND

  // Transfer rejection
  [ErrorCode.TRANSFER_WITHDRAW_CAP_EXCEEDED]:
    'Withdrawal cap exceeded. Please try a smaller amount or contact support', // WITHDRAW_CAP_EXCEEDED
  [ErrorCode.TRANSFER_MARGIN_CHECK_FAILED]:
    'Insufficient balance. Please add funds to your account', // TRANSFER_MARGIN_CHECK_FAILED
  [ErrorCode.TRANSFER_DUPLICATE]:
    'Duplicate transfer. Please check your recent transfers', // TRANSFER_DUPLICATE
  [ErrorCode.TRANSFER_ACCOUNT_NOT_FOUND]:
    'Account not found. Please check your account details', // TRANSFER_ACCOUNT_NOT_FOUND

  // Referrals
  [ErrorCode.NOT_UNIQUE_REF_CODE]:
    'Referral code already claimed by another user',
  [ErrorCode.NOT_FOUND_REF_CODE]: 'Referral code not found',
  [ErrorCode.ALREADY_USE_REF_CODE]: 'Referral code already used',
  [ErrorCode.MAX_RETRIES_EXCEEDED]:
    'WebSocket connection max retires reached. Please refresh the page',
} as const;

export enum SymbolStatus {
  TRADING = 'TRADING',
  HALT = 'HALT',
}

export enum OrderStatus {
  NEW = 'NEW',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  FILLED = 'FILLED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
}

export enum OrderType {
  MARKET = 'MARKET',
  LIMIT = 'LIMIT',
  STOP_LOSS = 'STOP_LOSS',
  TAKE_PROFIT = 'TAKE_PROFIT',
  LIQUIDATION = 'LIQUIDATION', // only in response
}

export enum LPType {
  DEPOSIT = 'DEPOSIT',
  IMMEDIATE_WITHDRAW = 'IMMEDIATE_WITHDRAW',
  SCHEDULE_WITHDRAW = 'SCHEDULE_WITHDRAW',
}

export enum TransferType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export enum LPOrderType {
  DEPOSIT = 'DEPOSIT',
  IMMEDIATE_WITHDRAW = 'IMMEDIATE_WITHDRAW',
  SCHEDULE_WITHDRAW = 'SCHEDULE_WITHDRAW',
}

export enum OrderHistoryFilterType {
  ALL = 'all',
  FILLED = 'filled',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
}

export enum TableTabType {
  POSITIONS = 'positions',
  OPEN_ORDERS = 'open-orders',
  ORDER_HISTORY = 'order-history',
  TRANSFERS = 'transfers',
}
