import {
  arbitrum,
  base,
  mainnet,
  optimism,
  polygon,
  zksync,
  zksyncSepoliaTestnet,
} from 'wagmi/chains';

export const getUSDCAddress = (chainId: number): `0x${string}` => {
  switch (chainId) {
    case arbitrum.id:
      return import.meta.env.VITE_USDC_ARBITRUM;

    case base.id:
      return import.meta.env.VITE_USDC_BASE;

    case mainnet.id:
      return import.meta.env.VITE_USDC_MAINNET;

    case optimism.id:
      return import.meta.env.VITE_USDC_OPTIMISM;

    case polygon.id:
      return import.meta.env.VITE_USDC_POLYGON;

    case zksync.id:
    case zksyncSepoliaTestnet.id:
      return import.meta.env.VITE_USDC_ZKSYNC;

    default:
      throw new Error('Unsupported chain');
  }
};

export const getBridgeAddress = (chainId: number): `0x${string}` => {
  switch (chainId) {
    case arbitrum.id:
      return import.meta.env.VITE_BRIDGE_ARBITRUM;

    case base.id:
      return import.meta.env.VITE_BRIDGE_BASE;

    case mainnet.id:
      return import.meta.env.VITE_BRIDGE_MAINNET;

    case optimism.id:
      return import.meta.env.VITE_BRIDGE_OPTIMISM;

    case polygon.id:
      return import.meta.env.VITE_BRIDGE_POLYGON;

    case zksync.id:
    case zksyncSepoliaTestnet.id:
      return import.meta.env.VITE_BRIDGE_ZKSYNC;

    default:
      throw new Error('Unsupported chain');
  }
};
