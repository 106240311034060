import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from 'react-router-dom';
import RootLayout from '@/components/layouts/root-layout';
import NotFoundPage from './pages/not-found';
import LPPage from './pages/lp';
import PortfolioPage from './pages/portfolio';
import TradePage from './pages/trade';
import PrivacyPolicy from './pages/privacy-policy';
import TermsOfService from './pages/terms-of-service';
import MarketsPage from './pages/markets';
import ReferralsPage from './pages/referrals';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/trade/ETH-PERP" replace />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      {
        path: '/trade',
        element: <Navigate to="/trade/ETH-PERP" replace />,
      },
      {
        path: '/portfolio/overview',
        element: <Navigate to="/trade/ETH-PERP" replace />,
      },
      {
        path: '/liquidity-providing',
        element: <LPPage />,
      },
      {
        path: '/referrals',
        element: <ReferralsPage />,
      },
      {
        path: '/trade/:symbol',
        element: <TradePage />,
      },
      {
        path: '/portfolio',
        element: <PortfolioPage />,
      },
      {
        path: '/markets',
        element: <MarketsPage />,
      },
      {
        path: '/terms',
        element: <TermsOfService />,
      },
      {
        path: '/privacy',
        element: <PrivacyPolicy />,
      },
    ],
  },
]);

export default function AppRouter() {
  // const queryClient = useQueryClient();

  // const router = useMemo(() => createAppRouter(queryClient), [queryClient]);

  return <RouterProvider router={router} />;
}
