import { lazy, Suspense } from 'react';
import { MinusCircledIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui';
import { bigIntToDecimalStr, formatBigInt } from '@/utils/value-format';
import { useEffect, useMemo, useState } from 'react';
import { OrderType } from '@/types/enums';
import { TpslInput } from '@/features/trade/order/components/tpsl-input';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import { Order } from '@/store/use-orders-store';
import { useMarketStore } from '@/store/use-markets-store';
import { Position } from '@/store/use-account-store';
import {
  CreateOrderInputSchemaWithId,
  updateOrders,
} from '@/features/account/api/update-orders';
import { abbrFromSym } from '@/utils/token-symbol';
import { useStore } from 'zustand';
import { cn } from '@/utils/cn';

interface TPSLEditProps {
  symbol: string;
  currentPosition: Position;
  currentTps: Order[];
  currentSls: Order[];
  disabled?: boolean;
}

const FormValuesSchema = z.object({
  tps: z.array(CreateOrderInputSchemaWithId),
  sls: z.array(CreateOrderInputSchemaWithId),
});

type FormValues = z.infer<typeof FormValuesSchema>;

const LazyTPSLEditContent = lazy(() =>
  Promise.resolve({
    default: TPSLEditContent,
  }),
);

export const TPSLEdit = (props: TPSLEditProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={props.disabled}>
        <button disabled={props.disabled}>
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-foreground/80"
          >
            <path
              d="M2.276 9.59511L9.03733 2.83377L8.09467 1.89111L1.33333 8.65244V9.59511H2.276ZM2.82867 10.9284H0V8.09977L7.62333 0.476441C7.74835 0.35146 7.91789 0.28125 8.09467 0.28125C8.27144 0.28125 8.44098 0.35146 8.566 0.476441L10.452 2.36244C10.577 2.48746 10.6472 2.657 10.6472 2.83377C10.6472 3.01055 10.577 3.18009 10.452 3.30511L2.82867 10.9284ZM0 12.2618H12V13.5951H0V12.2618Z"
              fill="inherit"
            />
          </svg>
        </button>
      </DialogTrigger>
      {open && (
        <Suspense fallback={null}>
          <LazyTPSLEditContent {...props} open={open} onOpenChange={setOpen} />
        </Suspense>
      )}
    </Dialog>
  );
};

interface TPSLEditContentProps extends TPSLEditProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function TPSLEditContent({
  symbol,
  currentPosition,
  currentTps,
  currentSls,
  onOpenChange,
}: TPSLEditContentProps) {
  const { marketSpec } = useMemo(
    () => useMarketStore(symbol).getState(),
    [symbol],
  );
  const marketData = useStore(
    useMarketStore(symbol),
    (state) => state.marketData,
  );

  const defaultValues = useMemo(() => {
    return {
      tps: currentTps
        .sort((a, b) => Number(a.limitPrice - b.limitPrice))
        .map((tp) => ({
          // ascending
          id: tp.id,
          symbol: tp.symbol,
          isBuy: tp.isBuy,
          size: bigIntToDecimalStr(tp.size, marketSpec.sizeDecimals),
          orderType: tp.orderType,
          limitPrice: bigIntToDecimalStr(
            tp.limitPrice,
            marketSpec.priceDecimals,
          ),
          reduceOnly: tp.reduceOnly,
          // initMarginRatio: bigIntToDecimalStr(tp.initMarginRatio, 4n),
        })),
      sls: currentSls
        .sort((a, b) => Number(b.limitPrice - a.limitPrice))
        .map((sl) => ({
          // descending
          id: sl.id,
          symbol: sl.symbol,
          isBuy: sl.isBuy,
          size: bigIntToDecimalStr(sl.size, marketSpec.sizeDecimals),
          orderType: sl.orderType,
          limitPrice: bigIntToDecimalStr(
            sl.limitPrice,
            marketSpec.priceDecimals,
          ),
          reduceOnly: sl.reduceOnly,
          // initMarginRatio: bigIntToDecimalStr(sl.initMarginRatio, 4n),
        })),
    };
  }, [currentTps, currentSls, marketSpec]);

  const form = useForm<FormValues>({
    resolver: zodResolver(FormValuesSchema),
    defaultValues,
  });

  const {
    fields: tpFields,
    append: appendTp,
    remove: removeTp,
  } = useFieldArray({
    control: form.control,
    name: 'tps',
  });

  const {
    fields: slFields,
    append: appendSl,
    remove: removeSl,
  } = useFieldArray({
    control: form.control,
    name: 'sls',
  });

  const { isDirty, isValid } = useFormState({
    control: form.control,
    name: ['tps', 'sls'],
  });

  const onSubmit = (data: FormValues) => {
    if (isDirty) {
      updateOrders(
        [...defaultValues.tps, ...defaultValues.sls],
        [...data.tps, ...data.sls],
        marketSpec,
      );
      onOpenChange(false);
    }
  };

  return (
    <DialogContent className="gap-0 border border-border bg-background p-0 sm:max-w-2xl sm:rounded-none">
      <DialogHeader className="px-5 pt-6">
        <DialogTitle className="text-xl text-white">
          Set Take Profits / Stop Losses{' '}
        </DialogTitle>
      </DialogHeader>
      <div className="flex flex-col gap-3 px-5 pb-3 pt-6">
        <div className="flex h-8 items-center justify-between">
          <p className="text-left text-base text-vestgrey-100">Position</p>
          <p className="font-mono text-vestgrey-50">
            <span
              className={cn(currentPosition.isLong ? 'text-green' : 'text-red')}
            >
              {currentPosition.isLong ? 'Long' : 'Short'}
            </span>{' '}
            {formatBigInt(
              currentPosition.size * marketData.markPrice,
              marketSpec.sizeDecimals + marketSpec.priceDecimals,
              { digits: 2 },
            )}{' '}
            (
            {formatBigInt(currentPosition.size, marketSpec.sizeDecimals, {
              digits: 2,
            })}{' '}
            {abbrFromSym(symbol)})
          </p>
        </div>
        <div className="flex h-8 items-center justify-between">
          <p className="text-left text-base text-vestgrey-100">Entry Price</p>
          <p className="font-mono text-vestgrey-50">
            {formatBigInt(
              currentPosition.cost / currentPosition.size,
              marketSpec.priceDecimals,
            )}
          </p>
        </div>
        <div className="flex h-8 items-center justify-between">
          <p className="text-left text-base text-vestgrey-100">Mark Price</p>
          <p className="font-mono text-vestgrey-50">
            {formatBigInt(marketData.markPrice, marketSpec.priceDecimals)}
          </p>
        </div>
      </div>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="px-5 pb-8">
            <div className="space-y-3 border-y border-border py-3">
              <div className="flex items-center justify-between">
                <h4 className="text-base text-vestgrey-100">Take Profits</h4>
                <button
                  onClick={() =>
                    appendTp({
                      id: 'NEW',
                      symbol: symbol,
                      isBuy: !currentPosition.isLong,
                      size: bigIntToDecimalStr(
                        currentPosition.size,
                        marketSpec.sizeDecimals,
                      ),
                      orderType: OrderType.TAKE_PROFIT,
                      limitPrice: '',
                      reduceOnly: true,
                      // initMarginRatio: '1.000',
                    })
                  }
                  className="grid h-7 w-7 min-w-7 place-content-center text-primary"
                >
                  <PlusCircledIcon className="h-7 w-7 text-primary" />
                </button>
              </div>
              {tpFields.map((field, index) => (
                <div key={field.id} className="flex items-center">
                  <TpslInput
                    {...form.register(`tps.${index}`)}
                    orderType={OrderType.TAKE_PROFIT}
                    symbol={symbol}
                    canChangeSize={true}
                    showTitle={false}
                  />
                  <button
                    onClick={() => removeTp(index)}
                    className="grid h-7 w-7 min-w-7 place-content-center text-vestgrey-100"
                  >
                    <MinusCircledIcon className="h-7 w-7 text-vestgrey-100" />
                  </button>
                </div>
              ))}
            </div>

            <div className="space-y-3 border-b border-border py-3">
              <div className="flex items-center justify-between">
                <h4 className="text-base text-vestgrey-100">Stop Losses</h4>
                <button
                  onClick={() =>
                    appendSl({
                      id: 'NEW',
                      symbol: symbol,
                      isBuy: !currentPosition.isLong,
                      size: bigIntToDecimalStr(
                        currentPosition.size,
                        marketSpec.sizeDecimals,
                      ),
                      orderType: OrderType.STOP_LOSS,
                      limitPrice: '',
                      reduceOnly: true,
                      // initMarginRatio: '1.000',
                    })
                  }
                  className="grid h-7 w-7 min-w-7 place-content-center text-primary"
                >
                  <PlusCircledIcon className="h-7 w-7 text-primary" />
                </button>
              </div>
              {slFields.map((field, index) => (
                <div key={field.id} className="flex items-center">
                  <TpslInput
                    {...form.register(`sls.${index}`)}
                    orderType={OrderType.STOP_LOSS}
                    symbol={symbol}
                    canChangeSize={true}
                    showTitle={false}
                  />
                  <button
                    onClick={() => removeSl(index)}
                    className="grid h-7 w-7 min-w-7 place-content-center text-vestgrey-100"
                  >
                    <MinusCircledIcon className="h-7 w-7 text-vestgrey-100" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              className="h-14 w-full rounded-none font-mono text-lg font-normal tracking-wider"
              disabled={!isDirty || !isValid}
            >
              SET TP/SL
            </Button>
          </DialogFooter>
        </form>
      </FormProvider>
    </DialogContent>
  );
}
