export default function TermsOfService() {
  return (
    <div>
      <div className="text-vestlightgrey-a flex flex-col gap-5 p-8 text-sm">
        <h1 className="text-center text-2xl font-bold">Terms of Use</h1>
        <p className="uppercase">
          THE VEST INTERFACE (AS DEFINED BELOW) IS NOT AVAILABLE TO PERSONS OR
          ENTITIES WHO RESIDE IN, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A
          REGISTERED OFFICE IN THE UNITED STATES OF AMERICA (COLLECTIVELY,
          “BLOCKED PERSONS”). MOREOVER, NO SERVICES (AS DEFINED BELOW) ARE
          OFFERED TO PERSONS OR ENTITIES WHO RESIDE IN, ARE CITIZENS OF, ARE
          LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN ANY
          RESTRICTED TERRITORY (AS DEFINED BELOW, AND ANY SUCH PERSON OR ENTITY
          FROM A RESTRICTED TERRITORY, A “RESTRICTED PERSON”). WE DO NOT MAKE
          EXCEPTIONS; THEREFORE, IF YOU ARE A BLOCKED PERSON, THEN DO NOT
          ATTEMPT TO USE THE VEST INTERFACE AND IF YOU ARE A RESTRICTED PERSON,
          THEN DO NOT ATTEMPT TO USE ANY OF THE SERVICES. USE OF A VIRTUAL
          PRIVATE NETWORK (“VPN”) TO CIRCUMVENT THE RESTRICTIONS SET FORTH
          HEREIN IS PROHIBITED.
        </p>
        <div>
          These terms of use, together with any documents and additional terms
          they expressly incorporate by reference, which includes any other
          terms and conditions or other agreement that Vest Labs inc. and its
          affiliates (“Vest,” “we,” “us” and “our”) posts publicly or makes
          available to you or the company or other legal entity you represent
          (“you” or “your”) (collectively, these “Terms”), are entered into
          between Vest and you concerning your use of, and access to:
          <ul className="list-disc pl-5">
            <li>
              Vest’s websites, including Vest.exchange (and their respective
              subdomains); web applications; mobile applications; and all
              associated sites linked thereto by Vest (collectively with any
              materials and services available therein, and successor website(s)
              or application(s) thereto, the “Site”);
            </li>
            <li>
              all products and features available via the Site, including the
              order book, matching engine, smart contracts, decentralized
              applications, APIs and all other software that Vest or a third
              party has developed for trading blockchain-based assets
              (collectively, “Digital Assets”), including entering into
              perpetual contracts (“Perpetual Contracts”) related to Digital
              Assets, or exchanging one Digital Asset for another Digital Asset
              through any third party API, and the computation and storage
              scalability service known as “zkSync” (the “ZkSync Service”)
              (collectively the “Interface”); and
            </li>
            <li>
              non-fungible tokens (“NFTs”) available in connection with the
              Interface, or registration for or attendance at events sponsored
              or hosted by Vest (collectively with the Site and the Interface,
              the “Services”).
            </li>
          </ul>
        </div>
        <p>
          Please read these Terms carefully, as these Terms govern your use of
          the Services. These Terms expressly cover your rights and obligations,
          and our disclaimers and limitations of legal liability, relating to
          your use of, and access to, the Services. By clicking “I agree” (or a
          similar language) to these Terms, acknowledging these Terms by other
          means, or otherwise accessing or using the Services, you accept and
          agree to be bound by and to comply with these Terms, including the
          mandatory arbitration provision in Section 15. If you do not agree to
          these Terms, then you must not access or use the Services.
        </p>
        <p>
          <span className="text-base font-medium">
            Please carefully review the disclosures and disclaimers set forth in
            Section 12 in their entirety before using any software developed by
            Vest.
          </span>{' '}
          The information in Section 12 provides important details about the
          legal obligations associated with your use of the Services. By
          accessing or using the Services, you agree that Vest does not provide
          execution, settlement, or clearing services of any kind and is not
          responsible for the execution, settlement, or clearing of transactions
          automated through the Services.
        </p>

        <div>
          <div className="pl-3">
            <h2 className="mb-3 text-base font-medium">2. USE OF SERVICES</h2>
            <p className="mb-3 pl-2">
              2.1 As a condition to accessing or using the Services, you
              represent and warrant to Vest the following:
            </p>
            <ul className="mb-4 list-disc pl-5">
              <li>
                if you are entering into these Terms as an individual, then you
                are of legal age in the jurisdiction in which you reside and you
                have the legal capacity to enter into these Terms and be bound
                by them;
              </li>
              <li>
                if you are entering into these Terms as an entity, then you must
                have the legal authority to accept these Terms on that entity’s
                behalf, in which case “you” (except as used in this paragraph)
                will mean that entity;
              </li>
              <li>
                if you are entering into these Terms to access the Interface or
                will in the future access the Interface, then you are not a
                Blocked Person, and are not accessing the Interface from within
                the United States or Canada (collectively, "Blocked Countries");
              </li>
              <li>
                you must not be a resident, citizen or agent of, or incorporated
                in, and do not have a registered office in Iran, Cuba, North
                Korea, Syria, Myanmar (Burma), the regions of Crimea, Donetsk or
                Luhansk, or any other country or region that is the subject of
                comprehensive country-wide or region-wide economic sanctions by
                the United States (collectively, “Restricted Territories”);
              </li>
              <li>
                you are not the subject of economic or trade sanctions
                administered or enforced by any governmental authority or
                otherwise designated on any list of prohibited or restricted
                parties (including the list maintained by the Office of Foreign
                Assets Control of the U.S. Department of the Treasury)
                (collectively, “Sanctioned Person”);
              </li>
              <li>
                you do not intend to transact with any Restricted Person or
                Sanctioned Person;
              </li>
              <li>
                you do not, and will not, use a VPN or any other privacy or
                anonymization tools or techniques to circumvent, or attempt to
                circumvent, any restrictions that apply to the Services; and
              </li>
              <li>
                your access to the Services (a) is not prohibited by and does
                not otherwise violate or assist you to violate any domestic or
                foreign law, rule, statute, regulation, by-law, order, protocol,
                code, decree, or another directive, requirement, or guideline,
                published or in force that applies to or is otherwise intended
                to govern or regulate any person, property, transaction,
                activity, event or other matter, including any rule, order,
                judgment, directive or other requirement or guideline issued by
                any domestic or foreign federal, provincial or state, municipal,
                local or other governmental, regulatory, judicial or
                administrative authority having jurisdiction over Vest, you, the
                Services, or as otherwise duly enacted, enforceable by law, the
                common law or equity (collectively, “Applicable Laws”); and (b)
                does not contribute to or facilitate any illegal activity.
              </li>
            </ul>
            <p className="mb-3 pl-2">
              2.2 As a condition to accessing or using the Services, you
              acknowledge, understand, and agree to the following:
            </p>
            <ul className="mb-4 list-disc pl-5">
              <li>
                from time to time, the Services may be inaccessible or
                inoperable for any reason, including: (a) equipment or
                technology or other infrastructure delay, inaccessibility, or
                malfunctions; (b) periodic maintenance procedures or repairs
                that Vest or any of our suppliers or contractors may undertake
                from time to time; (c) causes beyond Vest’s control or that Vest
                could not reasonably foresee; (d) disruptions and temporary or
                permanent unavailability of underlying blockchain
                infrastructure; or (e) unavailability of third-party service
                providers or external partners for any reason. Without
                limitation of any other provision of these Terms, and as set
                forth below, Vest has no responsibility or liability for any
                losses or other injuries resulting from any such events;
              </li>
              <li>
                we reserve the right to disable or modify access to the Services
                (such as restricting features of the Services) at any time in
                the event of any breach of these Terms, including, if we
                reasonably believe any of your representations and warranties
                may be untrue, misleading, or inaccurate, and we will not be
                liable to you for any losses or damages you may suffer as a
                result of or in connection with the Services being inaccessible
                to you at any time or for any reason;
              </li>
              <li>
                the Services may evolve, which means Vest may apply changes,
                replace, or discontinue (temporarily or permanently) the
                Services at any time in our sole discretion;
              </li>
              <li>
                the pricing information and other data provided on the Site does
                not represent (i) an offer, a solicitation of an offer, or
                recommendation to enter into, a transaction with Vest (other
                than the payment of fees to Vest) or (ii) any advice regarding a
                transaction entered into using the Site and the Services;
              </li>
              <li>
                Vest does not act as an agent for you or any other user of the
                Services;
              </li>
              <li>
                you are solely responsible for your use of the Services,
                including all of your transfers of Digital Assets;
              </li>
              <li>
                to the fullest extent not prohibited by Applicable Law, we owe
                no fiduciary duties or liabilities to you or any other party,
                and that to the extent any such duties or liabilities may exist
                at law or in equity, you hereby irrevocably disclaim, waive, and
                eliminate those duties and liabilities;
              </li>
              <li>
                you are solely responsible for reporting and paying any taxes
                applicable to your use of the Services;
              </li>
              <li>
                we have no control over, or liability for, the delivery,
                quality, safety, legality, or any other aspect of any Digital
                Assets that you may transfer to or from a third party, and we
                are not responsible for ensuring that an entity with whom you
                transact completes the transaction or is authorized to do so,
                and if you experience a problem with any transactions in Digital
                Assets using the Services, then you bear the entire risk;
              </li>
              <li>
                we may, from time to time, operate contests, promotions,
                sweepstakes or other activities or offer referral programs
                (“Promotions and Referrals”), which may be governed by separate
                terms and conditions and rules that may contain certain
                eligibility requirements; and you are responsible for reading
                all terms and conditions and rules relating to the Promotions
                and Referrals to determine whether you are eligible to
                participate; if you enter or participate in any Promotions and
                Referrals, then you agree to abide by and to comply with all
                terms and conditions and rules of such Promotions and Referrals;
                all Promotions and Referrals will be optional so you should not
                enter or participate in such Promotions and Referrals if you do
                not agree to abide by and comply with all such terms and
                conditions and rules; and
              </li>
              <li>
                if you receive discounts on fees from any Promotions and
                Referrals that are not subject to separate terms and conditions
                and rules, then Vest reserves the right to add to, modify or
                eliminate the discounts and any other aspect of such Promotions
                and Referrals.
              </li>
            </ul>
            <p className="mb-3 pl-2">
              2.3 As a condition to accessing or using the Services, you
              covenant to Vest the following:
            </p>
            <ul className="mb-4 list-disc pl-5">
              <li>
                in connection with using the Services, you only will transfer
                legally-obtained Digital Assets that belong to you;
              </li>
              <li>
                you will obey all Applicable Laws in connection with using the
                Services, and you will not use the Services if the laws of your
                country, or any other Applicable Law, prohibit you from doing
                so;
              </li>
              <li>
                any Digital Assets you use in connection with the Services are
                either owned by you or you are validly authorized to carry out
                actions using such Digital Assets; and
              </li>
              <li>
                in addition to complying with all restrictions, prohibitions,
                and other provisions of these Terms, you will ensure that, at
                all times, all information that you provide on the Site and
                during your use of the Services is current, complete, and
                accurate.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">
            3. FEES AND PRICE ESTIMATES
          </h2>
          <p className="pl-3">
            In connection with your use of the Services, unless expressly stated
            otherwise pursuant to a promotion operated by Vest, you are required
            to pay all fees necessary for interacting with the Ethereum
            blockchain, including “gas” costs, as well as all other fees
            reflected on the Site at the time of your use of the Services.
            Although we attempt to provide accurate fee information, this
            information reflects our estimates of fees, which may vary from the
            actual fees paid to use the Services and interact with the Ethereum
            blockchain.
          </p>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">
            4. NO PROFESSIONAL ADVICE OR FIDUCIARY DUTIES
          </h2>
          <p className="pl-3">
            All information provided in connection with your access and use of
            the Services is for informational purposes only and should not be
            construed as professional advice. You should not take, or refrain
            from taking any action based on any information contained on the
            Site or any other information that we make available at any time,
            including blog posts, data, articles, links to third-party content,
            discord content, news feeds, tutorials, tweets, and videos. Before
            you make any financial, legal, or other decisions involving the
            Services, you should seek independent professional advice from an
            individual who is licensed and qualified in the area for which such
            advice would be appropriate. The Terms are not intended to, and do
            not, create or impose any fiduciary duties on us. You further agree
            that the only duties and obligations that we owe you are those set
            out expressly in these Terms.
          </p>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">5. PROHIBITED ACTIVITY</h2>
          <div className="pl-3">
            <p className="mb-3">
              You may not use the Services to engage in the categories of
              activity set forth below (“Prohibited Uses”). The specific
              activities set forth below are representative, but not exhaustive,
              of Prohibited Uses. If you are uncertain as to whether your use of
              the Services involves a Prohibited Use or have other questions
              about how these requirements apply to you, then please contact us
              at team@vest.exchange. By using the Services, you confirm that you
              will not engage in any of the following Prohibited Uses:
            </p>
            <ul className="list-disc pl-5">
              <li>
                violate any Applicable Laws including any relevant and
                applicable anti-money laundering and anti-terrorist financing
                laws and sanctions programs, such as the Bank Secrecy Act and
                the U.S. Department of Treasury’s Office of Foreign Asset
                Controls;
              </li>
              <li>
                engage in transactions involving items that infringe or violate
                any copyright, trademark, right of publicity or privacy, or any
                other proprietary right under Applicable Law, including sales,
                distribution or access to counterfeit music, movies, software or
                other licensed materials without the appropriate authorization
                from the rights holder; use of Vest’s or our licensors’
                intellectual property, name or logo, including use of Vest’s
                trade, service or licensed marks, without express consent from
                Vest or in a manner that otherwise harms Vest; any action that
                implies an untrue endorsement by or affiliation with Vest;
              </li>
              <li>
                engage in improper or abusive trading practices, including, but
                not limited to, (a) any fraudulent act or scheme to defraud,
                deceive, trick or mislead; (b) trading ahead of another user of
                the Services or front-running; (c) fraudulent trading; (d)
                accommodation trading; (e) fictitious transactions; (f)
                pre-arranged or non-competitive transactions; (g) cornering, or
                attempted cornering, of any Perpetual Contracts or other Digital
                Assets; (h) violations of bids or offers; (i) wash trading
                (i.e., entering buy and sell orders at or about the same price,
                volume, and time for the purpose of generating trading volume);
                (j) manipulation (i.e., trading for the purposes of affecting
                the market price of a Digital Asset and creating an artificial
                price); (k) spoofing (i.e., placing buy or sell orders without a
                bona fide intent to transact and with the intent to cancel
                before execution); (l) knowingly making any bid or offer for the
                purpose of making a market price that does not reflect the true
                state of the market; (m) entering orders for the purpose of
                entering into transactions without a net change in either
                party’s open positions but a resulting profit to one party and a
                loss to the other party, commonly known as a “money pass;” or
                (n) any other trading activity that, in the reasonable judgment
                of Vest, is abusive, improper or disruptive to the operation of
                the Interface.
              </li>
              <li>
                use the Services in any manner that could interfere with,
                disrupt, negatively affect, or inhibit other users from fully
                enjoying the Services, or that could damage, disable,
                overburden, or impair the functioning of the Site or the
                Services in any manner;
              </li>
              <li>
                circumvent any content-filtering techniques, security measures
                or access controls that Vest employs on the Site, including
                through the use of a VPN;
              </li>
              <li>
                use any robot, spider, crawler, scraper or other automated means
                or interface not provided by us to access the Services, to
                extract data, or to introduce any malware, virus, Trojan horse,
                worm, logic bomb, drop-dead device, backdoor, shutdown mechanism
                or other harmful material into the Site or the Services;
              </li>
              <li>
                provide false, inaccurate, or misleading information while using
                the Services or engage in activity that operates to defraud
                Vest, other users of the Services or any other person;
              </li>
              <li>
                use or access the Services to transmit or exchange Digital
                Assets that are the direct or indirect proceeds of any criminal
                or fraudulent activity, including terrorism or tax evasion;
              </li>
              <li>
                use the Services in any way that is, in our sole discretion,
                libelous, defamatory, profane, obscene, pornographic, sexually
                explicit, indecent, lewd, vulgar, suggestive, harassing,
                stalking, hateful, threatening, offensive, discriminatory,
                bigoted, abusive, inflammatory, fraudulent, deceptive or
                otherwise objectionable, or likely or intended to incite,
                threaten, facilitate, promote, or encourage hate, racial
                intolerance or violent acts against others;
              </li>
              <li>
                use the Services from a jurisdiction (including an IP address in
                a jurisdiction) that we have, in our sole discretion, determined
                is a jurisdiction where the use of the Site, the Interface or
                the Services is prohibited, including any Blocked Countries or
                any Restricted Territory;
              </li>
              <li>
                harass, abuse or harm another person, including Vest’s employees
                and service providers;
              </li>
              <li>
                impersonate another user of the Services or otherwise
                misrepresent yourself; or
              </li>
              <li>
                engage or attempt to engage, or encourage, induce or assist any
                third party to engage or attempt to engage in any of the
                activities prohibited under this Section 5 or any other
                provision of these Terms.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">6. CONTENT</h2>
          <p className="pl-3">
            You hereby grant to us a royalty-free, fully paid-up, sublicensable
            (through multiple tiers), transferable, perpetual, irrevocable,
            non-exclusive, worldwide license to use, copy, modify, create
            derivative works of, display, perform, publish and distribute, in
            any form, medium or manner, any content that is available to other
            users as a result of your use of the Site or the Services
            (collectively, “Your Content”), including for promoting Vest or the
            Services. You represent and warrant that (a) you own Your Content or
            have the right to grant the rights and licenses in these Terms; and
            (b) Your Content and our use of Your Content, as licensed herein,
            does not and will not violate, misappropriate or infringe on any
            third party’s rights.
          </p>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">7. PROPRIETARY RIGHTS</h2>
          <div className="pl-3">
            <ul className="list-disc pl-5">
              <li>
                7.1. You acknowledge that certain aspects of the Services may
                use, incorporate or link to certain open-source components and
                that your use of the Services is subject to, and you will comply
                with, any applicable open-source licenses that govern any such
                open-source components (collectively, the “Open-Source
                Licenses”). Without limiting the generality of the foregoing,
                you may not (a) resell, lease, lend, share, distribute, or
                otherwise permit any third party to use the Services; (b) use
                the Services for time-sharing or service bureau purposes; or (c)
                otherwise use the Services in a manner that violates the
                Open-Source Licenses.
              </li>
              <li>
                7.2. Excluding third-party software that the Services
                incorporates, as between you and Vest, Vest owns the Services,
                including all technology, content and other materials used,
                displayed or provided on the Site or in connection with the
                Services (including all intellectual property rights subsisting
                therein, whether or not subject to the Open-Source Licenses),
                and hereby grants you a limited, non-exclusive, revocable,
                non-transferable, non-sublicensable license to access and use
                those portions of the Site and the Services that are proprietary
                to Vest and not available pursuant to the Open-Source Licenses.
              </li>
              <li>
                7.3. Any of Vest’s product or service names, logos, and other
                marks used on the Site or as a part of the Services including
                Vest’s name and logo are trademarks owned by Vest or our
                licensors You may not copy, imitate, or use them without the
                prior written consent of Vest or the applicable licensors, and
                these Terms do not grant you any rights in those trademarks. You
                may not remove, obscure, or alter any legal notices displayed in
                or along with the Site or the Services.
              </li>
              <li>
                7.4. The Services are non-custodial. When you deposit Digital
                Assets into any smart contract available on the Interface, you
                are not transferring Digital Assets to Vest, and you retain
                control over those Digital Assets at all times.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">8. LINKS</h2>
          <p className="pl-3">
            The Services or third parties may provide, links to other World Wide
            Web or accessible sites, applications, or resources. You acknowledge
            and agree that Vest is not responsible for the availability of such
            external sites, applications or resources, and does not endorse and
            is not responsible or liable for any content, advertising, products,
            or other materials on or available from such sites or resources. You
            further acknowledge and agree that Company will not be responsible
            or liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods, or services available on or through any
            such site or resource.
          </p>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">
            9. MODIFICATION, SUSPENSION, AND TERMINATION
          </h2>
          <p className="pl-3">
            We may, at our sole discretion, from time to time and with or
            without prior notice to you, modify, suspend or disable (temporarily
            or permanently) the Services, in whole or in part, for any reason
            whatsoever, including to only allow open Perpetual Contracts to be
            closed. Upon termination of your access, your right to use the
            Services will immediately cease. We will not be liable for any
            losses suffered by you resulting from any modification to any
            Services or from any modification, suspension or termination, for
            any reason, of your access to all or any portion of the Services.
            The following sections of these Terms will survive any termination
            of your access to the Site or the Services, regardless of the
            reasons for its expiration or termination, in addition to any other
            provision which by law or by its nature should survive: Section 7
            and Sections 9 through Section 17.
          </p>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">10. RISKS</h2>
          <div className="pl-3">
            <ul className="list-disc pl-5">
              <li>
                10.1. By accessing or using the Services , you understand and
                agree to the inherent risks associated with cryptographic
                systems and blockchain-based networks; Digital Assets, including
                the usage and intricacies of native Digital Assets, like ether
                (ETH); smart contract-based tokens, including fungible tokens
                and NFTs; and systems that interact with blockchain-based
                networks. Vest does not own or control any of the underlying
                software through which blockchain networks are formed. In
                general, the software underlying blockchain networks, including
                the Ethereum blockchain, is open source, such that anyone can
                use, copy, modify, and distribute it. By using the Services, you
                acknowledge and agree (a) that Vest is not responsible for the
                operation of the blockchain-based software and networks
                underlying the Services, (b) that there exists no guarantee of
                the functionality, security, or availability of that software
                and networks, and (c) that the underlying blockchain-based
                networks are subject to sudden changes in operating rules, such
                as those commonly referred to as “forks,” which may materially
                affect the Services. Blockchain networks use public and private
                key cryptography. You alone are responsible for securing your
                private key(s). We do not have access to your private key(s).
                Losing control of your private key(s) will permanently and
                irreversibly deny you access to Digital Assets on the Ethereum
                blockchain or other blockchain-based networks. Neither Vest nor
                any other person or entity will be able to retrieve or protect
                your Digital Assets. If your private key(s) are lost, then you
                will not be able to transfer your Digital Assets to any other
                blockchain address or wallet. If this occurs, then you will not
                be able to realize any value or utility from the Digital Assets
                that you may hold.
              </li>
              <li>
                10.2. You acknowledge and understand that the Services and your
                Digital Assets could be impacted by one or more regulatory
                inquiries or regulatory actions, which could impede or limit the
                ability of Vest to continue to make available our proprietary
                software and could impede or limit your ability to access or use
                the Services.
              </li>
              <li>
                10.3. You acknowledge and understand that cryptography is a
                progressing field with advances in code cracking or other
                technical advancements, such as the development of quantum
                computers, which may present risks to Digital Assets and the
                Services, and could result in the theft or loss of your Digital
                Assets. To the extent possible, the smart contracts available on
                the Interface will be updated to account for any advances in
                cryptography and to incorporate additional security measures
                necessary to address risks presented from technological
                advancements, but that intention does not guarantee or otherwise
                ensure full security of the Services.
              </li>
              <li>
                10.4. You understand that the Ethereum blockchain remains under
                development, which creates technological and security risks when
                using the Services in addition to uncertainty relating to
                Digital Assets and transactions therein. You acknowledge that
                the cost of transacting on the Ethereum blockchain is variable
                and may increase at any time causing impact to any activities
                taking place on the Ethereum blockchain, which may result in
                price fluctuations or increased costs when using the Services.
              </li>
              <li>
                10.5. You acknowledge that the Services are subject to flaws and
                that you are solely responsible for evaluating any code provided
                relating to the Services. This warning and other warnings that
                Vest provides in these Terms are in no way evidence or represent
                an on-going duty to alert you to all of the potential risks of
                utilizing the Services.
              </li>
              <li>
                10.6. Although we intend to provide accurate and timely
                information and data on the Site and during your use of the
                Services, the Site and other information available when using
                the Services may not always be entirely accurate, complete, or
                current and may also include technical inaccuracies or
                typographical errors. To continue to provide you with as
                complete and accurate information as possible, information may
                be changed or updated from time to time without notice,
                including information regarding our policies. Accordingly, you
                acknowledge and understand that you should verify all
                information before relying on it, and all decisions based on
                information contained on the Site or as part of the Services are
                your sole responsibility. No representation is made as to the
                accuracy, completeness, or appropriateness for any particular
                purpose of any pricing information distributed via the Site or
                otherwise when using the Services. Prices and pricing
                information may be higher or lower than prices available on
                platforms providing similar services.
              </li>
              <li>
                10.7. Any use or interaction with the Services requires a
                comprehensive understanding of applied cryptography and computer
                science to appreciate the inherent risks, including those listed
                above. You represent and warrant that you possess relevant
                knowledge and skills. Any reference to a type of Digital Asset
                on the Site or otherwise during the use of the Services does not
                indicate our approval or disapproval of the technology on which
                the Digital Asset relies, and should not be used as a substitute
                for your understanding of the risks specific to each type of
                Digital Asset.
              </li>
              <li>
                10.8. Use of the Services in particular for entering into
                Perpetual Contracts may carry financial risk. Digital Assets,
                especially in connection with Perpetual Contracts, are, by their
                nature, highly experimental, risky, and volatile. Transactions
                entered into in connection with the Services are irreversible,
                final and there are no refunds. You acknowledge and agree that
                you will access and use the Services at your own risk. The risk
                of loss in trading Digital Assets, especially entering into
                Perpetual Contracts, can be substantial. You should, therefore,
                carefully consider whether such trading is suitable for you in
                light of your circumstances and financial resources. By using
                the Services, you represent and warrant that you have been, are,
                and will be solely responsible for making your independent
                appraisal and investigations into the risks of a given
                transaction and the underlying Digital Assets, including
                Perpetual Contracts. You represent that you have sufficient
                knowledge, market sophistication, professional advice, and
                experience to make your evaluation of the merits and risks of
                any transaction conducted in connection with the Services or any
                Digital Asset. You accept all consequences of using the
                Services, including the risk that you may lose access to your
                Digital Assets indefinitely. All transaction decisions are made
                solely by you. Notwithstanding anything in these Terms, we
                accept no responsibility whatsoever for, and will in no
                circumstances be liable to you for any loss or injury sustained
                by you or any third parties in connection with, your use of the
                Services for performing Digital Asset transactions, including
                entering into Perpetual Contracts.
              </li>
              <li>
                10.9. We must comply with Applicable Law, which may require us
                to, upon request by government agencies, take certain actions or
                provide information. You acknowledge and understand that Vest
                may in its sole discretion take any action it deems appropriate
                to cooperate with government agencies or comply with Applicable
                Law.
              </li>
              <li>
                10.10. You understand that the ZkSync Service remains under
                development, which creates technological, trading, and other
                risks when using the Services. These risks include, among
                others, delays in trades, withdrawals, and deposits resulting
                from the servers of Vest or the operator of the ZkSync Services
                being offline; an incorrect display of information on the Site
                in the case of server errors; or transactions using the Services
                being rolled back in the case of server errors. You acknowledge
                that these risks may have a material impact on your transactions
                using the Services, which may result in, among other things,
                failing to fulfill transactions at your desired price or at all.
              </li>
              <li>
                10.11. You understand that you are responsible for all trades
                you place, including any erroneous orders that may be filled. We
                do not take any action to resolve erroneous trades that result
                from your errors.
              </li>
              <li>
                10.12. You hereby assume the risks set forth in this Section 10
                and Section 2, and acknowledge and agree that Vest will have no
                responsibility or liability for the risks set forth in this
                Section 10. You hereby irrevocably waive, release and discharge
                all claims, whether known or unknown to you, against Vest and
                our shareholders, members, directors, officers, employees,
                agents, and representatives, suppliers, and contractors
                (“Representatives”) related to any of the risks set forth in
                this Section 10 and Section 2.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">11. INDEMNIFICATION</h2>
          <p className="pl-3">
            You will defend, indemnify, and hold harmless Vest and our
            Representatives (collectively, “Indemnified Parties”) from any
            claim, demand, lawsuit, action, proceeding, investigation,
            liability, damage, loss, cost or expense, including reasonable
            attorneys’ fees, arising out of or relating to (a) your use of, or
            conduct in connection with the Services; (b) Digital Assets
            associated with your Ethereum address; (c) any feedback or user
            content you provide to Vest, if any, concerning the Services; (d)
            your violation of these Terms; or (e) your infringement or
            misappropriation of the rights of any other person or entity. If you
            are obligated to indemnify any Indemnified Party, Vest (or, at our
            sole discretion, the applicable Indemnified Party) will have the
            right, in our or its sole discretion, to control any action or
            proceeding and to determine whether Vest wishes to settle, and if
            so, on what terms, and you agree to cooperate with Vest in the
            defense.
          </p>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">
            12. DISCLOSURES; DISCLAIMERS
          </h2>
          <div className="pl-3">
            <ul className="list-disc pl-5">
              <li>
                12.1 Vest is a developer of software. Vest does not operate a
                Digital Asset or derivatives exchange platform or offer trade
                execution or clearing services and has no oversight,
                involvement, or control concerning your transactions using the
                Services. All transactions between users of the Interface are
                executed peer-to-peer directly between the users’ Ethereum
                addresses through a smart contract. You are responsible for
                complying with all Applicable Laws that govern your Digital
                Assets. As a result of restrictions under the Commodity Exchange
                Act and the regulations promulgated thereunder by the U.S.
                Commodity Futures Trading Commission (“CFTC”), no Blocked Person
                may enter into Perpetual Contracts using the Services.
              </li>
              <li>
                12.2 You understand that Vest is not registered or licensed by
                any regulatory agency or authority. No such agency or authority
                has reviewed or approved the use of the Services.
              </li>
              <li>
                12.3 To the maximum extent permitted under Applicable Law, the
                Services (and any of their content or functionality) provided by
                or on behalf of us are provided on an “AS IS” and “AS AVAILABLE”
                basis, and we expressly disclaim, and you hereby waive, any
                representations, conditions or warranties of any kind, whether
                express or implied, legal, statutory or otherwise, or arising
                from statute, otherwise in law, course of dealing, or usage of
                trade, including the implied or legal warranties and conditions
                of merchantability, merchantable quality, quality or fitness for
                a particular purpose, title, security, availability,
                reliability, accuracy, quiet enjoyment and non-infringement of
                third party rights. Without limiting the foregoing, we do not
                represent or warrant that the Services (including any data
                relating thereto) will be uninterrupted, available at any
                particular time, or error-free. Further, we do not warrant that
                errors in the Services are correctable or will be correctable.
              </li>
              <li>
                12.4 You acknowledge that data you provide while accessing or
                using the Services may become irretrievably lost or corrupted or
                temporarily unavailable due to a variety of causes, and agree
                that, to the maximum extent permitted under Applicable Law, we
                will not be liable for any loss or damage caused by
                denial-of-service attacks, software failures, viruses or other
                technologically harmful materials (including those which may
                infect your computer equipment), protocol changes by third-party
                providers, Internet outages, force majeure events or other
                disasters, scheduled or unscheduled maintenance, or other causes
                either within or outside of our control.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">
            13. LIMITATION OF DAMAGES; EXCLUSION OF CONSEQUENTIAL AND RELATED
            DAMAGES
          </h2>
          <div className="pl-3">
            <ul className="list-disc pl-5">
              <li>
                13.1 Without limitation of any other provision of these Terms,
                you hereby agree that neither Vest nor any of its
                Representatives will have any responsibility or liability
                whatsoever for any loss or injury sustained by you or any third
                parties as a result of (i) any equipment or technology or other
                infrastructure delay, inaccessibility, or malfunctions;
                (ii)periodic maintenance procedures or repairs that Vest or any
                of our suppliers or contractors may undertake from time to time;
                (iii) causes beyond Vest’s control or that Vest could not
                reasonably foresee; (iv) disruptions and temporary or permanent
                unavailability of underlying blockchain infrastructure; or (v)
                unavailability of third-party service providers or external
                partners for any reason. Under no circumstances will Vest or its
                Representatives have any liability for any such loss or injury
                caused by any of the foregoing events, including but not limited
                to any obligation to cover or reimburse any damages or losses
                caused by such events. You expressly acknowledge that any risk
                of loss resulting from such events shall be borne by you, and
                you expressly assume any and all such risks.
              </li>
              <li>
                13.2 In no event will Vest, our suppliers and contractors, and
                Vest’s or our suppliers’ and contractors’ respective
                stockholders, members, directors, officers, managers, employees,
                attorneys, agents, representatives, suppliers and contractors
                (collectively, the “Risk Limited Parties”) be liable for any
                incidental, indirect, special, punitive, consequential or
                similar damages or liabilities whatsoever (including damages for
                loss of fiat, assets, data, information, revenue, opportunities,
                use, goodwill, profits or other business or financial benefit)
                arising out of or in connection with the Services (and any of
                their content and functionality), any execution or settlement of
                a transaction, any performance or non-performance of the
                Services, your Digital Assets, Perpetual Contracts or any other
                product, service or other item provided by or on behalf of Vest,
                whether under contract, tort (including negligence), civil
                liability statute strict liability breach of warranties or under
                any other theory of liability and whether or not we have been
                advised of, knew of or should have known of the possibility of
                such damages and, notwithstanding any failure of the essential
                purpose of these Terms or any limited remedy hereunder, nor is
                Vest in any way responsible for the execution or settlement of
                transactions between users of the Services.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">
            14. LIMITATION OF LIABILITY
          </h2>
          <p className="pl-3">
            Without limitation of any provision of these Terms, in the event
            that Vest or any related party is found liable under these Terms,
            the aggregate liability of Vest (together with our equity owners,
            members, directors, managers, officers, employees, attorneys,
            agents, representatives, suppliers, or contractors), arising out of
            or in connection with your use of the Services (and any of their
            content and functionality), any performance or nonperformance of the
            Services, your Digital Assets, Perpetual Contracts or any other
            product, service or other item provided by or on behalf of Vest,
            whether under contract, tort (including negligence), civil
            liability, statute, strict liability or other theory of liability,
            will not exceed the amount of fees paid by you to Vest under these
            Terms, if any, in the twelve (12) month period immediately preceding
            the event giving rise to the claim for liability.
          </p>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">
            15. DISPUTE RESOLUTION AND ARBITRATION
          </h2>
          <div className="mb-3 flex pl-3">
            {/* <div className="mr-3 min-w-[24px]">
            <WarningIcon className="text-vestred-a w-full" />
          </div> */}
            <p>
              PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT MAY
              SIGNIFICANTLY IMPACT YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO
              BRING A LAWSUIT AGAINST Vest IN ANY COURT OR GOVERNING AUTHORITY.
              EXCEPT AS EXPRESSLY PROVIDED BELOW, THIS SECTION REQUIRES YOU TO
              SUBMIT ANY DISPUTE, CLAIM, OR DISAGREEMENT (EACH A “DISPUTE”)
              ARISING OUT OF THESE TERMS OR THE SERVICES, INCLUDING ANY DISPUTE
              THAT AROSE BEFORE THE EFFECTIVE DATES OF THESE TERMS, TO BINDING
              INDIVIDUAL ARBITRATION. THIS SECTION EXTENDS TO DISPUTES THAT
              AROSE OR INVOLVE FACTS OCCURING BEFORE THE EXISTENCE OF THIS OR
              ANY PRIOR VERSIONS OF THE TERMS AS WELL AS DISPUTES THAT MAY ARISE
              AFTER THE TERMINATION OF THE TERMS.
            </p>
          </div>
          <p className="pl-3">
            You and Vest agree that any Dispute arising out of or related to
            these Terms or the Services is personal to you and Vest and that any
            Dispute will be resolved solely through individual arbitration, and
            will not be brought as a class arbitration, class action, or any
            other type of representative proceeding. <br />
            Nothing in this Section precludes you or Vest from bringing an
            individual action for damages in a small claims court if such claims
            qualify and remain in small claims court, or from seeking injunctive
            or other equitable relief in court for infringement or other alleged
            misuse of intellectual property rights (such as trademarks, trade
            dress, domain names, trade secrets, copyrights, and patents).
            <br />
            Except as specified above, you and Vest waive your rights to a judge
            or jury trial or to have any Dispute arising out of or related to
            these Terms or the Services resolved in court. Instead, for any
            Dispute that you have against Vest or relating in any way to the
            Services, you agree to first contact Vest and attempt to resolve the
            claim informally by sending a written notice of your claim
            (“Notice”) to Vest by email at team@vest.exchange. The Notice must
            (a) include your name, residence address, email address, and
            telephone number; (b) describe the nature and basis of the claim;
            and (c) set forth the specific relief sought. Our notice to you will
            be similar in form to that described above. If you and Vest cannot
            reach an agreement to resolve the claim within thirty (30) days
            after such Notice is received, then either party may submit the
            Dispute to binding arbitration administered by JAMS or, under the
            limited circumstances set forth above, in court. All Disputes
            submitted to JAMS will be resolved through confidential, binding
            arbitration before one arbitrator. Arbitration proceedings will be
            held in New York, New York, under the JAMS Streamlined Arbitration
            Rules and Procedures (“JAMS Rules”). The most recent version of the
            JAMS Rules are available on the JAMS website and are hereby
            incorporated by reference. You either acknowledge and agree that you
            have read and understand the JAMS Rules or waive your opportunity to
            read the JAMS Rules and waive any claim that the JAMS Rules are
            unfair or should not apply for any reason. <br />
            You and Vest agree that these Terms affect interstate commerce and
            that the enforceability of this Section 15 will be substantively and
            procedurally governed by the Federal Arbitration Act, 9 U.S.C. § 1,
            et seq. (the “FAA”), to the maximum extent permitted by applicable
            law. As limited by the FAA, these Terms and the JAMS Rules, the
            arbitrator will have exclusive authority to make all procedural and
            substantive decisions regarding any dispute and to grant any remedy
            that would otherwise be available in court, including the power to
            determine the question of arbitrability. The arbitrator may conduct
            only an individual arbitration and may not consolidate more than one
            individual’s claims, preside over any type of class or
            representative proceeding or preside over any proceeding involving
            more than one individual. <br />
            The arbitrator, Vest, and you will maintain the confidentiality of
            any arbitration proceedings, judgments and awards, including all
            information gathered, prepared, and presented for purposes of the
            arbitration or related to the dispute(s) therein. The arbitrator
            will have the authority to make appropriate rulings to safeguard
            confidentiality unless the law provides to the contrary. The duty of
            confidentiality does not apply to the extent that disclosure is
            necessary to prepare for or conduct the arbitration hearing on the
            merits, in connection with a court application for a preliminary
            remedy or in connection with a judicial challenge to an arbitration
            award or its enforcement, or to the extent that disclosure is
            otherwise required by law or judicial decision. <br />
            You and Vest agree that for any arbitration you initiate, you will
            pay the filing fee and all other JAMS fees and costs. For any
            arbitration initiated by Vest, Vest will pay all JAMS fees and
            costs. You and Vest agree that the state or federal courts of the
            State of New York and the United States sitting in New York, New
            York have exclusive jurisdiction over the enforcement of an
            arbitration award. <br />
            Any claim arising out of or related to these Terms or the Services
            must be filed within one (1) year after such claim arose; otherwise,
            the claim is permanently barred, which means that you and Vest will
            not have the right to assert the claim. <br />
            If any portion of this Section 15 is found to be unenforceable or
            unlawful for any reason, (a) the unenforceable or unlawful provision
            will be severed from these Terms; (b) severance of the unenforceable
            or unlawful provision will have no impact whatsoever on the
            remainder of this Section 15 or the parties’ ability to compel
            arbitration of any remaining claims on an individual basis under
            this Section 15; and (c) to the extent that any claims must
            therefore proceed on a class, collective, consolidated, or
            representative basis, such claims must be litigated in a civil court
            of competent jurisdiction and not in arbitration, and the parties
            agree that litigation of those claims will be stayed pending the
            outcome of any individual claims in arbitration. Further, if any
            part of this Section 15 is found to prohibit an individual claim
            seeking public injunctive relief, then that provision will have no
            effect to the extent such relief is allowed to be sought out of
            arbitration, and the remainder of this Section 15 will be
            enforceable. <br />
          </p>
        </div>

        <div>
          <h2 className="mb-3 text-base font-medium">16. GOVERNING LAW</h2>
          <p className="pl-3">
            The interpretation and enforcement of these Terms, and any Dispute
            related to these Terms or the Services, will be governed by and
            construed and enforced under the laws of the State of New York, as
            applicable, without regard to conflict of law rules or principles
            (whether of the State of New York or any other jurisdiction) that
            would cause the application of the laws of any other jurisdiction.
            You agree that we may initiate a proceeding related to the
            enforcement or validity of our intellectual property rights in any
            court having jurisdiction. For any other proceeding that is not
            subject to arbitration under these Terms, the state and federal
            courts located in New York, New York will have exclusive
            jurisdiction. You waive any objection to venue in any such courts.
          </p>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">
            17. GENERAL INFORMATION
          </h2>
          <div className="pl-3">
            <ul className="list-disc pl-5">
              <li>
                17.1. Please refer to our privacy policy, which is incorporated
                herein by reference and available at{' '}
                <a
                  className="text-base font-bold underline"
                  href={window.location.origin + '/documentation/privacy/'}
                >
                  {window.location.origin + '/documentation/privacy/'}
                </a>
                , for information about how we collect, use, share and otherwise
                process information about you.
              </li>
              <li>
                17.2. You consent to receive all communications, agreements,
                documents, receipts, notices, and disclosures electronically
                (collectively, our “Communications”) that we provide in
                connection with these Terms or any Services. You agree that we
                may provide our Communications to you by posting them on the
                Site, by emailing them to you at the email address you provide
                in connection with using the Services, if any, or by Telegram at
                the username you provided to us during the course of your use of
                the Services. You should maintain copies of our Communications
                by printing a paper copy or saving an electronic copy. You may
                also contact us with questions, complaints, or claims concerning
                the Services at legal@Vest.exchange.
              </li>
              <li>
                17.3. Any right or remedy of Vest set forth in these Terms is in
                addition to, and not in lieu of, any other right or remedy
                whether described in these Terms, under Applicable Law, at law,
                or in equity. The failure or delay of Vest in exercising any
                right, power, or privilege under these Terms will not operate as
                a waiver thereof.
              </li>
              <li>
                17.4. The invalidity or unenforceability of any of these Terms
                will not affect the validity or enforceability of any other of
                these Terms, all of which will remain in full force and effect.
              </li>
              <li>
                17.5. We will have no responsibility or liability for any
                failure or delay in performance of any of the Services, or any
                loss or damage that you may incur, due to any circumstance or
                event beyond our control, including any flood, extraordinary
                weather conditions, earthquake, or other act of God, fire, war,
                insurrection, riot, labor dispute, accident, action of
                government, communications, power failure, or equipment or
                software malfunction.
              </li>
              <li>
                17.6. You may not assign or transfer any right to use the
                Services, or any of your rights or obligations under these
                Terms, without our express prior written consent, including by
                operation of law or in connection with any change of control. We
                may assign or transfer any or all of our rights or obligations
                under these Terms, in whole or in part, with or without notice
                or obtaining your consent or approval.
              </li>
              <li>
                17.7. Except to the extent otherwise provided or unless the
                context otherwise requires, for the purposes of these Terms: (a)
                headings of sections are for convenience only and will not be
                used to limit or construe such sections; (b) whenever the words
                “include,” “includes” or “including” are used in these Terms,
                they are deemed to be followed by the words “without
                limitation”; and (c) the use of “or” is not intended to be
                exclusive.
              </li>
              <li>
                17.8. These Terms contain the entire agreement between you and
                Vest, and supersede all prior and contemporaneous understandings
                between the parties with respect to the Services.
              </li>
              <li>
                17.9. In the event of any conflict between these Terms and any
                other agreement you may have with us, these Terms will control
                unless such other agreement specifically identifies these Terms
                and declares that such other agreement supersedes these Terms.
              </li>
              <li>
                17.10. You agree that, except as otherwise expressly provided in
                this Agreement, there is no third-party beneficiaries to the
                Agreement other than the Indemnified Parties.
              </li>
            </ul>
          </div>
        </div>

        <div>
          <h2 className="mb-4 text-base font-medium">18. Points System</h2>
          <div className="pl-3">
            <p className="mb-3">
              Vest operates a points system (“Points System”). The Points System
              is applicable to all users of the Site.
            </p>
            <ul className="list-disc pl-5">
              <li>
                18.1. The Points System is a mechanism that allows you to
                accumulate points (“Points”) based on your usage and interaction
                with the App and services provided therein. The number of Points
                earned may vary depending on the nature of your interaction with
                the App.
              </li>
              <li>
                18.2. We reserve the right to determine, in our sole discretion,
                the number of Points awarded for any given interaction.
              </li>
              <li>
                18.3. We expressly reserve the right, in our sole and absolute
                discretion, to modify, alter, or change the calculation or
                valuation of Points at any time and for any reason without prior
                notice.
              </li>
              <li>
                18.4. Such modifications, alterations, or changes may include,
                but are not limited to, increasing or decreasing the number of
                Points awarded for a specific interaction, changing the
                activities for which Points may be awarded, or imposing limits
                on the accumulation or redemption of Points.
              </li>
              <li>
                18.5. We do not guarantee the availability of any particular
                redemption level or reward. The amount of Points required to
                redeem any reward may be substantially increased, any reward may
                be withdrawn, and restrictions on any reward or its redemption
                may be imposed at any time.
              </li>
              <li>
                18.6. Points have no cash value, are not exchangeable for cash,
                and are not transferable. The sale, barter, or other disposal of
                Points, other than by us, is expressly prohibited.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
