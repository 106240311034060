import { default as dayjs } from 'dayjs';

export const formatDate = (date: number) =>
  dayjs(date).format('MMMM D, YYYY h:mm A');

/**
 * Truncate a blockchain address to a specified format.
 * @param {string} address - The blockchain address to truncate.
 * @param {number} startChars - The number of characters to show before the two periods. Defaults to 4.
 * @param {number} endChars - The number of characters to show after the two periods. Defaults to 4.
 * @returns {string} The truncated address.
 */
export const truncateAddress = (address: string, startChars: number = 4, endChars: number = 4): string => {
  if (address.length <= startChars + endChars) {
    return address;
  }
  const start = address.slice(0, startChars);
  const end = address.slice(-endChars);
  return `${start}..${end}`;
};
