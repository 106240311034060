import { useState, useEffect } from 'react';
import { fetchTime } from '@/lib/api/get-time';

export const useAccurateTimeCheck = () => {
  const [isAccurate, setIsAccurate] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const checkAccurateTime = async () => {
      try {
        const accurateTime = await fetchTime();

        setIsAccurate(Math.abs(accurateTime - Date.now()) < 5_000);
        setLoading(false);
        return;
      } catch (err) {
        console.error('Time fetch failed:', err);
      }
      setError('Failed to fetch time from all sources');
      setLoading(false);
    };

    checkAccurateTime();
  }, []);

  return { loading, error, isAccurate };
};
