import useReferrals from '@/features/referrals/api/get-referrals';
import ReferralStats from '@/features/referrals/components/stats';
import ReferralPositionsTable from '@/features/referrals/components/table';

export default function ReferralsPage() {
  return (
    <div className="flex h-full flex-col">
      <div className="grid h-40 grid-cols-5 items-center border-b border-border bg-background">
        <h1 className="pl-8 text-2xl font-semibold sm:text-3xl lg:text-4xl">
          Referrals
        </h1>
        <div className="col-span-3 flex flex-col gap-2 px-8">
          <p className="font-medium text-vestgrey-100">
            Claim referral code. Refer traders. Earn rewards
          </p>
          <p className="max-w-7xl text-vestgrey-100">
            1. Claim: Claim your personalized referral code from the dashboard.
            <br />
            2. Track: Track metrics like referred trader volumes and fees using
            the dashboard.
            <br />
            3. Earn: Rewards are distributed on a weekly basis.
            <br />
          </p>
        </div>
        <div className="pr-16">
          {/* <Button className="h-12 w-full rounded-none bg-white font-mono text-lg font-normal uppercase">
            Connect X
          </Button> */}
          {/* <div className="grid grid-cols-2">
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Estimated APY
              </div>
              <div
                className={cn(
                  'text-font flex flex-1 items-center justify-start text-xl font-bold',
                )}
              >
                -
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                TVL
              </div>
              <div
                className={cn(
                  'text-font flex flex-1 items-center justify-start text-xl font-bold',
                )}
              >
                {formatNumber(exchangeInfo.exchange.lp, {
                  digits: 2,
                  compact: true,
                  round: 'ceil',
                })}
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ReferralStats />
      <div className="relative flex-1">
        <div className="absolute inset-0 h-full w-full">
          <ReferralPositionsTable />
        </div>
      </div>
    </div>
  );
}
