import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { OrdersHistoryTableContainer } from '@/features/account/components';
import { OpenOrdersTableContainer } from '@/features/account/components/open-orders-table';
import { TransfersTableContainer } from '@/features/account/components/transfers-table';
import usePoints from '@/features/portfolio/api/get-points';
import PortfolioChart from '@/features/portfolio/components/chart';
import PortfolioStats from '@/features/portfolio/components/stats';
import { PositionsTableContainer } from '@/features/trade/components';
import { cn } from '@/lib/utils';
import { OrderHistoryFilterType, TableTabType } from '@/types/enums';
import useGeofenceCheck from '@/utils/geofence-check';
import { useState } from 'react';

export default function PortfolioPage() {
  const [tableTab, setTableTab] = useState<TableTabType>(
    TableTabType.POSITIONS,
  );
  const [orderHistoryFilter, setOrderHistoryFilter] =
    useState<OrderHistoryFilterType>(OrderHistoryFilterType.ALL);
  const { data: points } = usePoints();
  const { data: isSanctioned } = useGeofenceCheck();

  return (
    <div>
      <div
        className={cn(
          isSanctioned ? 'h-[calc(100vh-225px)]' : 'h-[calc(100vh-60px)]',
        )}
      >
        <div className="grid grid-cols-4 grid-rows-[max-content_1fr]">
          <PortfolioStats points={points} />
          <PortfolioChart />
          <Tabs
            value={tableTab}
            onValueChange={(value) => setTableTab(value as TableTabType)}
            defaultValue="positions"
            className="col-span-full w-full"
          >
            {/* to make right side of tabs foreground div */}
            <div className="flex">
              <TabsList>
                <TabsTrigger className="h-16" value="positions">
                  POSITIONS
                </TabsTrigger>
                <TabsTrigger className="h-16" value="open-orders">
                  OPEN ORDERS
                </TabsTrigger>
                <TabsTrigger className="h-16" value="order-history">
                  ORDER HISTORY
                </TabsTrigger>
                <TabsTrigger className="h-16" value="transfers">
                  TRANSFERS
                </TabsTrigger>
              </TabsList>
              {/* to make right side of tabs foreground div */}
              <div className="flex w-full items-center justify-end gap-4 border-y border-border bg-background">
                {tableTab === TableTabType.ORDER_HISTORY && (
                  <Select
                    onValueChange={(value) =>
                      setOrderHistoryFilter(value as OrderHistoryFilterType)
                    }
                  >
                    <SelectTrigger
                      id="filter"
                      className="h-full max-w-32 rounded-none border-y-0 border-r-0"
                    >
                      <SelectValue placeholder="Filter" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={OrderHistoryFilterType.ALL}>
                        All
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.FILLED}>
                        Filled
                      </SelectItem>
                      <SelectItem value={OrderHistoryFilterType.REJECTED}>
                        Rejected
                      </SelectItem>
                    </SelectContent>
                  </Select>
                )}
              </div>
            </div>
            {/* TODO: table height fixed for now to make it 600 like div */}

            <TabsContent value="positions">
              <div className="h-[calc(100vh-410px)]">
                <PositionsTableContainer />
              </div>
            </TabsContent>
            <TabsContent value="open-orders">
              <div className="h-[calc(100vh-410px)]">
                <OpenOrdersTableContainer />
              </div>
            </TabsContent>
            <TabsContent value="order-history">
              <div className="h-[calc(100vh-410px)]">
                <OrdersHistoryTableContainer filter={orderHistoryFilter} />
              </div>
            </TabsContent>
            <TabsContent value="transfers">
              <div className="h-[calc(100vh-410px)]">
                <TransfersTableContainer />
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
