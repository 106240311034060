import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Table } from '@/components/ui/';
import { createColumnHelper } from '@tanstack/react-table';
import { PositionRow } from './position-row';
import { useMemo } from 'react';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useFetchAccount } from '../api/get-account';
import {
  ErrorCode,
  ErrorMsgs,
  OrderStatus,
  OrderType,
  TransferType,
} from '@/types/enums';
import { useTransfers } from '../api/get-transfers';
import { useTransfersStore } from '@/store/use-transfers-store';
import {
  formatBigInt,
  formatDate,
  formatOrderStatus,
  formatTransferType,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { chains } from '@/lib/wagmi/config';
import { Chain } from '@rainbow-me/rainbowkit';

type TransferColumn = {
  id: string;
  postTime: number;
  transferType: TransferType;
  size?: bigint;
  status: OrderStatus;
  code?: ErrorCode;
  chain: Chain;
};

const columnHelper = createColumnHelper<TransferColumn>();

export const TransfersTableContainer = () => {
  const { isPending: isExchangeInfoPending, isError: isExchangeInfoError } =
    useExchangeInfo({});
  const { isPending: isAccountPending, isError: isAccountError } =
    useFetchAccount({});

  if (isExchangeInfoPending) {
    return <></>;
  }

  if (isExchangeInfoError) {
    return <div>Error</div>;
  }

  return <TransfersTable />;
};

const TransfersTable = () => {
  const { isPending, isError } = useTransfers();

  const { transfers } = useTransfersStore();

  const data: TransferColumn[] = useMemo(() => {
    return Object.values(transfers)
      .filter((transfer) => chains[transfer.chainId] !== undefined)
      .map(
        (transfer) =>
          ({
            id: transfer.id,
            postTime: transfer.postTime,
            transferType: transfer.orderType,
            size:
              transfer.status === OrderStatus.REJECTED
                ? undefined
                : transfer.size,
            status: transfer.status,
            code:
              transfer.status === OrderStatus.REJECTED
                ? transfer.code
                : undefined,
            chain: chains[transfer.chainId],
          }) as TransferColumn,
      )
      .sort((a, b) => Number(b.postTime - a.postTime));
  }, [transfers]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('postTime', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            {formatDate(new Date(info.getValue()), {
              showTime: true,
              showSeconds: true,
            })}
          </div>
        ),
        header: () => 'TIME',
        meta: { width: 'w-[12.5%]' },
      }),
      columnHelper.accessor('chain', {
        cell: (info) => {
          const chain = info.getValue();
          return (
            <div>
              {chain.iconUrl && chain.iconBackground && (
                <div
                  style={{
                    background: chain.iconBackground,
                    width: 12,
                    height: 12,
                    borderRadius: 999,
                    overflow: 'hidden',
                    marginRight: 4,
                  }}
                >
                  <img
                    alt={chain.name}
                    src={chain.iconUrl as string}
                    style={{ width: 12, height: 12 }}
                  />
                </div>
              )}
              <span>{chain.name}</span>
            </div>
          );
        },
        header: () => <span>CHAIN</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('transferType', {
        cell: (info) => (
          <span>
            {info.getValue() ? formatTransferType(info.getValue()) : '-'}
          </span>
        ),
        header: () => <span>TYPE</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('size', {
        cell: (info) =>
          formatBigInt(info.getValue(), COLLATERAL_DECIMALS, {
            digits: 2,
            round: 'ceil',
          }),
        header: () => <span>AMOUNT</span>,
        meta: { width: 'w-[10%]' },
      }),
      columnHelper.accessor('status', {
        cell: (info) => (
          <div className="flex items-center gap-4">
            {info.getValue() ? formatOrderStatus(info.getValue()) : '-'}
            {info.getValue() === OrderStatus.REJECTED &&
              info.row.original.code && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <Info size={14} />
                    </TooltipTrigger>
                    <TooltipContent>
                      {ErrorMsgs[info.row.original.code]}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            {info.getValue() === OrderStatus.NEW &&
              info.row.original.transferType === TransferType.WITHDRAW &&
              info.row.original.size !== undefined && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                      <Info size={14} />
                    </TooltipTrigger>
                    <TooltipContent>
                      {info.row.original.size >
                      parseDecimalToBigInt(
                        '25000', // TEMP: replace with env var
                        COLLATERAL_DECIMALS,
                      )
                        ? `Please wait ${24} hours.` // TEMP: replace with env var
                        : `Please wait ${8} hours.`}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
          </div>
        ),
        header: () => <span>STATUS</span>,
        meta: { width: 'w-[10%]' },
      }),
    ],
    [],
  );

  return (
    // @ts-ignore
    <Table data={data} columns={columns} RowComponent={PositionRow} />
  );
};
