import { useMutation } from '@tanstack/react-query';
import { useAccountStore } from '@/store/use-account-store';
import { privateApi } from '@/lib/api-clients/rest-client';

type ListenKeyResponse = {
  listenKey: string;
};

const onSuccess = (res: ListenKeyResponse) => {
  const { authParams, setAuthParams } = useAccountStore.getState();
  if (!authParams) {
    console.error('No auth params found');
    return;
  }
  setAuthParams({
    ...authParams,
    listenKey: res.listenKey,
    listenKeyExpiry: Date.now() + 1000 * 60 * 59, // 59 minutes, server by default is 1 hour
  });
};

export function useListenKey() {
  return useMutation({
    mutationFn: async (): Promise<ListenKeyResponse> => {
      return privateApi.post('/account/listenKey', {});
    },
    onSuccess: onSuccess,
  });
}

export function useRefreshListenKey() {
  return useMutation({
    mutationFn: async (): Promise<ListenKeyResponse> => {
      return privateApi.put('/account/listenKey', {});
    },
    onSuccess: onSuccess,
  });
}
