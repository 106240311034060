import * as React from 'react';
import { flexRender } from '@tanstack/react-table';
import { cn } from '@/utils/cn';

interface PositionRowProps {
  row: any;
}

export const PositionRow: React.FC<PositionRowProps> = ({ row }) => {
  // const symbol = row.original.symbol;
  return (
    <tr key={row.id} className="bg-background font-mono">
      {row.getVisibleCells().map((cell: any) => (
        <td
          key={cell.id}
          className={cn(
            'border border-background px-4 py-2 text-vestgrey-50',
            // @ts-ignore
            cell.column.columnDef.meta?.width,
          )}
        >
          {flexRender(cell.column.columnDef.cell, {
            ...cell.getContext(),
            row: cell.row.original,
          })}
        </td>
      ))}
    </tr>
  );
};
