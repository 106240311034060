export default function PrivacyPolicy() {
  return (
    <div className="text-vestlightgrey-a flex flex-col gap-5 p-8 text-sm">
      <h1 className="text-center text-2xl font-bold">Privacy Policy</h1>
      <p>
        This Privacy Policy (“Policy”) of Vest provides our privacy policy
        regarding the nature, purpose, use, and sharing of personal data or
        other information collected from the users of the website{' '}
        <a
          className="text-base font-bold underline"
          href={window.location.origin}
          target="_blank"
          rel="noreferrer"
        >
          {window.location.origin}
        </a>{' '}
        and other websites which use subdomains of{' '}
        <a
          className="text-base font-bold underline"
          href={window.location.origin}
          target="_blank"
          rel="noreferrer"
        >
          {window.location.origin}
        </a>{' '}
        (the "Site"). We are committed to protecting and respecting your
        privacy. Please read this carefully as this Privacy Policy is legally
        binding when you use the Site. Any terms not defined herein shall have
        the meaning given to such terms in our Terms of Service which can be
        found at{' '}
        <a
          className="text-base font-bold underline"
          href={window.location.origin + '/documentation/terms'}
          target="_blank"
          rel="noreferrer"
        >
          {window.location.origin + '/documentation/terms'}
        </a>
        .
      </p>
      <p>
        Please read this Privacy Policy carefully to understand our policies and
        practices regarding your information and how we will treat it. IF YOU
        ARE UNWILLING TO AGREE TO THIS PRIVACY POLICY, OR YOU DO NOT HAVE THE
        RIGHT, POWER AND AUTHORITY TO ACT ON BEHALF OF AND BIND THE BUSINESS,
        ORGANIZATION, OR OTHER ENTITY YOU REPRESENT, DO NOT ACCESS OR OTHERWISE
        USE THE SITE.
      </p>

      <div>
        <h2 className="mb-2 text-base font-bold uppercase">
          Changes to this Agreement
        </h2>
        <p>
          We reserve the exclusive right to make changes to this Privacy Policy
          from time to time, in our sole discretion. Your continued access to
          and use of the Site constitutes your agreement to be bound by specific
          terms of this Privacy Policy posted at such time. You acknowledge and
          agree that you accept this Privacy Policy (and any amendments hereto)
          each time you access the Site in any manner. Therefore, we encourage
          you to review this Privacy Policy regularly as you shall be bound by
          it. If, within thirty (30) days of us posting changes or amendments to
          this Privacy Policy, you decide that you do not agree to the updated
          terms of the Privacy Policy, you may provide us with written notice of
          your withdrawal from this Privacy Policy to the email address at{' '}
          <a
            className="text-base font-bold underline"
            href="mailto:team@vest.exchange"
            target="_blank"
            rel="noreferrer"
          >
            team@vest.exchange
          </a>
          . Upon providing us with the written notice of the withdrawal of your
          acceptance to this Privacy Policy, you shall no longer be authorized
          to access or use the Site, and you must immediately cease in doing so.
        </p>
      </div>

      <div>
        <h2 className="mb-3 text-base font-bold uppercase">Eligibility</h2>
        <ul>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">Age</h3>
            <p>
              Age. By accessing our using the Site, you represent and warrant
              that you are at least eighteen (18) years of age. If you are under
              the age of eighteen (18), you may not, under any circumstances or
              for any reason, use the Site.
            </p>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">Legality</h3>
            <p>
              You are solely responsible for ensuring that this Privacy Policy
              is in compliance with all laws, rules, and regulations applicable
              to you and in your jurisdiction. If your use of the Site, or any
              functionality provided or activity enabled thereby, is prohibited
              or conflicts with any applicable law, rule or regulation, you may
              not, under any circumstances or for any reason, use the Site.
            </p>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">Criteria</h3>
            <p>
              We may, in our sole discretion, refuse to offer the Site to any
              person or entity and change the eligibility criteria for use
              thereof at any time.
            </p>
          </li>
          <li>
            <h3 className="mb-2 text-base font-bold">Applicability</h3>
            <p>
              This Privacy Policy applies to all of your interactions with us
              via the Site, and your interactions with us in connection
              therewith.
            </p>
          </li>
        </ul>
      </div>

      <div>
        <h2 className="mb-3 text-base font-bold uppercase">
          Data processing in connection with the Site
        </h2>
        <ul>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">
              Types of information we may collect
            </h3>
            <p className="mb-2">
              To the maximum extent possible, we try to collect as minimum as
              possible personal information from you.
            </p>
            <p className="mb-2">
              Personal Information we may collect (“Personal Information”)
              includes:
            </p>
            <ul className="list-disc pl-5">
              <li>
                Email address and any other your Personal Information you
                provide to us when communicating with us. Such Personal
                Information is used only for communication with you;
              </li>
              <li>
                IP address, MAC address, cookie identifiers, domain server, data
                related to usage, performance, site security, traffic patterns,
                location information, browser and device information only when
                you are using our services at our Site;
              </li>
              <li>
                We may also receive information about you from other sources,
                including through third-party services and organizations. For
                example, if you access third-party services, through the Sites,
                we may collect information from these third-party services.
              </li>
            </ul>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">
              How and why we use your personal information
            </h3>
            <p>We may use your Personal Information listed above only for:</p>
            <ul className="list-disc pl-5">
              <li>
                Our internal and operational purposes, when: ensuring security,
                identifying irregular website behavior, preventing fraudulent
                activity and improving security at all possible levels;
              </li>
              <li>
                Assessing and improving the performance of the services we
                provide through our Site;
              </li>
              <li>Analyzing the services we provide through our Site.</li>
            </ul>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">
              Public Ethereum Addresses
            </h3>
            <p>
              We use public Ethereum addresses to identify a user’s journey
              through our Interface. We group and analyze these user journeys
              collectively in order to provide adequate security for our users
              and to improve our overall App user experience.
            </p>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">Blockchain Data</h3>
            <p>
              Note that we are not responsible for your use of the Ethereum
              blockchain and your data processed in this decentralized and
              permissionless network.
            </p>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">Disclosure of Data</h3>
            <p>We may disclose any information about you:</p>
            <ul className="list-disc pl-5">
              <li>
                to the extent required by law or if we have a good-faith belief
                that such disclosure is necessary in order to comply with
                official investigations or legal proceedings initiated by
                governmental and/or law enforcement officials, or private
                parties, including but not limited to: in response to subpoenas,
                search warrants, or court orders;
              </li>
              <li>
                in connection with a merger, division, restructuring, or other
                association change; or
              </li>
              <li>
                to our subsidiaries or affiliates only if necessary for
                operational purposes.
              </li>
            </ul>
            <p>
              If we must disclose any of your Personal Information in order to
              comply with official investigations or legal proceedings initiated
              by governmental and/or law enforcement officials, we may not be
              able to ensure that such recipients of your Personal Information
              will maintain the privacy or security of your Personal
              Information.
            </p>
          </li>
          <li className="mb-3">
            <h3 className="mb-2 text-base font-bold">Data Retention Period</h3>
            <p className="mb-2">
              We will store your data, for a reasonable period based on our
              analysis of how long the specific data is reasonably required for
              legal or business purposes. When the applicable period has
              expired, we securely delete or destroy such data. Aggregated data,
              which cannot identify a device/browser (or individual) is retained
              in a similar manner.
            </p>
            <p className="mb-2">
              Sometimes business and legal requirements oblige us to retain
              certain information, for specific purposes, for an extended period
              of time. Reasons we might retain some data for longer periods of
              time include:
            </p>
            <ul className="mb-2 list-disc pl-5">
              <li>Security, fraud & abuse prevention.</li>
              <li>Financial record-keeping.</li>
              <li>Complying with legal or regulatory requirements.</li>
              <li>Ensuring the continuity of our services at our Site.</li>
            </ul>
            <p className="mb-2">
              At any time, please email us at{' '}
              <a
                className="text-base font-bold underline"
                href="mailto:legal@vest.com"
                target="_blank"
                rel="noreferrer"
              >
                legal@vest.com
              </a>{' '}
              if you would like (a) to receive a copy of your data or any
              additional information regarding the collection of your data
              (categories, type etc), (b) to make a correction to your data (c)
              to delete or remove your data (d) to transfer your data to another
              entity (subject to our commercially reasonable limitations
              regarding form, time and source) (e) to revoke your consent
              regarding agreement with this Policy or (f) to object to any
              portion of this policy or the collection of your data pursuant to
              this policy. All requests will be addressed as soon as it is
              commercially reasonable for us to do so and is at all times
              subject to any obligations we are required to comply with under
              applicable law.
            </p>
            <p>
              Our Site is not directed to collect any data from people under the
              age of 18. We do not knowingly allow anyone under 18 to submit any
              data to our Site. If you believe your child may have provided us
              with their data, please contact us as soon as possible.
            </p>
          </li>
          <li>
            <h3 className="mb-2 text-base font-bold">
              Data Integrity & Security
            </h3>
            <p className="mb-2">
              We take information privacy and security very seriously. We work
              hard to protect the Personal Information you provide us from loss,
              misuse, or unauthorized access. We utilize a variety of safeguards
              such as encryption, digital and physical access controls,
              non-disclosure agreements, and other technical and organizational
              measures to protect the Personal Information submitted to us, both
              during and post transmission.
            </p>
            <p>
              Please note that no electronic transmission, storage, or
              processing of Personal Information can be entirely secure. We
              cannot guarantee that the security measures we have in place to
              safeguard Personal Information will never be defeated or fail, or
              that those measures will always be sufficient or effective.
              Therefore, although we are committed to protecting your privacy,
              we do not promise, and you should not expect that your Personal
              Information will always remain private or secure.
            </p>
          </li>
        </ul>
      </div>

      <div>
        <h2 className="mb-2 text-base font-bold uppercase">
          Supervisory Authority Oversight
        </h2>
        <p>
          If you are a data subject whose data we process, you may also have the
          right to lodge a complaint with a data protection regulator in one or
          more of the European Union member states.
        </p>
      </div>

      <div>
        <h2 className="mb-2 text-base font-bold uppercase">Contact</h2>
        <p>
          If you have any questions regarding this policy, you may contact us by
          email to the following email address: <br />
          <a
            className="text-base font-bold underline"
            href="mailto:team@vest.exchange"
            target="_blank"
            rel="noreferrer"
          >
            team@vest.exchange
          </a>
          .
        </p>
      </div>
    </div>
  );
}
