import { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { cn } from '@/utils/cn';

interface UnitSelectProps {
  units: string[];
  onSelect: (value: string) => void;
  disabled?: boolean;
}

export const UnitSelect: React.FC<UnitSelectProps> = ({
  units,
  onSelect,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(units[0]);

  const handleSelect = (value: string) => {
    setSelected(value);
    onSelect(value);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!units.includes(selected)) {
      setSelected(units[1]); // TEMP fix to base size unit not changing when symbol changes
    }
  }, [units, selected]);

  return (
    <div className="relative w-[8ch]">
      <button
        className={`flex w-full items-center justify-between gap-2 bg-vestgrey-800 p-2 text-center font-mono ${
          disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
        }`}
        onClick={(e) => {
          setIsOpen(!isOpen);
          e.preventDefault();
        }}
        disabled={disabled}
      >
        <span className="text-vestgrey-50">{selected}</span>
        <ChevronDownIcon
          className={cn('text-vestgrey-200 transition-transform duration-300', {
            'rotate-180': isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div className="absolute right-0 top-full z-10 w-full overflow-hidden bg-vestgrey-900 shadow-lg">
          {units.map((unit) => (
            <div
              key={unit}
              className="cursor-pointer border-b border-border p-2 font-mono uppercase text-foreground last:border-b-0 hover:bg-vestgrey-800"
              onClick={() => handleSelect(unit)}
            >
              {unit}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
