import { useAccountStore } from '@/store/use-account-store';
import { TooltipProps } from 'recharts';
import useAccountValueHistory from '../api/get-account-value-history';
import { Wallet2, XCircle } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui';
import { cn } from '@/lib/utils';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { createChart, ISeriesApi, UTCTimestamp } from 'lightweight-charts';
import { formatDate, formatNumber } from '@/utils/value-format';
import triangleLoader from '@/assets/branding/triangles-loader.gif';
import { useAccount } from 'wagmi';
import { useMarketStores } from '@/store/use-markets-store';

const periods = [
  {
    label: '1D',
    offset: 86400000,
    limit: 1440,
  },
  {
    label: '1W',
    offset: 604800000,
    limit: 168,
  },
  {
    label: '1M',
    offset: 2592000000,
    limit: 31,
  },
  // {
  //   label: '6M',
  //   offset: 15552000000,
  //   limit: 53,
  // },
  // {
  //   label: '1Y',
  //   offset: 31104000000,
  //   limit: 53,
  // },
];

type AccountValue = {
  ts: string;
  total: string;
};

const formatData = (data?: AccountValue[]) =>
  Array.isArray(data)
    ? data
        .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())
        .map(({ total, ts }) => ({
          value: parseFloat(total),
          time: (new Date(ts).getTime() / 1000) as UTCTimestamp,
        }))
    : [];

export default function PortfolioChart() {
  const [period, setPeriod] = useState(periods[0]);

  const { hasAuth } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
    positions: state.account.positions,
  }));
  const { data, isLoading } = useAccountValueHistory(period);

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ReturnType<typeof createChart>>();
  const seriesRef = useRef<ISeriesApi<'Line'>>();

  useEffect(() => {
    if (!hasAuth || isLoading || !chartContainerRef.current) return;
    chartRef.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: { color: '#0f0f0f' }, // Corrected property name and provided the correct type
        textColor: '#F5F5F5',
      },
      grid: {
        vertLines: {
          color: '#222127', // Match TradingView chart grid color
        },
        horzLines: {
          color: '#222127', // Match TradingView chart grid color
        },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
      },
    });

    const formattedData = formatData(data);

    const isProfit =
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].value > formattedData[0].value;
    const lineColor = isProfit ? '#4BC2A3' : '#FF4B4B'; // Green for profit, red for loss

    seriesRef.current = chartRef.current.addLineSeries({
      color: lineColor,
      lineWidth: 2,
    });

    seriesRef.current.setData(formattedData);

    return () => chartRef.current?.remove();
  }, [hasAuth, isLoading]);

  useEffect(() => {
    if (!hasAuth || isLoading || !seriesRef.current) return;
    const formattedData = formatData(data);

    const isProfit =
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].value > formattedData[0].value;
    const lineColor = isProfit ? '#4BC2A3' : '#FF4B4B'; // Green for profit, red for loss

    seriesRef.current.applyOptions({ color: lineColor });
    seriesRef.current.setData(formattedData);
  }, [hasAuth, isLoading, data]);

  return (
    <div className="col-span-3 flex flex-col">
      {hasAuth ? (
        <>
          <div className="flex">
            <Tabs defaultValue="value">
              <TabsList>
                <TabsTrigger value="value" className="h-16 border-t-0">
                  Account Value
                </TabsTrigger>
              </TabsList>
            </Tabs>
            <div className="flex flex-1 justify-end border-b border-border px-8">
              {periods.map((p) => (
                <button
                  className={cn(
                    'w-20 font-mono',
                    period.offset === p.offset
                      ? 'font-semibold text-foreground'
                      : 'text-vestgrey-600',
                  )}
                  onClick={() => setPeriod(p)}
                  key={p.offset}
                >
                  {p.label}
                </button>
              ))}
              {/* <div className="flex w-16 items-center justify-end">
                <Calendar size={16} className="text-foreground" />
              </div> */}
            </div>
          </div>
          {isLoading ? (
            <div className="flex flex-1 flex-col items-center justify-center">
              <img src={triangleLoader} alt="" className="w-16" />
            </div>
          ) : data && data.length > 0 ? (
            <div className="flex-1">
              <div className="h-full w-full">
                <div className="h-full w-full" ref={chartContainerRef} />
                <div className="bg-vestgrey-a absolute bottom-8 z-50 h-9 w-12" />
              </div>
            </div>
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center gap-2">
              <span className="text-lg text-vestgrey-500">
                Start trading to view your history
              </span>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-1 flex-col items-center justify-center gap-2">
          <Wallet2 size={16} strokeWidth={1.5} className="text-vestgrey-500" />
          <span className="text-lg text-vestgrey-500">Connect to view</span>
        </div>
      )}
    </div>
  );
}
