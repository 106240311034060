import * as React from 'react';
import { useEffect, useState } from 'react';
import { truncateAddress } from '@/utils/format';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccountStore } from '@/store/use-account-store';
import { useAccount } from 'wagmi';
import { TransferDialog } from './transfer-dialog';
import { TransferType } from '@/types/enums';
import { AccountMenu } from './account-menu';
import { Button } from '../ui';
import RegisterDialog from './register-dialog';
import { MintingDialog } from './minting-modal';

interface IRKConnectButtonProps {}

export const RKConnectButton: React.FC<IRKConnectButtonProps> = ({}) => {
  const account = useAccount();
  const { _hasHydrated, hasAuth, authHydrated } = useAccountStore((state) => ({
    _hasHydrated: state._hasHydrated,
    hasAuth: state.hasAuth,
    authHydrated: state.authHydrated,
  }));

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  useEffect(() => {
    if (!_hasHydrated || !authHydrated || isRegisterModalOpen) return;

    if (account.status === 'connected' && !hasAuth) {
      setIsRegisterModalOpen(true);
    }
  }, [account, hasAuth, authHydrated, _hasHydrated, isRegisterModalOpen]);

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const menuOptions = (openAccountModal: () => void) => [
    {
      label: 'VIEW PROFILE',
      action: () => {
        openAccountModal();
      },
    },
  ];

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <div
            className="grid w-full"
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div className="flex w-full items-center justify-between">
                    <Button
                      className="ml-auto h-14 w-full rounded-none font-mono text-base font-normal uppercase tracking-wider"
                      onClick={openConnectModal}
                    >
                      CONNECT YOUR WALLET
                    </Button>
                  </div>
                );
              }

              return (
                <div className="flex w-full justify-between gap-4">
                  <div className="flex gap-2">
                    <span className="self-center text-vestgrey-200">
                      WELCOME
                    </span>
                    <AccountMenu options={menuOptions(openAccountModal)}>
                      <span className="flex items-center text-primary">
                        {truncateAddress(account.address, 6)}
                      </span>
                    </AccountMenu>
                  </div>
                  <div className="flex items-center gap-4">
                    {import.meta.env.VITE_ENV === 'development' && (
                      <MintingDialog />
                    )}
                    <TransferDialog orderType={TransferType.DEPOSIT} />
                    <TransferDialog
                      orderType={TransferType.WITHDRAW}
                      isOpen={isWithdrawModalOpen}
                      onOpenChange={setIsWithdrawModalOpen}
                    />
                    <RegisterDialog
                      open={isRegisterModalOpen}
                      setOpen={setIsRegisterModalOpen}
                      openAccountModal={openAccountModal}
                    />
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
