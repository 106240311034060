import { useFetchAccount } from '@/features/account/api/get-account';
import { cn } from '@/lib/utils';
import { LPOrderType, OrderStatus } from '@/types/enums';
import {
  formatBigInt,
  formatNumber,
  parseDecimalToBigInt,
} from '@/utils/value-format';
import { useLpOrders } from '../api/get-lp-orders';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';
import { useMemo } from 'react';

export default function LPStats() {
  const { data: account } = useFetchAccount();

  const { data: lpOrders } = useLpOrders({
    orderType: LPOrderType.SCHEDULE_WITHDRAW,
    status: OrderStatus.NEW,
  });

  const pendingWithdrawal: bigint | undefined = useMemo(() => {
    return lpOrders?.reduce(
      (acc, order) =>
        acc + parseDecimalToBigInt(order.size, COLLATERAL_DECIMALS),
      0n,
    );
  }, [lpOrders]);

  return (
    <div className="grid grid-cols-2 border-r border-border">
      <div className="col-span-2 border-b border-border p-8">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Current Balance
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-3xl font-bold">
            {formatNumber(account?.lp.balance, { digits: 2 })}
          </div>
        </div>
      </div>
      <div className="border-b border-r border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Total Unrealized Pnl
          </div>
          <div
            className={cn(
              'text-font flex flex-1 items-center justify-start text-xl font-bold',

              account
                ? Number(account.lp.unrealizedPnl) >= 0
                  ? 'text-green'
                  : 'text-red'
                : undefined,
            )}
          >
            {formatNumber(account?.lp.unrealizedPnl, {
              digits: 2,
              showChange: true,
            })}
          </div>
        </div>
      </div>
      <div className="border-b border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Pending Withdrawal
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-xl font-bold">
            {formatBigInt(pendingWithdrawal, COLLATERAL_DECIMALS, {
              digits: 2,
            })}
          </div>
        </div>
      </div>
      <div className="border-b border-r border-border px-8 py-6">
        <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Past Month APR
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-xl font-bold text-vestgrey-600">
            -
          </div>
        </div>
      </div>
      <div className="border-b border-border px-8 py-6">
        {/* <div className="flex flex-col gap-2">
          <div className="flex flex-1 items-center justify-start font-mono text-sm uppercase tracking-wider text-vestgrey-600">
            Pending Withdrawal
          </div>
          <div className="text-font flex flex-1 items-center justify-start text-xl font-bold text-green">
            0.00
          </div>
        </div> */}
      </div>
    </div>
  );
}
