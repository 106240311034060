import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { useQuery } from '@tanstack/react-query';

export type PointsHistoryRecord = {
  point: number;
  ts: string;
};

export type Points = {
  point: number | null;
  rank: number | null;
  league: number | null;
  history: PointsHistoryRecord[];
};

async function getPoints(): Promise<Points> {
  return privateApi.get('/points');
}

const usePoints = () => {
  const { hasAuth } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
  }));

  return useQuery({
    enabled: hasAuth,
    queryKey: ['points'],
    queryFn: getPoints,
  });
};

export default usePoints;
