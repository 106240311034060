import { Maintenance } from './pages/maintenance';
import { AppProvider } from '@/context/provider';
import AppRouter from './router';

export const App = () => {
  const maintenance = JSON.parse(import.meta.env.VITE_MAINTENANCE || 'false');
  if (maintenance) {
    return <Maintenance />;
  }

  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
};
