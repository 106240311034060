import geofenceCheck from '@/utils/geofence-check';
import { useEffect, useState } from 'react';

export default function GeofenceBanner() {
  return (
    <div className="bg-brown flex items-center px-8 py-4">
      <p className="text-lg tracking-wider text-primary">
        <strong>
          You are accessing our service from a restricted jurisdiction.
        </strong>{' '}
        We do not allow access to our service from the United States of America
        or any other restricted jurisdiction. If you believe this is an error,
        please contact us.
      </p>
    </div>
  );
}
