import { useEffect, useState } from 'react';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { useAccountStore } from '@/store/use-account-store';
import { useWebSocketStore } from '@/store/use-websocket-store';
const WS_URL = `${import.meta.env.VITE_WS_URL as string}?version=1.0`;

/**
 * WebSocket Provider Component
 *
 * This component manages the WebSocket connection related to the business logic of the app.
 *
 * Key features:
 * - Initializes public and private WebSocket connections, exported as `publicWs` and `privateWs`
 * - Manages auth and listen key (with refresh) for private
 * - Sets up default message handlers that put updates into the store
 * - Disconnects private connection on switch wallet/disconnect
 *
 * @component
 */

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isLoading } = useExchangeInfo();

  const {
    initializePublicWs,
    initializePrivateWs,
    disconnectPublicWs,
    disconnectPrivateWs,
  } = useWebSocketStore();

  const { account, _hasHydrated, hasAuth, authParams } = useAccountStore(
    (state) => ({
      account: state.account,
      _hasHydrated: state._hasHydrated,
      hasAuth: state.hasAuth,
      authParams: state.authParams,
      authHydrated: state.authHydrated,
    }),
  );

  const publicWsUrl = `${WS_URL}&xwebsocketserver=restserver0`;
  const privateWsUrl = authParams?.listenKey
    ? `${WS_URL}&xwebsocketserver=restserver${authParams.accountGroup}&listenKey=${authParams.listenKey}`
    : '';

  // Initialize Public WebSocket
  useEffect(() => {
    if (!isLoading) {
      initializePublicWs(publicWsUrl);
    }

    return () => {
      disconnectPublicWs();
    };
  }, [isLoading, publicWsUrl]);

  // Initialize Private WebSocket
  useEffect(() => {
    if (!isLoading && hasAuth && authParams?.listenKey && _hasHydrated) {
      initializePrivateWs(privateWsUrl);
    }

    return () => {
      disconnectPrivateWs();
    };
  }, [isLoading, hasAuth, authParams?.listenKey, _hasHydrated, privateWsUrl]);

  return <>{children}</>;
};
