import config from '@/config';
import { WsMsg } from '@/types';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const parseWsMsg = async (event: MessageEvent<any>): Promise<WsMsg> => {
  try {
    const arrayBuffer = await event.data.arrayBuffer();
    const text = new TextDecoder().decode(arrayBuffer);
    return JSON.parse(text);
  } catch (e) {
    throw new Error(
      `WebSocket received message format error:\n${e}\nMessage: ${event.data}`,
    );
  }
};

export const logConsole = (isError: boolean, ...params: any[]): void => {
  const { isDevelopment } = config;

  if (isDevelopment)
    isError ? console.error(...params) : console.log(...params);
};
