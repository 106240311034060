import { getAllSymbols, useMarketStore } from '@/store/use-markets-store'; // TODO: needs adjustment
import { SymbolInfo, TvSymbol, TvIntervals, ConfigurationData } from './types';
import { StreamData } from './types';
import {
  Bar,
  HistoryCallback,
  IDatafeedChartApi,
  LibrarySymbolInfo,
  Mark,
  ResolutionString,
} from 'public/charting_library/charting_library';
import { RobustWebsocket } from '../api-clients/ws-client';
import { FilledOrder, useOrdersStore } from '@/store/use-orders-store';
import { useShallow } from 'zustand/react/shallow';
import { OrderStatus } from '@/types/enums';
import { privateApi } from '../api-clients/rest-client';
import { DataMsg } from '@/types';

const REST_URL = import.meta.env.VITE_REST_URL;

const fetchKlines = async (
  symbol: string,
  interval: string,
  startTime?: number,
  endTime?: number,
  countBack?: number,
): Promise<(string | number)[][]> => {
  const params = `symbol=${symbol}&interval=${interval}${
    startTime ? `&startTime=${startTime}` : ''
  }${endTime ? `&endTime=${endTime}` : ''}${
    countBack ? `&limit=${countBack}` : ''
  }`;
  const vestUrl = `${REST_URL}klines?${params}`;
  const res = await fetch(vestUrl, {
    headers: { xrestservermm: 'restserver0' },
  });
  const json = await res.json();
  return json;
};

const fetchOrders = async (
  symbol: string,
  startTime: number,
  endTime: number,
): Promise<FilledOrder[]> =>
  privateApi.get('/orders', {
    params: {
      symbol,
      status: OrderStatus.FILLED,
      startTime,
      endTime,
    },
  });

const prevBars = new Map<string, StreamData>();

const handleKlineMessage =
  (channel: string, onRealtimeCallback: (data: StreamData) => void) =>
  (msg: DataMsg) => {
    if (msg.channel === channel) {
      const sData = msg.data;
      try {
        if (sData.length >= 8) {
          const newBar = {
            openTime: sData[0],
            time: sData[5],
            open: parseFloat(sData[1]),
            high: parseFloat(sData[2]),
            low: parseFloat(sData[3]),
            close: parseFloat(sData[4]),
            volume: parseFloat(sData[6]),
          } as StreamData;

          const prevBar = prevBars.get(channel);

          // NOTE: for some reason, trading view does cumulative sum over volume in bars received over ws
          // This solution is a workaround, computing a volume diff b/w current and previous bar
          let updatedBar;
          if (
            prevBar !== undefined &&
            prevBar.volume &&
            prevBar.openTime == newBar.openTime
          ) {
            updatedBar = { ...newBar, volume: newBar.volume - prevBar.volume };
          } else {
            updatedBar = newBar;
          }

          prevBars.set(channel, newBar);

          onRealtimeCallback(updatedBar);
        }
      } catch (e) {
        console.error(e);
      }
      return true;
    }
  };

let symbols: TvSymbol[];

const subscribedChannels: Record<string, string> = {}; // subscriberUID -> channel name

const tvIntervals = {
  '1': '1m',
  '3': '3m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '60': '1h',
  '120': '2h',
  '240': '4h',
  '360': '6h',
  '480': '8h',
  '720': '12h',
  '1D': '1d',
  '3D': '3d',
  '1W': '1w',
};

const intervalMins = {
  '1m': 1,
  '3m': 3,
  '5m': 5,
  '15m': 15,
  '30m': 30,
  '1h': 60,
  '2h': 120,
  '4h': 240,
  '6h': 360,
  '8h': 480,
  '12h': 720,
  '1d': 1440,
  '3d': 4320,
  '1w': 10080,
};

const getDatafeed = (ws: RobustWebsocket) => {
  // TODO: fix type to IDatafeedChartApi
  const configurationData = {
    supports_marks: true,
    supports_timescale_marks: false,
    supports_time: true,
    supported_resolutions: Object.keys(tvIntervals),
  };
  return {
    onReady: (callback: (data: ConfigurationData) => void) => {
      // Assumes useAllMarketStores is already populated by useExchangeInfo
      symbols = getAllSymbols().map((symbol) => {
        const { marketSpec } = useMarketStore(symbol).getState();
        return {
          symbol,
          baseAsset: marketSpec.base,
          quoteAsset: marketSpec.quote,
          filters: [
            {
              filterType: 'PRICE_FILTER',
              tickSize: (10 ** Number(-marketSpec.priceDecimals)).toString(),
            },
          ],
        };
      });
      setTimeout(() => callback(configurationData));
    },

    getMarks: (
      symbol: SymbolInfo,
      startTime: number,
      endTime: number,
      onDataCallback: (marks: Mark[]) => void,
    ) => {
      (async () => {
        const ordersHistory = await fetchOrders(
          symbol.name,
          startTime * 1000,
          endTime * 1000,
        );
        Array.isArray(ordersHistory) &&
          onDataCallback(
            ordersHistory.map(({ id, lastFilledTime, isBuy, symbol }) => ({
              id,
              time: lastFilledTime / 1000,
              color: isBuy ? 'green' : 'red',
              text: `${isBuy ? 'Long' : 'Short'} on ${symbol}`,
              label: isBuy ? 'B' : 'S',
              labelFontColor: '#FFF',
              minSize: 24,
            })),
          );
      })();
    },

    searchSymbols: (
      userInput: string,
      exchange: string,
      symbolType: string,
      onResultReadyCallback: (symbols: Partial<SymbolInfo>[]) => void,
    ) => {
      userInput = userInput.toUpperCase();
      onResultReadyCallback(
        symbols
          .filter((symbol) => symbol.symbol.indexOf(userInput) >= 0)
          .map((symbol) => ({
            symbol: symbol.symbol,
            full_name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            ticker: symbol.symbol,
            exchange: 'Vest',
            type: 'crypto',
          })),
      );
    },

    resolveSymbol: (
      symbolName: string,
      onSymbolResolvedCallback: (symbol: Partial<LibrarySymbolInfo>) => void,
      onResolveErrorCallback: (error: string) => void,
    ) => {
      const comps = symbolName.split(':');
      symbolName = comps.length > 1 ? comps[1] : symbolName;
      symbolName = symbolName.startsWith('k')
        ? 'k' + symbolName.substring(1).toUpperCase()
        : symbolName.toUpperCase();

      const pricescale = (symbol: TvSymbol) => {
        const priceFilter = symbol.filters.find(
          ({ filterType }) => filterType === 'PRICE_FILTER',
        );
        if (priceFilter) {
          return Math.round(1 / parseFloat(priceFilter.tickSize));
        }
        return 1;
      };

      const symbolToResolve = symbols.find(
        ({ symbol }) => symbol === symbolName,
      );
      if (symbolToResolve) {
        setTimeout(() => {
          onSymbolResolvedCallback({
            name: symbolToResolve.symbol,
            description:
              symbolToResolve.baseAsset + ' / ' + symbolToResolve.quoteAsset,
            ticker: symbolToResolve.symbol,
            exchange: 'Vest',
            listed_exchange: 'Vest',
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: pricescale(symbolToResolve),
            has_intraday: true,
            has_daily: true,
            daily_multipliers: ['1', '3'],
            currency_code: symbolToResolve.quoteAsset,
          });
        }, 0);
      } else {
        onResolveErrorCallback('not found');
      }
    },
    getBars: (
      symbolInfo: SymbolInfo,
      resolution: keyof TvIntervals,
      periodParams: { from: number; to: number; countBack: number },
      onHistoryCallback: HistoryCallback,
      onErrorCallback: (error: string) => void,
    ) => {
      let { from, to, countBack } = periodParams;
      from *= 1000;
      to *= 1000;
      const interval = tvIntervals[resolution];
      if (!interval) {
        onErrorCallback('Invalid interval');
      }
      let totalKlines: (string | number)[][] = [];
      const finishKlines = () => {
        console.log({ totalKlines });
        if (totalKlines.length === 0) {
          onHistoryCallback([], { noData: true });
        } else {
          const historyCBArray: Bar[] = totalKlines.map((kline) => ({
            time: kline[0] as number,
            open: parseFloat(kline[1] as string),
            high: parseFloat(kline[2] as string),
            low: parseFloat(kline[3] as string),
            close: parseFloat(kline[4] as string),
            volume: parseFloat(kline[5] as string),
          }));
          onHistoryCallback(historyCBArray, { noData: false });
        }
      };

      const getKlines = async (timeFrom: number, timeTo: number) => {
        const barsLeft = countBack - totalKlines.length;
        const klinesLimit = Math.min(barsLeft, 1000);

        try {
          const data = await fetchKlines(
            symbolInfo.name,
            interval,
            timeFrom,
            timeTo,
            klinesLimit,
          );
          totalKlines = totalKlines.concat(data);
          if (data.length <= klinesLimit || totalKlines.length >= countBack) {
            // no data or full data
            finishKlines();
          } else {
            to = data[0][0] as number;
            from =
              to -
              intervalMins[interval as keyof typeof intervalMins] *
                60_000 *
                Math.min(countBack - totalKlines.length, 1000);
            getKlines(from, to);
          }
        } catch (e) {
          onErrorCallback(`Error in 'getKlines' func`);
        }
      };

      getKlines(from, to);
    },

    subscribeBars: (
      symbolInfo: SymbolInfo,
      resolution: keyof TvIntervals,
      onRealtimeCallback: () => void,
      subscriberUID: string,
    ) => {
      const channel = `${symbolInfo.name}@kline_${tvIntervals[resolution]}`;
      subscribedChannels[subscriberUID] = channel;
      ws.subscribeToChannels([channel]);
      ws.addMessageHandler(
        subscriberUID,
        handleKlineMessage(channel, onRealtimeCallback),
      );
    },

    unsubscribeBars: (subscriberUID: string) => {
      ws.unsubscribeFromChannels([subscribedChannels[subscriberUID]]);
      ws.removeMessageHandler(subscriberUID);
      delete subscribedChannels[subscriberUID];
    },

    // getVolumeProfileResolutionForPeriod: (
    //   currentResolution: ResolutionString,
    //   from: number,
    //   to: number,
    //   symbolInfo: LibrarySymbolInfo,
    // ) => {
    //   return currentResolution;
    // },
  };
};

export default getDatafeed;
