import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import {
  arbitrum,
  mainnet,
  optimism,
  base,
  polygon,
  zksync,
  zksyncSepoliaTestnet,
} from 'wagmi/chains';
import config from '@/config';
import { Chain } from '@rainbow-me/rainbowkit';

const projectId = config.WALLET_CONNECT_PROJECT_ID;

const supportedChains: readonly [Chain, ...Chain[]] =
  import.meta.env.VITE_ENV === 'development'
    ? [zksyncSepoliaTestnet]
    : [arbitrum, mainnet, base, optimism, polygon, zksync];
export const chains = supportedChains.reduce(
  (acc, chain) => {
    acc[chain.id] = chain;
    return acc;
  },
  {} as Record<number, Chain>,
);

export const wagmiConfig = getDefaultConfig({
  appName: 'Vest Exchange',
  projectId,
  chains: supportedChains,
  ssr: false,
});
