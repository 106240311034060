/**
 * @param symbol Full Symbol of the token from backend (*-PERP)
 * @returns Abbreviation of the token (Symbol without -PERP suffix)
 */
export const abbrFromSym = (symbol: string | undefined) => {
  if (!symbol) return '';
  return symbol.split('-PERP')[0];
};

/**
 * @param name Abbreviation of the token (Symbol without -PERP suffix)
 * @returns URL of the token image
 */
export const imgFromAbbr = (name: string) => {
  const tokenImg = new URL(
    `../assets/token-images/${name}.png`,
    import.meta.url,
  );
  return tokenImg.pathname.includes('undefined')
    ? new URL(`../assets/token-images/Default.png`, import.meta.url).href
    : tokenImg.href;
};

export const symbolToBaseQuote = (symbol: string) => {
  return symbol.replace('-PERP', '/USDC');
};
